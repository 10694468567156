header {
	@extend .section;
	padding: 222px 0 100px 0;
	min-height: 100vh;
	background-repeat: no-repeat;
	background-position: right top;
	background-size: cover;

	@media #{$mobilland} {
        padding: 160px 0 60px;
    }

    @media #{$mobil2} {
        padding: 181px 0 60px;
    }

    @media #{$portrait} {
	    background-position: 78% top;
    }

	.hd-contain {
		@extend .section;
	}

	.grid {

		&.grd-slider {

			@media #{$tablet2} {
		        padding: 0;
		    }

		    @media #{$mobil7} {
		        padding: 0;
		    }
		}
	}

	.wrap {

		.header-title {

			&.sub-page {

				h1, img {
					margin-top: 53px;

					@media #{$mobilland} {
				        margin-top: 20px;
				    }

				    @media #{$mobil2} {
				        margin-top: 30px;
				    }
				}
			}

			>span, > h1.sub-ttl {
				display: block;
				font-family: basier_squaremedium, sans-serif;
				opacity: 0.5;
				margin-top: 0px;
				line-height: inherit;
				font-size: 12px;
				color: $darkgreen;
				letter-spacing: 1.6px;
				text-transform: uppercase;
				@include noselect;
			}

			h1 {
				max-width: 880px;
				font-size: 70px;
				line-height: 80px;

				@media #{$mobil2} {
					font-size: 40px;
					line-height: 50px;
		        }

		        @media #{$mobilland} {
			        font-size: 40px;
					line-height: 50px;
			    }

			}

			.link-cnt {
				max-width: 901px;
				margin-top: 29px;

				@media #{$mobilland} {
					margin-top: 16px;
			    }

			    @media #{$mobil2} {
			    	margin-top: 16px;
			    }
			}

			p {
				padding: 24px 300px 42px 0;
				font-family: $basereg;
				font-size: 16px;
				color: $grey;
				line-height: 28px;

				@media #{$tablet2} {
					padding: 24px 0 0 0;
				}
			}

		}

		.header-video-slider {
			@extend .section;
			margin: 80px 0 0 0;

			@media #{$tablet2} {
		        margin: 80px 0 0 40px;
		    }

		    @media #{$mobilland} {
				margin-top: 67px;
		    }

		    @media #{$mobil2} {
		    	margin-top: 67px;
		    }
		    
		    ul.slick-dots {
		    	position: absolute;
		    	bottom: -41px;
		    	left: 0;
		    	font-size: 0;

		    	>li {
		    		display: inline-block;
		    		vertical-align: top;

		    		&.slick-active {

		    			button {
		    				background: $green;
		    				border-color: $green;
		    			}
		    		}

		    		&:not(:first-child) {
		    			margin-left: 4px; 
		    		}

		    		>button {
		    			width: 6px;
		    			height: 6px;
		    			display: block;
		    			position: relative;
		    			overflow: hidden;
		    			border-radius: 50%;
		    			box-sizing: border-box;
		    			border: 1px solid rgba(18,114,97,0.50);
		    			font-size: 0;
		    			text-indent: -9999px;
		    			transition: all 0.2s ease-out;
		    		}
		    	}
		    }

			.slide-item {
				width: 585px!important;
				float: left;

				@media #{$mobilland} {
					width: 314px!important;
			    }

			    @media #{$mobil2} {
			    	width: 314px!important;
			    }

				>a {
					width: 100%;
					display: block;
					float: left;

					@include hover {

						>.img-video {

							&:before {
								background-color: $green;

								background-image: url(../images/arrow-white.svg);

								html.no-svg & {
									background-image: url(../images/arrow-white.png);
								}
							}

							.clmn {
								box-shadow: 0 10px 30px 0 rgba(27,63,57,0.20);
							}
						}

						>.title-video {

							p {
								color: $brown;
							}
						}
					}

					>.img-video {
						width: 170px;
						height: 110px;
						float: left;
						position: relative;

						@media #{$mobilland} {
							width: 136px;
							height: 88px;
					    }

					    @media #{$mobil2} {
					    	width: 136px;
					    	height: 88px;
					    }

						&:before {
							width: 50px;
							height: 50px;
							@include pseudo;
							right: 20px;
							bottom: -25px;
							z-index: 2;
							border-radius: 50%;
							transition: all 0.2s ease-out;
							background-color: $white;
							background-repeat: no-repeat;
							background-position: 53% center;
							background-image: url(../images/arrow-green.svg);

							@media #{$mobilland} {
								width: 40px;
								height: 40px;
								right: 15px;
								bottom: -20px;
								background-size: 8px auto;
						    }

						    @media #{$mobil2} {
						    	width: 40px;
						    	height: 40px;
						    	right: 15px;
								bottom: -20px;
								background-size: 8px auto;
						    }

							html.no-svg & {
								background-image: url(../images/arrow-green.png);
							}
						}

						.clmn {
							width: 100%;
							height: 110px;
							display: block;
							position: relative;
							overflow: hidden;
							border-radius: 4px;
							font-size: 0;
							background: $white;
							box-shadow: 0 30px 70px 0 rgba(27,63,57,0.40);
							transition: all 0.2s ease-out;

							@media #{$mobilland} {
								height: 88px;
								text-align: center;
						    }

						    @media #{$mobil2} {
						    	height: 88px;
						    	text-align: center;
						    }

							>img {
								width: 100%;
								max-width: 100%;
								border-radius: 4px;
								display: inline-block;
								vertical-align: top;

								@media #{$mobilland} {
									max-width: auto;
									width: auto;
									height: 100%;
							    }

							    @media #{$mobil2} {
							    	max-width: auto;
							    	width: auto;
							    	height: 100%;
							    }
							}
						}
					}

					>.title-video {
						width: 200px;
						float: left;
						position: relative;
						margin: 0 0 0 30px;

						@media #{$mobilland} {
							width: 140px;
					    }

					    @media #{$mobil2} {
					    	width: 140px;
					    }

						>span {
							display: block;
							margin: 17px 0 0 0;
							opacity: 0.5;
							font-size: 12px;
							color: $darkgreen;
							letter-spacing: 1.6px;
							transition: all 0.2s ease-out;
							text-transform: uppercase;

							@media #{$mobilland} {
								font-size: 11px;
								letter-spacing: 1.47px;
								margin: 3px 0 0;
						    }

						    @media #{$mobil2} {
						    	font-size: 11px;
								letter-spacing: 1.47px;
								margin: 3px 0 0;
						    }
						}

						>p {
							max-height: 48px;
							margin: 18px 0 0 0;
							font-family: $small;
							font-size: 16px;
							color: $darkgreen;
							line-height: 24px;
							transition: all 0.2s ease-out;
							overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;

                            @media #{$mobilland} {
                            	max-width: 130px;
                            	max-height: 62px;
                            	margin: 12px 0 0;
								font-size: 15px;
								line-height: 20px;
								-webkit-line-clamp: 3;
						    }

						    @media #{$mobil2} {
						    	max-width: 130px;
						    	max-height: 62px;
						    	margin: 12px 0 0;
						    	font-size: 15px;
								line-height: 20px;
								-webkit-line-clamp: 3;
						    }
						}
					}
				}
			}
		}
	}
}

a.lnk-btn {
	width: 300px;
	height: 50px;
	display: table;
	background: $white;
	box-shadow: 0 2px 4px 0 rgba(18, 114, 97, 0.10);
	border-radius: 4px;
	text-align: center;
	text-transform: uppercase;
	transition: all 0.2s ease-out;

	@media #{$tablet2} {
		margin: 31px 0 0 0;
	}

	@media #{$mobil} {
		width: 100%;
	}

	@include hover {
		background: $darkgreen;

		> span {
			color: $white;
		}
	}

	> span {
		display: table-cell;
		vertical-align: middle;
		font-size: 12px;
		color: $green;
		letter-spacing: 1.6px;
		transition: all 0.2s ease-out;
	}
}