section.about-us {
	padding: 85px 0 114px;
	a {
		text-decoration: underline;
		color: $darkgreen;
		transition: all 0.2s ease-out;

		@include hover {
			color: $green;
		}
	}
	@media #{$mobilland} {
		padding: 40px 0 50px 0;
	}

	@media #{$mobil2} {
		padding: 66px 0 60px 0;
	}



	.about-column {
		width: 50%;
		float: left;
		position: relative;

		@media #{$tablet} {
			width: 100%;
		}

		>article {
			position: relative;
		}

		&.left {

			article {
				padding-right: 85px;

				@media #{$tablet2} {
					padding-right: 40px;
				}

				@media #{$tablet} {
					padding: 0;
				}
			}
		}

		&.right {

			@media #{$tablet} {
				margin-top: 50px;
			}

			article {
				padding-left: 85px;

				@media #{$tablet2} {
					padding-left: 40px;
				}

				@media #{$tablet} {
					padding: 0;
				}
			}
		}

		.header-title {
			float: none;
			display: block;
			padding-bottom: 28px;
			border-bottom: 2px solid $green;

			h2 {
				font-size: 45px;
				line-height: 80px;

				@media #{$mobilland} {
					font-size: 30px;
					line-height: 40px;
				}

				@media #{$mobil2} {
					font-size: 30px;
					line-height: 40px;
		        }
			}
		}

		p {
			padding: 52px 0 0;
			font-family: $basereg;
			font-size: 16px;
			color: $grey;
			line-height: 28px;

			@media #{$mobilland} {
				padding: 32px 0 0;
			}

			@media #{$mobil2} {
				padding: 32px 0 0;
			}

			b,
			strong {
				color: $darkgreen;
				font-family: $semi;
			}
		}
	}
}




	blockquote {
		padding: 143px 0 0;

		@media #{$tablet2} {
			padding: 100px 0 0;
		}

		@media #{$mobilland} {
			padding: 50px 0 0;
		}

		@media #{$mobil2} {
			padding: 60px 0 0;
		}

		&:before {
			display: none;
		}
		max-width: 900px;

		p {
			font-family: $title;
			font-size: 45px;
			color: $darkgreen;
			line-height: 60px;
			padding-bottom: 38px;
			border-bottom: 2px solid $green;

			@media #{$mobilland} {
				font-size: 30px;
				line-height: 40px;
			}

			@media #{$mobil2} {
				font-size: 30px;
				line-height: 40px;
				padding-bottom: 20px;
			}
		}

		> span {
			display: block;
			margin-top: 22px;
			opacity: 0.5;
			font-size: 12px;
			color: $darkgreen;
			letter-spacing: 1.6px;
			line-height: 24px;
			text-transform: uppercase;
		}
	}

.wrap {
	&.mid-line {
		&:before {
			width: 1px;
			height: 100%;
			@include pseudo;
			top: 0;
			left: 50%;
			opacity: 0.2;
			background: #237865;

			@media #{$tablet} {
				display: none;
			}
		}
	}
}