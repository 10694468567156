//general
@import "bootstrap/bootstrap-grid.scss";


@import "partials/webfonts.scss";
@import "partials/mixins.scss";
@import "partials/reset.scss";
@import "partials/grid.scss";
@import "partials/slick-slider.scss";
@import "partials/video-lightbox.scss";
//pages
@import "partials/header.scss";
@import "partials/navigation.scss";
@import "partials/partners.scss";
@import "partials/footer.scss";
@import "partials/solutions.scss";
@import "partials/hd-box-item.scss";
@import "partials/sub-nav.scss";
//home

@import "partials/home-products.scss";
@import "partials/home-about.scss";
//about
@import "partials/about-us.scss";
@import "partials/about-timeline.scss";
@import "partials/about-team.scss";
@import "partials/about-lightbox.scss";
//products
@import "partials/product-columns.scss";
@import "partials/product-know.scss";
@import "partials/product-api.scss";
@import "partials/product-sub.scss";

input.hs-input.error,
.hs-form div.field.error input,
.hs-form div.field.error textarea,
.hs-form div.field.error .chzn-choices,
textarea.hs-input.error {
  border-color: #c87872;
  -webkit-box-shadow: 0 0 3px rgba(171, 41, 32, 0.25);
  -moz-box-shadow: 0 0 3px rgba(171, 41, 32, 0.25);
  box-shadow: 0 0 3px rgba(171, 41, 32, 0.25)
}

input.hs-input.error:focus,
#hsformContainer div.field.error input:focus,
#hsformContainer div.field.error textarea:focus,
#hsformContainer div.field.error .chzn-choices:focus,
textarea.hs-input.error:focus {
  border-color: #b9554d;
  -webkit-box-shadow: 0 0 6px rgba(171, 41, 32, 0.5);
  -moz-box-shadow: 0 0 6px rgba(171, 41, 32, 0.5);
  box-shadow: 0 0 6px rgba(171, 41, 32, 0.5)
}
#hsformContainer {
  input[type="text"].hs-input, input[type="number"].hs-input, input[type="email"].hs-input, textarea {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-top-color: rgb(206, 212, 218);
    border-right-color: rgb(206, 212, 218);
    border-bottom-color: rgb(206, 212, 218);
    border-left-color: rgb(206, 212, 218);
    border-radius: 0;
  }
  textarea {
    height: auto;
  }
}

div#hsformContainer {
  font-family: $basereg;
  color: $grey;
  fieldset {
    margin-top: 0.5rem;
  }
  .hs-richtext {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .hs-error-msgs {
    label {
      color: #cc0000;
    }
  }
  p {
    padding: 10px 0;
  }
  .actions {
    input.btn.btn-primary {
      margin-top: 2rem;
      width: 100%;
      display: block;
      height: 70px;
      position: relative;
      background: $green;
      box-shadow: 0 2px 4px 0 rgba(18, 114, 97, 0.10);
      border-radius: 4px;
      font-size: 12px;
      letter-spacing: 1.6px;
      color: $white;
      text-transform: uppercase;
      transition: all 0.2s ease-out;
    }
  }

}

.text-content {
  p, h4, h5, h6 {
    margin: 10px 0;
    font-family: $basereg;
    font-size: 16px;
    color: $grey;
    line-height: 28px;

    @media #{$mobilland} {
      padding: 32px 0 0;
    }

    @media #{$mobil2} {
      padding: 32px 0 0;
    }

    b,
    strong {
      color: $darkgreen;
      font-family: $semi;
    }
  }
  ul p {
    padding: 0;
    margin: 0;
  }
  h4, h5, h6 {
    font-weight: bold;
  }
  h2 {
    font-size: 45px;
    line-height: 80px;

    @media #{$mobilland} {
      font-size: 30px;
      line-height: 40px;
    }

    @media #{$mobil2} {
      font-size: 30px;
      line-height: 40px;
    }
  }
  h3 {
    font-size: 30px;
    line-height: 60px;

    @media #{$mobilland} {
      font-size: 20px;
      line-height: 30px;
    }

    @media #{$mobil2} {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

@media #{$tablet4} {
  .grid .wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.saphetor-com-nav-compare {
  color: #ffffff;
  text-transform: uppercase;
  padding: 20px;
}

ul.sc-links {
  position: relative;
  font-size: 0;
  margin-top: 10px;
  padding: 0 0 0 5px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;

  @media #{$tablet2} {
    padding: 0;
    text-align: left;
  }

  @media #{$mobil6} {
    display: block;
  }

  &:nth-of-type(1) {
    margin-top: 24px;

    @media #{$mobil6} {
      margin-top: 30px;
    }
  }

  li {
    width: 50%;
    width: calc(50% - 10px);
    margin-left: 10px;
    position: relative;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    border-radius: 4px;
    border: 1px solid rgba(#237865, 0.2);
    // display: flex;
    transition: all 0.2s ease-out;
    box-sizing: border-box;
    overflow: hidden;

    @media #{$tablet2} {
      width: calc(50% - 5px);
      margin: 0;

      &:nth-of-type(1) {
        margin-right: 5px;
      }

      &:nth-of-type(2) {
        margin-left: 5px;
      }
    }

    @media #{$mobil6} {
      width: 100%;
      margin: 0;
      display: block;

      &:nth-of-type(1) {
        margin-right: 0;
      }

      &:nth-of-type(2) {
        margin-top: 10px;
        margin-left: 0;
      }
    }

    &:before,
    &:after {
      width: 20px;
      height: 20px;
      @include pseudo;
      top: 50%;
      left: 22px;
      margin-top: -10px;
      background-repeat: no-repeat;
      background-position: 50%;
      transition: all 0.3s ease-out;
      pointer-events: none;
    }

    &:before {
      z-index: 1;
      background-image: url(../images/icon-btn-static.svg);
    }

    &:after {
      z-index: 2;
      opacity: 0;
      background-image: url(../images/icon-btn-hover.svg);
    }

    @include hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }

    a {
      width: 100%;
      height: 100%;
      // padding: 21px 0;
      // margin: auto 0;
      display: table;
      vertical-align: top;
      position: relative;
      text-transform: uppercase;
      font-size: 12px;
      color: $darkgreen;
      font-family: $base;
      font-size: 12px;
      text-decoration: none;
      letter-spacing: 0.4px;
      line-height: 16px;
      transition: all 0.3s ease-out;

      > span {
        display: table-cell;
        vertical-align: middle;
        position: relative;
        padding: 21px 20px 21px 55px;
        z-index: 3;
      }
    }
  }
}
