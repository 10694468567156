section.partners {
	padding: 100px 0 84px;


    @media #{$mobilland} {
    	padding: 65px 0 50px;
    }

    @media #{$mobil2} {
    	padding: 65px 0 50px;
    }

    .header-title {
    	text-align: center;
		float: none;
		clear: both;

    	h2 {
			font-size: 35px;
			line-height: 40px;

			@media #{$mobil2} {
				font-size: 25px;
				line-height: 35px;
	        }
		}
    }

    div.partners-list {
		@include noselect;

	    //@media #{$mobilland} {
	    //	margin-top: 40px;
	    //}

	    //@media #{$mobil2} {
	    //	margin-top: 80px;
	    //}

		img {
			max-width: 100%;
			display: inline-block;
			transition: all 0.26s ease-out;
			@include hover {
				@include transform(scale(1.06));
			}
		}
    }
}