section.about-team {
	padding: 101px 0 99px;
	background: #EFFCFA;

	@media #{$mobilland} {
		padding: 50px 0 65px;
	}

	@media #{$mobil2} {
		padding: 50px 0 65px;
	}

	& ~ section.intr-solutions {
		border-top: 0;
	}

	.grid {
		max-width: 1200px;

		@media #{$desktop7} {
			padding: 0 45px;
		}

		@media #{$tablet2} {
	        padding: 0 25px;
	    }
	}

	.header-title {
		text-align: center;

		h2 {
			font-size: 35px;
		}
	}

	ul.team-list {
		@extend .section;
		font-size: 0;
	    margin-top: 25px;

	    @media #{$mobilland} {
			margin-top: 0;
		}

		@media #{$mobil2} {
			margin-top: 0;
		}

		>li {
			width: 22%;
			width: calc(25% - 30px);
			display: inline-block;
			vertical-align: top;
			margin: 58px 15px 0;
			div.team-cv {
				display: none;
			}
			@media #{$tablet} {
				width: 45%;
				width: calc(50% - 30px);
			}

			@media #{$mobilland} {
				margin-top: 40px;
			}

			@media #{$mobil} {
				width: 90%;
				width: calc(100% - 30px);
				margin-top: 40px;
			}

			&:nth-of-type(1),
			&:nth-of-type(2) {

				@media #{$mobilland} {
					margin-top: 40px;
				}

				@media #{$mobil2} {
					margin-top: 40px;
				}
			}

			> a, div {
			    display: block;
			    position: relative;
			    color: #fff;
			    text-decoration: none;

			    @include hover {

			    	.row {
			    		box-shadow: 0 40px 70px 0 rgba(0,0,0,0.10);
			    	}

			    	.title {
			    		&:before,
					    &:after {
					    	@include transform(translateY(0));
					    	opacity: 1;
					    }
			    	}
			    }

				.row {
					width: 100%;
				    display: inline-block;
				    position: relative;
				    vertical-align: top;
				    background-repeat: no-repeat;
				    background-position: 50% 0;
				    background-size: cover;
				    overflow: hidden;
				    border-radius: 4px;
				    transition: all 0.2s ease-out;
				    z-index: 1;

				    &:before {
					    content: "";
					    display: block;
					    padding-top: 100%;
					}
				}

				.title {
					width: 100%;
					display: block;
					position: relative;
				    padding: 39px 0 0;
				    z-index: 3;

				    @media #{$mobilland} {
				    	padding-top: 20px;
					}

					@media #{$mobil} {
						padding-top: 20px;
					}

					h3 {
						font-family: DroidSerif;
						font-size: 16px;
						color: $brown;
					}

					>span {
						display: block;
						margin-top: 12px;
						opacity: 0.5;
						font-size: 12px;
						color: $darkgreen;
						letter-spacing: 1.6px;
						text-transform: uppercase;
					}
				}
			}
			> a {
				.title {
					&:before,
					&:after {
						@include pseudo;
						background: $green;
						@include transform(translateY(5px));
						opacity: 0;
						transition: all 0.26s ease-out;
					}

					&:before {
						width: 14px;
						height: 2px;
						top: 46px;
						right: 10px;
					}

					&:after {
						width: 2px;
						height: 14px;
						top: 40px;
						right: 16px;
					}
				}
			}
		}
	}
}