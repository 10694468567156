@charset "UTF-8";
/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #20d5b7;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #16927e;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #87eddc;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 213, 183, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9); }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9); }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #20d5b7; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(32, 213, 183, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b4f4e9; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #20d5b7; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #20d5b7; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(32, 213, 183, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(32, 213, 183, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #20d5b7; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(32, 213, 183, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #87eddc;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(135, 237, 220, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #87eddc;
    box-shadow: 0 0 0 0.2rem rgba(32, 213, 183, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #87eddc; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(32, 213, 183, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(32, 213, 183, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(32, 213, 183, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #20d5b7;
    border: 0;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b4f4e9; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #20d5b7;
    border: 0;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b4f4e9; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #20d5b7;
    border: 0;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b4f4e9; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none; } }

.btn, .saphetor-com-nav-compare {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn, .saphetor-com-nav-compare {
      transition: none; } }
  .btn:hover, .btn:focus, .saphetor-com-nav-compare:hover, .saphetor-com-nav-compare:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus, .saphetor-com-nav-compare:focus, .saphetor-com-nav-compare.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 213, 183, 0.25); }
  .btn.disabled, .btn:disabled, .saphetor-com-nav-compare.disabled, .saphetor-com-nav-compare:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled), .saphetor-com-nav-compare:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.saphetor-com-nav-compare {
  color: #212529;
  background-color: #20d5b7;
  border-color: #20d5b7; }
  .saphetor-com-nav-compare:hover {
    color: #fff;
    background-color: #1bb49a;
    border-color: #19a991; }
  .saphetor-com-nav-compare:focus, .saphetor-com-nav-compare.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 213, 183, 0.5); }
  .saphetor-com-nav-compare.disabled, .saphetor-com-nav-compare:disabled {
    color: #212529;
    background-color: #20d5b7;
    border-color: #20d5b7; }
  .saphetor-com-nav-compare:not(:disabled):not(.disabled):active, .saphetor-com-nav-compare:not(:disabled):not(.disabled).active,
  .show > .saphetor-com-nav-compare.dropdown-toggle {
    color: #fff;
    background-color: #19a991;
    border-color: #189e87; }
    .saphetor-com-nav-compare:not(:disabled):not(.disabled):active:focus, .saphetor-com-nav-compare:not(:disabled):not(.disabled).active:focus,
    .show > .saphetor-com-nav-compare.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 213, 183, 0.5); }

.btn-primary {
  color: #212529;
  background-color: #20d5b7;
  border-color: #20d5b7; }
  .btn-primary:hover {
    color: #fff;
    background-color: #1bb49a;
    border-color: #19a991; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 213, 183, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #20d5b7;
    border-color: #20d5b7; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #19a991;
    border-color: #189e87; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 213, 183, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #20d5b7;
  background-color: transparent;
  background-image: none;
  border-color: #20d5b7; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #20d5b7;
    border-color: #20d5b7; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 213, 183, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #20d5b7;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #20d5b7;
    border-color: #20d5b7; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 213, 183, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #20d5b7;
  background-color: transparent; }
  .btn-link:hover {
    color: #16927e;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block, .saphetor-com-nav-compare {
  display: block;
  width: 100%; }
  .btn-block + .btn-block, .saphetor-com-nav-compare + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #20d5b7 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

@font-face {
  font-family: 'ClarendonLTStd-Light';
  src: url("../fonts/clarendonltstd-light.woff2") format("woff2"), url("../fonts/clarendonltstd-light.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'droid_serifregular';
  src: url("../fonts/droidserif-webfont.woff2") format("woff2"), url("../fonts/droidserif-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'basier_squaremedium';
  src: url("../fonts/basiersquare-medium-webfont.woff2") format("woff2"), url("../fonts/basiersquare-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'basier_squareregular';
  src: url("../fonts/basiersquare-regular-webfont.woff2") format("woff2"), url("../fonts/basiersquare-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'basier_squaresemibold';
  src: url("../fonts/basiersquare-semibold-webfont.woff2") format("woff2"), url("../fonts/basiersquare-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/* colors */
/* fonts */
/* responsive width */
/* min */
/* padding */
/* hover */
/* fixed */
/* absolute */
/* pseudo */
/* backface */
/* radius */
/* select */
/* transform */
/* gradient */
/******************************************************************
    reset css
*/
html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  font-size-adjust: none;
  -moz-font-size-adjust: none;
  -webkit-font-size-adjust: none; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    html,
    body {
      height: auto; } }
  @media screen and (max-width: 350px) {
    html,
    body {
      height: auto; } }

html {
  font-family: "Helvetica", sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  font-family: "basier_squaremedium", sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  margin: 0;
  padding: 0;
  cursor: default;
  overflow-x: hidden;
  background-color: #FFF; }

* {
  margin: 0;
  padding: 0; }

table,
p {
  padding: 0;
  margin: 0; }

strong, b {
  font-weight: normal; }

embed {
  padding: 0px; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: "ClarendonLTStd-Light";
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #127261; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
  font-size-adjust: none;
  -moz-font-size-adjust: none;
  -webkit-font-size-adjust: none; }

a,
a:hover,
button,
button:hover {
  cursor: pointer;
  outline: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

a img {
  border: none; }

button {
  width: auto;
  vertical-align: top;
  overflow: visible;
  outline: no-repeat;
  cursor: pointer;
  border-radius: 0;
  border: 0;
  outline: 0;
  background: transparent; }

a:link,
section.articleWrapper aside:link {
  -webkit-tap-highlight-color: rgba(1, 1, 1, 0.563); }

label,
img {
  display: block;
  border: none;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

textarea {
  overflow: auto;
  resize: none;
  outline: no-repeat; }

ol,
ul,
li {
  list-style: none;
  outline: none; }

form,
dl,
dd,
dt,
ul,
ol,
li {
  padding: 0;
  margin: 0; }

canvas {
  overflow: hidden; }

acronym {
  border: none;
  cursor: help; }

iframe {
  overflow: hidden; }

::selection {
  background: #18C2A5;
  color: #FFF; }

::-moz-selection {
  background: #18C2A5;
  color: #FFF; }

::-webkit-selection {
  background: #18C2A5;
  color: #FFF; }

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block; }

html input[type="button"],
input[type="reset"] {
  -webkit-appearance: button;
  cursor: pointer;
  border-radius: 0;
  width: auto;
  border: 0;
  outline: 0;
  background: transparent;
  border-radius: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; }

input[type='number'] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

@-ms-viewport {
  width: device-width; }

#root {
  width: 100%;
  overflow: hidden;
  float: left;
  position: relative; }

button#screen {
  width: 40px;
  height: 40px;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  z-index: 9999; }

.section, .header-title, .box-container, .link-cnt, header, header .hd-contain, header .wrap .header-video-slider, aside#header-navigation > .site-container, footer, footer .ft-container .items, footer .project, section.hp-products .key-benefits, section.about-team ul.team-list, section.product-columns .columns, section.product-know ul.know-list {
  width: 100%;
  position: relative;
  float: left;
  z-index: 5; }

.grid {
  max-width: 1170px;
  position: relative;
  margin: 0 auto; }
  .grid .wrap {
    width: 100%;
    float: left;
    position: relative; }

.header-title > span {
  display: block; }

.header-title h1, .header-title h2, .header-title h3, .header-title h4 {
  display: inline-block;
  vertical-align: top;
  position: relative;
  font-family: "ClarendonLTStd-Light";
  color: #127261; }

.box-container {
  text-align: center;
  font-size: 0; }
  .box-container > .item {
    display: inline-block;
    vertical-align: top;
    position: relative; }

.link-cnt {
  font-size: 0;
  overflow: hidden; }
  .link-cnt:before {
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 120px;
    margin-top: -1px;
    background: #18C2A5;
    pointer-events: none;
    z-index: -1; }
  .link-cnt > a {
    width: auto;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 10px;
    left: -10px;
    font-family: "basier_squaremedium";
    font-size: 12px;
    color: #127261;
    letter-spacing: 1.6px;
    transition: all 0.2s ease-out;
    text-transform: uppercase; }
    .link-cnt > a > span {
      color: #127261;
      display: inline-block;
      vertical-align: top;
      transition: all 0.3s ease-out; }
    .link-cnt > a:hover > span {
      color: #18C2A5;
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 !important; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto; }

.slick-list.draggable {
  height: 100% !important; }
  @media screen and (max-width: 900px) {
    .slick-list.draggable {
      height: 88px !important; } }

/* Slider */
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

.video-lightbox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 101;
  display: table;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  -webkit-animation: zoom 0.3s ease-out forwards;
  animation: zoom 0.3s ease-out forwards; }

@-webkit-keyframes zoom {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes zoom {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }
  .video-lightbox.fadeout {
    animation: zooms 0.3s ease-out forwards; }

@keyframes zooms {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }
  .video-lightbox > .grid {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    position: relative;
    text-align: center; }
    .video-lightbox > .grid .video-box-cnt {
      max-width: 870px;
      margin: 0 auto;
      position: relative;
      background: #FFF;
      padding: 10px;
      border-radius: 4px; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        .video-lightbox > .grid .video-box-cnt {
          max-width: 400px; } }
      .video-lightbox > .grid .video-box-cnt > button {
        width: 50px;
        height: 50px;
        position: absolute;
        top: -25px;
        right: -25px;
        z-index: 12;
        transition: all 0.24s ease-out;
        border-radius: 50%;
        background: #FFF;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15); }
        .video-lightbox > .grid .video-box-cnt > button:hover {
          background: #18C2A5; }
          .video-lightbox > .grid .video-box-cnt > button:hover:before, .video-lightbox > .grid .video-box-cnt > button:hover:after {
            background: #FFF; }
        .video-lightbox > .grid .video-box-cnt > button:before, .video-lightbox > .grid .video-box-cnt > button:after {
          width: 20px;
          height: 2px;
          content: "";
          position: absolute;
          overflow: hidden;
          top: 50%;
          left: 50%;
          transition: all 0.24s ease-out;
          background: #18C2A5; }
        .video-lightbox > .grid .video-box-cnt > button:before {
          -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          -ms-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg); }
        .video-lightbox > .grid .video-box-cnt > button:after {
          -webkit-transform: translate(-50%, -50%) rotate(45deg);
          -ms-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg); }
  .video-lightbox .wrapper {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    border-radius: 4px; }
    .video-lightbox .wrapper iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      border-radius: 4px; }

header {
  padding: 222px 0 100px 0;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    header {
      padding: 160px 0 60px; } }
  @media screen and (max-width: 650px) {
    header {
      padding: 181px 0 60px; } }
  @media screen and (orientation: portrait) {
    header {
      background-position: 78% top; } }
  @media screen and (max-width: 1000px) {
    header .grid.grd-slider {
      padding: 0; } }
  @media screen and (max-width: 350px) {
    header .grid.grd-slider {
      padding: 0; } }
  header .wrap .header-title.sub-page h1, header .wrap .header-title.sub-page img {
    margin-top: 53px; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      header .wrap .header-title.sub-page h1, header .wrap .header-title.sub-page img {
        margin-top: 20px; } }
    @media screen and (max-width: 650px) {
      header .wrap .header-title.sub-page h1, header .wrap .header-title.sub-page img {
        margin-top: 30px; } }
  header .wrap .header-title > span, header .wrap .header-title > h1.sub-ttl {
    display: block;
    font-family: basier_squaremedium, sans-serif;
    opacity: 0.5;
    margin-top: 0px;
    line-height: inherit;
    font-size: 12px;
    color: #127261;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none; }
  header .wrap .header-title h1 {
    max-width: 880px;
    font-size: 70px;
    line-height: 80px; }
    @media screen and (max-width: 650px) {
      header .wrap .header-title h1 {
        font-size: 40px;
        line-height: 50px; } }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      header .wrap .header-title h1 {
        font-size: 40px;
        line-height: 50px; } }
  header .wrap .header-title .link-cnt {
    max-width: 901px;
    margin-top: 29px; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      header .wrap .header-title .link-cnt {
        margin-top: 16px; } }
    @media screen and (max-width: 650px) {
      header .wrap .header-title .link-cnt {
        margin-top: 16px; } }
  header .wrap .header-title p {
    padding: 24px 300px 42px 0;
    font-family: "basier_squareregular";
    font-size: 16px;
    color: #80A09B;
    line-height: 28px; }
    @media screen and (max-width: 1000px) {
      header .wrap .header-title p {
        padding: 24px 0 0 0; } }
  header .wrap .header-video-slider {
    margin: 80px 0 0 0; }
    @media screen and (max-width: 1000px) {
      header .wrap .header-video-slider {
        margin: 80px 0 0 40px; } }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      header .wrap .header-video-slider {
        margin-top: 67px; } }
    @media screen and (max-width: 650px) {
      header .wrap .header-video-slider {
        margin-top: 67px; } }
    header .wrap .header-video-slider ul.slick-dots {
      position: absolute;
      bottom: -41px;
      left: 0;
      font-size: 0; }
      header .wrap .header-video-slider ul.slick-dots > li {
        display: inline-block;
        vertical-align: top; }
        header .wrap .header-video-slider ul.slick-dots > li.slick-active button {
          background: #18C2A5;
          border-color: #18C2A5; }
        header .wrap .header-video-slider ul.slick-dots > li:not(:first-child) {
          margin-left: 4px; }
        header .wrap .header-video-slider ul.slick-dots > li > button {
          width: 6px;
          height: 6px;
          display: block;
          position: relative;
          overflow: hidden;
          border-radius: 50%;
          box-sizing: border-box;
          border: 1px solid rgba(18, 114, 97, 0.5);
          font-size: 0;
          text-indent: -9999px;
          transition: all 0.2s ease-out; }
    header .wrap .header-video-slider .slide-item {
      width: 585px !important;
      float: left; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        header .wrap .header-video-slider .slide-item {
          width: 314px !important; } }
      @media screen and (max-width: 650px) {
        header .wrap .header-video-slider .slide-item {
          width: 314px !important; } }
      header .wrap .header-video-slider .slide-item > a {
        width: 100%;
        display: block;
        float: left; }
        header .wrap .header-video-slider .slide-item > a:hover > .img-video:before {
          background-color: #18C2A5;
          background-image: url(../images/arrow-white.svg); }
          html.no-svg header .wrap .header-video-slider .slide-item > a:hover > .img-video:before {
            background-image: url(../images/arrow-white.png); }
        header .wrap .header-video-slider .slide-item > a:hover > .img-video .clmn {
          box-shadow: 0 10px 30px 0 rgba(27, 63, 57, 0.2); }
        header .wrap .header-video-slider .slide-item > a:hover > .title-video p {
          color: #444444; }
        header .wrap .header-video-slider .slide-item > a > .img-video {
          width: 170px;
          height: 110px;
          float: left;
          position: relative; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            header .wrap .header-video-slider .slide-item > a > .img-video {
              width: 136px;
              height: 88px; } }
          @media screen and (max-width: 650px) {
            header .wrap .header-video-slider .slide-item > a > .img-video {
              width: 136px;
              height: 88px; } }
          header .wrap .header-video-slider .slide-item > a > .img-video:before {
            width: 50px;
            height: 50px;
            content: "";
            position: absolute;
            overflow: hidden;
            right: 20px;
            bottom: -25px;
            z-index: 2;
            border-radius: 50%;
            transition: all 0.2s ease-out;
            background-color: #FFF;
            background-repeat: no-repeat;
            background-position: 53% center;
            background-image: url(../images/arrow-green.svg); }
            @media screen and (max-width: 820px) and (orientation: landscape) {
              header .wrap .header-video-slider .slide-item > a > .img-video:before {
                width: 40px;
                height: 40px;
                right: 15px;
                bottom: -20px;
                background-size: 8px auto; } }
            @media screen and (max-width: 650px) {
              header .wrap .header-video-slider .slide-item > a > .img-video:before {
                width: 40px;
                height: 40px;
                right: 15px;
                bottom: -20px;
                background-size: 8px auto; } }
            html.no-svg header .wrap .header-video-slider .slide-item > a > .img-video:before {
              background-image: url(../images/arrow-green.png); }
          header .wrap .header-video-slider .slide-item > a > .img-video .clmn {
            width: 100%;
            height: 110px;
            display: block;
            position: relative;
            overflow: hidden;
            border-radius: 4px;
            font-size: 0;
            background: #FFF;
            box-shadow: 0 30px 70px 0 rgba(27, 63, 57, 0.4);
            transition: all 0.2s ease-out; }
            @media screen and (max-width: 820px) and (orientation: landscape) {
              header .wrap .header-video-slider .slide-item > a > .img-video .clmn {
                height: 88px;
                text-align: center; } }
            @media screen and (max-width: 650px) {
              header .wrap .header-video-slider .slide-item > a > .img-video .clmn {
                height: 88px;
                text-align: center; } }
            header .wrap .header-video-slider .slide-item > a > .img-video .clmn > img {
              width: 100%;
              max-width: 100%;
              border-radius: 4px;
              display: inline-block;
              vertical-align: top; }
              @media screen and (max-width: 820px) and (orientation: landscape) {
                header .wrap .header-video-slider .slide-item > a > .img-video .clmn > img {
                  max-width: auto;
                  width: auto;
                  height: 100%; } }
              @media screen and (max-width: 650px) {
                header .wrap .header-video-slider .slide-item > a > .img-video .clmn > img {
                  max-width: auto;
                  width: auto;
                  height: 100%; } }
        header .wrap .header-video-slider .slide-item > a > .title-video {
          width: 200px;
          float: left;
          position: relative;
          margin: 0 0 0 30px; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            header .wrap .header-video-slider .slide-item > a > .title-video {
              width: 140px; } }
          @media screen and (max-width: 650px) {
            header .wrap .header-video-slider .slide-item > a > .title-video {
              width: 140px; } }
          header .wrap .header-video-slider .slide-item > a > .title-video > span {
            display: block;
            margin: 17px 0 0 0;
            opacity: 0.5;
            font-size: 12px;
            color: #127261;
            letter-spacing: 1.6px;
            transition: all 0.2s ease-out;
            text-transform: uppercase; }
            @media screen and (max-width: 820px) and (orientation: landscape) {
              header .wrap .header-video-slider .slide-item > a > .title-video > span {
                font-size: 11px;
                letter-spacing: 1.47px;
                margin: 3px 0 0; } }
            @media screen and (max-width: 650px) {
              header .wrap .header-video-slider .slide-item > a > .title-video > span {
                font-size: 11px;
                letter-spacing: 1.47px;
                margin: 3px 0 0; } }
          header .wrap .header-video-slider .slide-item > a > .title-video > p {
            max-height: 48px;
            margin: 18px 0 0 0;
            font-family: "droid_serifregular";
            font-size: 16px;
            color: #127261;
            line-height: 24px;
            transition: all 0.2s ease-out;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; }
            @media screen and (max-width: 820px) and (orientation: landscape) {
              header .wrap .header-video-slider .slide-item > a > .title-video > p {
                max-width: 130px;
                max-height: 62px;
                margin: 12px 0 0;
                font-size: 15px;
                line-height: 20px;
                -webkit-line-clamp: 3; } }
            @media screen and (max-width: 650px) {
              header .wrap .header-video-slider .slide-item > a > .title-video > p {
                max-width: 130px;
                max-height: 62px;
                margin: 12px 0 0;
                font-size: 15px;
                line-height: 20px;
                -webkit-line-clamp: 3; } }

a.lnk-btn {
  width: 300px;
  height: 50px;
  display: table;
  background: #FFF;
  box-shadow: 0 2px 4px 0 rgba(18, 114, 97, 0.1);
  border-radius: 4px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s ease-out; }
  @media screen and (max-width: 1000px) {
    a.lnk-btn {
      margin: 31px 0 0 0; } }
  @media screen and (max-width: 480px) {
    a.lnk-btn {
      width: 100%; } }
  a.lnk-btn:hover {
    background: #127261; }
    a.lnk-btn:hover > span {
      color: #FFF; }
  a.lnk-btn > span {
    display: table-cell;
    vertical-align: middle;
    font-size: 12px;
    color: #18C2A5;
    letter-spacing: 1.6px;
    transition: all 0.2s ease-out; }

aside#header-navigation {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  transition: all .2s ease-in;
  background: rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(35, 120, 101, 0.2); }
  aside#header-navigation::-webkit-scrollbar {
    display: none; }
  aside#header-navigation.fixed-nav {
    background: #FFF;
    border-bottom: 1px solid rgba(35, 120, 101, 0);
    box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.06); }
    aside#header-navigation.fixed-nav > .site-container {
      padding: 0; }
      @media screen and (max-width: 1200px) {
        aside#header-navigation.fixed-nav > .site-container {
          padding: 0 0 15px; } }
      @media screen and (max-width: 1000px) {
        aside#header-navigation.fixed-nav > .site-container {
          padding: 15px 0 15px; } }
      aside#header-navigation.fixed-nav > .site-container #logo {
        width: 129px;
        height: 30px;
        top: 0;
        margin: 25px 0 0 30px; }
        @media screen and (max-width: 1000px) {
          aside#header-navigation.fixed-nav > .site-container #logo {
            width: 160px;
            height: 50px;
            margin: 0 0 0 40px; } }
        aside#header-navigation.fixed-nav > .site-container #logo a {
          background-image: url(../images/logo-saphetor-small.svg); }
          @media screen and (max-width: 1000px) {
            aside#header-navigation.fixed-nav > .site-container #logo a {
              background-image: url(../images/logo-saphetor.svg); } }
          html.no-svg aside#header-navigation.fixed-nav > .site-container #logo a {
            background-image: url(../images/logo-saphetor-small.png); }
            @media screen and (max-width: 1000px) {
              html.no-svg aside#header-navigation.fixed-nav > .site-container #logo a {
                background-image: url(../images/logo-saphetor.png); } }
      aside#header-navigation.fixed-nav > .site-container .slogan {
        height: 80px; }
      aside#header-navigation.fixed-nav > .site-container nav {
        float: right; }
        aside#header-navigation.fixed-nav > .site-container nav ul.general-nav {
          margin: 33px 57px 0 0; }
          @media screen and (max-width: 1000px) {
            aside#header-navigation.fixed-nav > .site-container nav ul.general-nav {
              margin: 148px 0 0 40px; } }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            aside#header-navigation.fixed-nav > .site-container nav ul.general-nav {
              margin: 90px 0 0 40px; } }
          aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li:not(:nth-last-of-type(1)) {
            margin: 0 60px 0 0; }
            @media screen and (max-width: 1380px) {
              aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li:not(:nth-last-of-type(1)) {
                margin: 0 45px 0 0; } }
            @media screen and (max-width: 1000px) {
              aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li:not(:nth-last-of-type(1)) {
                margin: 0; } }
          @media screen and (max-width: 1000px) {
            aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li:not(:nth-of-type(1)) {
              margin-top: 28px; } }
          aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li:nth-of-type(1) > a span:before {
            top: 60px; }
          aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li > ul.sub-nav {
            top: 60px; }
            @media screen and (max-width: 1000px) {
              aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li > ul.sub-nav {
                top: 0; } }
            aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li > ul.sub-nav > li {
              margin-top: 42px; }
              @media screen and (max-width: 1000px) {
                aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li > ul.sub-nav > li {
                  margin-top: 0; } }
          aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li > a {
            font-size: 14px; }
            @media screen and (max-width: 1000px) {
              aside#header-navigation.fixed-nav > .site-container nav ul.general-nav > li > a {
                font-size: 22px; } }
        aside#header-navigation.fixed-nav > .site-container nav .client-login {
          margin: 15px 15px 0 0; }
          @media screen and (max-width: 1000px) {
            aside#header-navigation.fixed-nav > .site-container nav .client-login {
              margin: 51px 0 0 0; } }
    @media screen and (max-width: 1000px) {
      aside#header-navigation.fixed-nav button#btn-open-nav {
        top: 16px; } }
  aside#header-navigation.navigation-opened nav {
    background: rgba(0, 0, 0, 0.2);
    pointer-events: inherit;
    opacity: 1;
    visibility: visible; }
    aside#header-navigation.navigation-opened nav .nav-container {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0); }
      aside#header-navigation.navigation-opened nav .nav-container .nav-contact-socials {
        opacity: 1; }
  aside#header-navigation button#btn-open-nav {
    width: 50px;
    height: 50px;
    position: fixed;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 35px;
    right: 33px;
    z-index: 100;
    display: none;
    transition: all .2s ease-in; }
    @media screen and (max-width: 1000px) {
      aside#header-navigation button#btn-open-nav {
        display: block; } }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      aside#header-navigation button#btn-open-nav {
        display: block;
        top: 25px; } }
    @media screen and (max-width: 650px) {
      aside#header-navigation button#btn-open-nav {
        display: block;
        top: 25px; } }
    aside#header-navigation button#btn-open-nav.active:before {
      margin-top: 0;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg); }
    aside#header-navigation button#btn-open-nav.active:after {
      margin-top: 0;
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
      transform: translate(-50%, -50%) rotate(-45deg); }
    aside#header-navigation button#btn-open-nav:before, aside#header-navigation button#btn-open-nav:after {
      width: 35px;
      height: 2px;
      content: "";
      position: absolute;
      overflow: hidden;
      top: 50%;
      left: 50%;
      background: #18C2A5;
      -webkit-transform: translate(-50%, -50%) rotate(0);
      -ms-transform: translate(-50%, -50%) rotate(0);
      transform: translate(-50%, -50%) rotate(0);
      transition: all .2s ease-in; }
    aside#header-navigation button#btn-open-nav:before {
      margin-top: -7px; }
    aside#header-navigation button#btn-open-nav:after {
      margin-top: 7px; }
  aside#header-navigation > .site-container {
    padding: 35px 0 34px;
    transition: all .2s ease-in; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      aside#header-navigation > .site-container {
        padding: 25px 0 24px; } }
    @media screen and (max-width: 650px) {
      aside#header-navigation > .site-container {
        padding: 25px 0 24px; } }
    aside#header-navigation > .site-container #logo {
      width: 160px;
      height: 50px;
      float: left;
      position: relative;
      overflow: hidden;
      top: -1px;
      margin: 0 0 0 50px;
      transition: all 0.2s ease-out; }
      @media screen and (max-width: 1200px) {
        aside#header-navigation > .site-container #logo {
          margin: 0 0 0 50px; } }
      @media screen and (max-width: 1000px) {
        aside#header-navigation > .site-container #logo {
          margin: 0 0 0 40px; } }
      aside#header-navigation > .site-container #logo > a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-image: url(../images/logo-saphetor.svg);
        transition: all 0.2s ease-out; }
        html.no-svg aside#header-navigation > .site-container #logo > a {
          background-image: url(../images/logo-saphetor.png); }
        aside#header-navigation > .site-container #logo > a > span,
        aside#header-navigation > .site-container #logo > a img {
          width: 0;
          height: 0;
          position: absolute;
          top: -999px;
          left: -999px;
          opacity: 0;
          font-size: 0; }
    aside#header-navigation > .site-container .slogan {
      height: 50px;
      float: left;
      margin: 0 0 0 40px;
      padding-left: 40px;
      display: table;
      position: relative;
      border-left: 1px solid rgba(35, 120, 101, 0.2);
      transition: all 0.2s ease-out; }
      @media screen and (max-width: 1200px) {
        aside#header-navigation > .site-container .slogan {
          display: none; } }
      aside#header-navigation > .site-container .slogan > span {
        display: table-cell;
        vertical-align: middle;
        opacity: 0.7;
        font-size: 10px;
        color: #127261;
        letter-spacing: 1.33px;
        line-height: 16px;
        text-transform: uppercase;
        transition: all 0.2s ease-out;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none; }
  aside#header-navigation nav {
    float: right; }
    @media screen and (max-width: 1000px) {
      aside#header-navigation nav {
        width: 100%;
        height: 100%;
        position: fixed;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        top: 0;
        left: 0;
        bottom: 0;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-in;
        z-index: 10; } }
    aside#header-navigation nav .nav-container {
      width: 100%;
      z-index: 10;
      pointer-events: inherit;
      transition: opacity 0.14s ease-out, transform 0.3s ease-out; }
      aside#header-navigation nav .nav-container::-webkit-scrollbar {
        display: none; }
      @media screen and (max-width: 1000px) {
        aside#header-navigation nav .nav-container {
          max-width: 280px;
          height: 100%;
          background: #FFF;
          float: right;
          opacity: 0;
          -webkit-transform: translateX(100%);
          -ms-transform: translateX(100%);
          transform: translateX(100%); } }
      aside#header-navigation nav .nav-container .nav-content {
        width: 100%;
        height: 100%;
        margin: 0 0 0 auto;
        -webkit-overflow-scrolling: touch;
        z-index: 20; }
        aside#header-navigation nav .nav-container .nav-content::-webkit-scrollbar {
          display: none; }
        @media screen and (max-width: 1000px) {
          aside#header-navigation nav .nav-container .nav-content {
            position: relative;
            overflow: hidden;
            overflow-y: scroll; } }
    aside#header-navigation nav ul.general-nav {
      float: left;
      font-size: 0;
      margin: 16px 80px 0 0;
      transition: all 0.2s ease-out; }
      @media screen and (max-width: 1380px) {
        aside#header-navigation nav ul.general-nav {
          margin: 16px 50px 0 0; } }
      @media screen and (max-width: 1000px) {
        aside#header-navigation nav ul.general-nav {
          margin: 148px 0 0 40px; } }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        aside#header-navigation nav ul.general-nav {
          margin: 90px 0 0 40px; } }
      aside#header-navigation nav ul.general-nav > li {
        display: inline-block;
        vertical-align: top; }
        @media screen and (max-width: 1000px) {
          aside#header-navigation nav ul.general-nav > li {
            width: 100%; } }
        aside#header-navigation nav ul.general-nav > li:not(:nth-last-of-type(1)) {
          margin: 0 75px 0 0;
          transition: all 0.2s ease-out; }
          @media screen and (max-width: 1380px) {
            aside#header-navigation nav ul.general-nav > li:not(:nth-last-of-type(1)) {
              margin: 0 45px 0 0; } }
          @media screen and (max-width: 1000px) {
            aside#header-navigation nav ul.general-nav > li:not(:nth-last-of-type(1)) {
              margin: 0; } }
        @media screen and (max-width: 1000px) {
          aside#header-navigation nav ul.general-nav > li:not(:nth-of-type(1)) {
            margin-top: 28px; } }
        aside#header-navigation nav ul.general-nav > li.has-submenu > a span.hovered:before {
          border-bottom: 15px solid #f9fefd; }
        aside#header-navigation nav ul.general-nav > li.has-submenu > a span:before {
          width: 0;
          height: 0;
          content: "";
          position: absolute;
          overflow: hidden;
          top: 80px;
          left: 50%;
          display: none;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 15px solid #FFF;
          pointer-events: none;
          z-index: -1;
          transition: all 0.22s ease-out;
          opacity: 0; }
        aside#header-navigation nav ul.general-nav > li.active > a {
          color: #127261; }
        aside#header-navigation nav ul.general-nav > li:hover.has-submenu > a span:before {
          -webkit-animation: opacitynav .22s linear forwards;
          animation: opacitynav .22s linear forwards;
          display: block; }
        aside#header-navigation nav ul.general-nav > li:hover > a {
          color: #127261; }
        aside#header-navigation nav ul.general-nav > li:hover > ul.sub-nav {
          display: block;
          -webkit-animation: opacitynav .22s linear forwards;
          animation: opacitynav .22s linear forwards; }
        aside#header-navigation nav ul.general-nav > li > a {
          position: relative;
          padding: 10px 0;
          font-size: 24px;
          font-family: "droid_serifregular";
          font-size: 16px;
          color: #444444;
          transition: all 0.2s ease-out; }
          @media screen and (max-width: 1000px) {
            aside#header-navigation nav ul.general-nav > li > a {
              font-size: 22px; } }
          aside#header-navigation nav ul.general-nav > li > a > span {
            position: relative;
            display: inline-block;
            vertical-align: top; }
        aside#header-navigation nav ul.general-nav > li > ul.sub-nav {
          width: 1170px;
          display: none;
          position: absolute;
          top: 79px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          z-index: 10;
          opacity: 0;
          transition: all 0.2s ease-out; }
          @media screen and (max-width: 1000px) {
            aside#header-navigation nav ul.general-nav > li > ul.sub-nav {
              display: block;
              opacity: 1;
              position: relative;
              top: 0;
              left: 0;
              -webkit-transform: translateX(0);
              -ms-transform: translateX(0);
              transform: translateX(0); } }
          aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li {
            width: 100%;
            display: block;
            font-size: 0;
            margin-top: 62px;
            position: relative;
            background: #FFFFFF;
            box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            transition: all 0.2s ease-out; }
            @media screen and (max-width: 1000px) {
              aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li {
                box-shadow: none;
                margin-top: 0;
                padding: 21px 0 0; } }
            aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a {
              width: 33.3333%;
              width: calc(100% / 3);
              display: inline-block;
              vertical-align: top;
              position: relative;
              box-sizing: border-box;
              transition: all 0.2s ease-out; }
              @media screen and (max-width: 1000px) {
                aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a {
                  width: 100%;
                  padding: 8px 0; } }
              aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a:nth-of-type(2) {
                border-left: 1px solid rgba(35, 120, 101, 0.2);
                border-right: 1px solid rgba(35, 120, 101, 0.2); }
                @media screen and (max-width: 1000px) {
                  aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a:nth-of-type(2) {
                    border: 0; } }
              aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a:hover {
                background: rgba(239, 252, 250, 0.4); }
                aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a:hover > .text p > span {
                  color: #127261; }
                aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a:hover > .btn-more:before {
                  height: 2px;
                  background: #18C2A5; }
                aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a:hover > .btn-more > span {
                  color: #127261; }
              aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a > .text {
                width: 100%;
                display: block;
                position: relative; }
                @media screen and (max-width: 1000px) {
                  aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a > .text {
                    height: auto; } }
                aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a > .text > span {
                  display: block;
                  margin-bottom: 20px;
                  padding: 45px 50px 0 50px;
                  font-family: "droid_serifregular";
                  font-size: 16px;
                  color: #444444;
                  transition: all 0.2s ease-out; }
                  @media screen and (max-width: 1000px) {
                    aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a > .text > span {
                      margin: 0;
                      padding: 0; } }
                aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a > .text > p {
                  min-height: 180px;
                  padding: 0 50px 20px 50px;
                  font-size: 14px;
                  color: #80A09B;
                  font-family: "basier_squareregular";
                  line-height: 22px; }
                  @media screen and (max-width: 1000px) {
                    aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a > .text > p {
                      display: none; } }
              aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a > .btn-more {
                width: 100%;
                height: 80px;
                display: table;
                position: relative; }
                @media screen and (max-width: 1000px) {
                  aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a > .btn-more {
                    display: none; } }
                aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a > .btn-more:before {
                  width: 100%;
                  height: 1px;
                  content: "";
                  position: absolute;
                  overflow: hidden;
                  top: 0;
                  left: 0;
                  background: rgba(35, 120, 101, 0.2);
                  transition: all 0.2s ease-out; }
                aside#header-navigation nav ul.general-nav > li > ul.sub-nav > li > a > .btn-more > span {
                  display: table-cell;
                  vertical-align: middle;
                  padding: 0 0 0 50px;
                  font-size: 12px;
                  color: #18C2A5;
                  letter-spacing: 1.6px;
                  transition: all 0.2s ease-out;
                  text-transform: uppercase; }

@-webkit-keyframes opacitynav {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes opacitynav {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    aside#header-navigation nav .client-login {
      float: left;
      margin: 0 40px 0 0;
      transition: all 0.2s ease-out; }
      @media screen and (max-width: 1000px) {
        aside#header-navigation nav .client-login {
          width: 100%;
          margin: 51px 0 0 0;
          padding: 24px 0 80px;
          border-top: 1px solid rgba(35, 120, 101, 0.2); } }
      aside#header-navigation nav .client-login > a {
        width: 140px;
        height: 50px;
        display: block;
        box-sizing: border-box;
        border: 1px solid rgba(35, 120, 101, 0.2);
        border-radius: 4px;
        text-align: center;
        line-height: 48px;
        font-size: 12px;
        color: #FFF;
        opacity: 1;
        background: #18C2A5;
        border: 1px solid #18C2A5;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        transition: all 0.2s ease-out; }
        aside#header-navigation nav .client-login > a:hover {
          color: #FFF;
          background: #127261;
          border: 1px solid #18C2A5; }
        @media screen and (max-width: 1000px) {
          aside#header-navigation nav .client-login > a {
            margin-left: 40px; } }

section.partners {
  padding: 100px 0 84px; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.partners {
      padding: 65px 0 50px; } }
  @media screen and (max-width: 650px) {
    section.partners {
      padding: 65px 0 50px; } }
  section.partners .header-title {
    text-align: center;
    float: none;
    clear: both; }
    section.partners .header-title h2 {
      font-size: 35px;
      line-height: 40px; }
      @media screen and (max-width: 650px) {
        section.partners .header-title h2 {
          font-size: 25px;
          line-height: 35px; } }
  section.partners div.partners-list {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none; }
    section.partners div.partners-list img {
      max-width: 100%;
      display: inline-block;
      transition: all 0.26s ease-out; }
      section.partners div.partners-list img:hover {
        -webkit-transform: scale(1.06);
        -ms-transform: scale(1.06);
        transform: scale(1.06); }

footer {
  padding: 97px 0 97px;
  background-color: #127261; }
  @media screen and (max-width: 770px) {
    footer {
      padding: 80px 0 80px; } }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    footer {
      padding: 40px 0 40px; } }
  @media screen and (max-width: 650px) {
    footer {
      padding: 40px 0 40px; } }
  footer a.ft-logo {
    top: 0;
    left: 0; }
  footer address {
    margin-top: 34px;
    opacity: 0.5;
    font-family: "basier_squareregular";
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
    color: #FFF; }
  footer .socials {
    font-size: 0;
    text-align: left;
    left: 0;
    z-index: 10; }
    footer .socials > a {
      width: 40px;
      height: 40px;
      display: inline-block;
      vertical-align: top;
      position: relative;
      left: -8px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: 50%; }
      @media screen and (max-width: 900px) {
        footer .socials > a {
          left: 0;
          margin: 0 2px; } }
      footer .socials > a:not(:nth-of-type(1)) {
        margin-left: 4px; }
        @media screen and (max-width: 900px) {
          footer .socials > a:not(:nth-of-type(1)) {
            margin-left: 2px; } }
      footer .socials > a > img {
        width: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
      footer .socials > a:hover svg path {
        fill: #FFF; }
  footer .ft-container {
    position: relative;
    z-index: 2; }
    footer .ft-container .items {
      font-size: 0; }
      @media screen and (max-width: 1100px) {
        footer .ft-container .items {
          padding-bottom: 0; } }
      @media screen and (max-width: 900px) {
        footer .ft-container .items {
          text-align: center; } }
      footer .ft-container .items > ul {
        display: inline-block;
        vertical-align: top;
        margin-top: 5px;
        text-align: left; }
        @media screen and (max-width: 900px) {
          footer .ft-container .items > ul {
            display: none; } }
        footer .ft-container .items > ul > li {
          display: block;
          margin: 16px 0;
          font-size: 14px; }
          footer .ft-container .items > ul > li:nth-of-type(1) {
            margin: 0;
            margin-bottom: 33px; }
            footer .ft-container .items > ul > li:nth-of-type(1) > span {
              text-transform: uppercase;
              font-size: 12px;
              color: #FFF;
              letter-spacing: 1.6px;
              opacity: 0.5; }
          footer .ft-container .items > ul > li a {
            padding: 10px 0;
            font-family: "basier_squareregular";
            font-size: 15px;
            color: #20D5B7;
            transition: all 0.24s ease-out; }
            footer .ft-container .items > ul > li a:hover {
              color: #FFF; }
      footer .ft-container .items .form-newsletter {
        display: inline-block;
        vertical-align: top;
        text-align: left; }
        @media screen and (max-width: 900px) {
          footer .ft-container .items .form-newsletter {
            width: 100%;
            text-align: center; } }
        footer .ft-container .items .form-newsletter > span {
          width: 100%;
          display: block;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none; }
          footer .ft-container .items .form-newsletter > span.title {
            text-transform: uppercase;
            font-size: 12px;
            color: #18C2A5;
            letter-spacing: 1.6px;
            margin-top: 7px; }
          footer .ft-container .items .form-newsletter > span.spam {
            margin-top: 26px;
            text-align: right;
            opacity: 0.5;
            font-family: "basier_squareregular";
            font-size: 14px;
            color: #FFF; }
            @media screen and (max-width: 900px) {
              footer .ft-container .items .form-newsletter > span.spam {
                text-align: center; } }
        footer .ft-container .items .form-newsletter form {
          margin-top: 37px;
          text-align: left; }
          @media screen and (max-width: 900px) {
            footer .ft-container .items .form-newsletter form {
              display: inline-block;
              vertical-align: top; } }
          @media screen and (max-width: 480px) {
            footer .ft-container .items .form-newsletter form {
              width: 100%; } }
          footer .ft-container .items .form-newsletter form label.item {
            width: 210px;
            height: 50px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            cursor: text;
            box-sizing: border-box;
            border: 1px solid rgba(190, 220, 215, 0.3);
            transition: all 0.24s ease-out;
            border-right: none;
            overflow: hidden;
            border-radius: 4px 0 0 4px; }
            footer .ft-container .items .form-newsletter form label.item:hover {
              border: 1px solid rgba(190, 220, 215, 0.5);
              border-right: none; }
              footer .ft-container .items .form-newsletter form label.item:hover > span {
                color: #FFF; }
            @media screen and (max-width: 480px) {
              footer .ft-container .items .form-newsletter form label.item {
                width: 100%;
                width: calc(100% - 107px); } }
            footer .ft-container .items .form-newsletter form label.item.active, footer .ft-container .items .form-newsletter form label.item.valid {
              border: 1px solid rgba(190, 220, 215, 0.5);
              border-right: none; }
              footer .ft-container .items .form-newsletter form label.item.active > span, footer .ft-container .items .form-newsletter form label.item.valid > span {
                color: rgba(255, 255, 255, 0); }
            footer .ft-container .items .form-newsletter form label.item.invalid {
              border: 1px solid #ff4646;
              border-right: none; }
              footer .ft-container .items .form-newsletter form label.item.invalid > span {
                color: #ff4646; }
            footer .ft-container .items .form-newsletter form label.item > span {
              position: absolute;
              top: 50%;
              left: 20px;
              pointer-events: none;
              font-family: "basier_squareregular";
              font-size: 15px;
              color: rgba(255, 255, 255, 0.5);
              margin-top: -1px;
              transition: all 0.24s ease-out;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%); }
            footer .ft-container .items .form-newsletter form label.item > input {
              width: 70%;
              width: calc(100% - 40px);
              height: 49px;
              position: absolute;
              top: 0;
              left: 20px;
              margin-top: -1px;
              border: 0;
              font-size: 15px;
              font-family: "basier_squareregular";
              color: #FFF;
              background: transparent;
              overflow: hidden; }
          footer .ft-container .items .form-newsletter form .send-form {
            width: 107px;
            height: 50px;
            float: right;
            position: relative; }
            footer .ft-container .items .form-newsletter form .send-form > button {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              overflow: hidden;
              background: #18C2A5;
              color: #FFF;
              font-family: "basier_squaremedium";
              font-size: 11px;
              letter-spacing: 2.51px;
              transition: all 0.24s ease-out;
              text-transform: uppercase;
              border-radius: 0 4px 4px 0; }
              footer .ft-container .items .form-newsletter form .send-form > button:hover {
                color: #127261;
                background-color: #FFF; }
  footer .project {
    padding: 57px 0 0;
    margin: 52px 0 0;
    font-family: "basier_squareregular";
    font-size: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.15); }
    @media screen and (max-width: 900px) {
      footer .project {
        text-align: center;
        margin: 34px 0 0;
        padding: 37px 0 0; } }
    footer .project span.copyright {
      color: #FFF; }
      @media screen and (max-width: 900px) {
        footer .project span.copyright {
          display: block; } }
    footer .project a.author {
      float: right;
      position: relative;
      top: -10px;
      padding: 10px 0;
      color: #18C2A5;
      font-size: 15px;
      color: #20D5B7;
      transition: all 0.24s ease-out; }
      @media screen and (max-width: 900px) {
        footer .project a.author {
          float: none;
          top: 0;
          display: inline-block;
          vertical-align: top;
          margin-top: 12px; } }
      @media screen and (max-width: 480px) {
        footer .project a.author {
          max-width: 182px;
          margin-top: 10px;
          line-height: 25px; } }
      footer .project a.author:hover {
        color: #FFF; }
    footer .project button#scroll-top {
      width: 60px;
      height: 40px;
      display: block;
      margin: 15px auto 0;
      clear: both;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: url(../images/icon-top.svg); }
      html.no-svg footer .project button#scroll-top {
        background-image: url(../images/icon-top.png); }

section.intr-solutions {
  border-top: 1px solid #DFECE9;
  padding: 99px 0; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.intr-solutions {
      padding: 0;
      border-top: 0;
      background: #18C2A5; } }
  @media screen and (max-width: 650px) {
    section.intr-solutions {
      border-top: 0;
      padding: 0;
      background: #18C2A5; } }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.intr-solutions .grid {
      padding: 0; } }
  @media screen and (max-width: 650px) {
    section.intr-solutions .grid {
      padding: 0; } }
  section.intr-solutions .wrap {
    padding: 30px 0;
    background: #18C2A5;
    border-radius: 4px; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.intr-solutions .wrap {
        border-radius: 0;
        padding: 67px 0 70px; } }
    @media screen and (max-width: 650px) {
      section.intr-solutions .wrap {
        border-radius: 0;
        padding: 67px 0 70px; } }
    section.intr-solutions .wrap .flex-container {
      height: 50px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.intr-solutions .wrap .flex-container {
          display: block;
          height: auto; } }
      @media screen and (max-width: 650px) {
        section.intr-solutions .wrap .flex-container {
          display: block;
          height: auto; } }
      section.intr-solutions .wrap .flex-container .flex-item-link {
        padding: 0;
        position: absolute;
        top: 30px;
        right: 35px; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          section.intr-solutions .wrap .flex-container .flex-item-link {
            position: relative;
            top: 0;
            right: 0;
            left: 40px; } }
        @media screen and (max-width: 650px) {
          section.intr-solutions .wrap .flex-container .flex-item-link {
            position: relative;
            top: 0;
            right: 0;
            left: 40px; } }
        section.intr-solutions .wrap .flex-container .flex-item-link a {
          float: right;
          height: 50px;
          padding: 0 24px;
          display: block;
          z-index: 5;
          box-sizing: border-box;
          border-radius: 4px;
          text-align: center;
          line-height: 50px;
          font-size: 12px;
          color: #18C2A5;
          letter-spacing: 1.6px;
          text-transform: uppercase;
          transition: all 0.2s ease-out;
          background: #FFF;
          box-shadow: 0 2px 4px 0 rgba(18, 114, 97, 0.1); }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.intr-solutions .wrap .flex-container .flex-item-link a {
              float: left;
              margin-top: 30px;
              left: 40px; } }
          @media screen and (max-width: 650px) {
            section.intr-solutions .wrap .flex-container .flex-item-link a {
              float: left;
              margin-top: 30px;
              left: 40px; } }
          section.intr-solutions .wrap .flex-container .flex-item-link a:hover {
            background: #127261; }
      section.intr-solutions .wrap .flex-container .flex-item {
        position: relative;
        padding: 0 40px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          section.intr-solutions .wrap .flex-container .flex-item {
            display: block; } }
        @media screen and (max-width: 650px) {
          section.intr-solutions .wrap .flex-container .flex-item {
            display: block; } }
        section.intr-solutions .wrap .flex-container .flex-item:nth-of-type(1):before {
          width: 1px;
          height: 50px;
          content: "";
          position: absolute;
          overflow: hidden;
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          background: rgba(35, 120, 101, 0.3); }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.intr-solutions .wrap .flex-container .flex-item:nth-of-type(1):before {
              display: none; } }
          @media screen and (max-width: 650px) {
            section.intr-solutions .wrap .flex-container .flex-item:nth-of-type(1):before {
              display: none; } }
        section.intr-solutions .wrap .flex-container .flex-item:nth-of-type(2) {
          padding: 0 240px 0 40px; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.intr-solutions .wrap .flex-container .flex-item:nth-of-type(2) {
              padding: 22px 40px 0; } }
          @media screen and (max-width: 650px) {
            section.intr-solutions .wrap .flex-container .flex-item:nth-of-type(2) {
              padding: 22px 40px 0; } }
        section.intr-solutions .wrap .flex-container .flex-item h4 {
          font-family: "droid_serifregular";
          font-size: 22px;
          color: #FFF; }
        section.intr-solutions .wrap .flex-container .flex-item p {
          font-family: "basier_squareregular";
          font-size: 15px;
          color: #FFFFFF;
          line-height: 25px;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.intr-solutions .wrap .flex-container .flex-item p {
              line-height: 20px; } }
          @media screen and (max-width: 650px) {
            section.intr-solutions .wrap .flex-container .flex-item p {
              line-height: 20px; } }

.hg-box-item {
  position: absolute;
  top: 72px;
  right: 70px;
  z-index: 10; }
  @media screen and (max-width: 1200px) {
    .hg-box-item {
      right: 0; } }
  @media screen and (max-width: 1000px) {
    .hg-box-item {
      float: left;
      position: relative;
      top: 0;
      right: 0;
      margin-top: 42px; } }
  @media screen and (max-width: 650px) {
    .hg-box-item {
      padding-bottom: 20px; } }
  .hg-box-item > a {
    width: 100%;
    display: block;
    float: left; }
    .hg-box-item > a:hover > .img-video:before {
      background-color: #18C2A5;
      background-image: url(../images/arrow-white.svg); }
      html.no-svg .hg-box-item > a:hover > .img-video:before {
        background-image: url(../images/arrow-white.png); }
    .hg-box-item > a:hover > .img-video .clmn {
      box-shadow: 0 10px 30px 0 rgba(27, 63, 57, 0.2); }
    .hg-box-item > a:hover > .title-video p {
      color: #444444; }
    .hg-box-item > a > .img-video {
      width: 170px;
      height: 110px;
      display: block;
      position: relative; }
      @media screen and (max-width: 1000px) {
        .hg-box-item > a > .img-video {
          float: left; } }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        .hg-box-item > a > .img-video {
          width: 136px;
          height: 88px; } }
      @media screen and (max-width: 650px) {
        .hg-box-item > a > .img-video {
          width: 136px;
          height: 88px; } }
      .hg-box-item > a > .img-video:before {
        width: 50px;
        height: 50px;
        content: "";
        position: absolute;
        overflow: hidden;
        right: 20px;
        bottom: -25px;
        z-index: 2;
        border-radius: 50%;
        transition: all 0.2s ease-out;
        background-color: #FFF;
        background-repeat: no-repeat;
        background-position: 53% center;
        background-image: url(../images/arrow-green.svg); }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          .hg-box-item > a > .img-video:before {
            width: 40px;
            height: 40px;
            right: 15px;
            bottom: -20px;
            background-size: 8px auto; } }
        @media screen and (max-width: 650px) {
          .hg-box-item > a > .img-video:before {
            width: 40px;
            height: 40px;
            right: 15px;
            bottom: -20px;
            background-size: 8px auto; } }
        html.no-svg .hg-box-item > a > .img-video:before {
          background-image: url(../images/arrow-green.png); }
      .hg-box-item > a > .img-video .clmn {
        width: 100%;
        height: 110px;
        display: block;
        position: relative;
        overflow: hidden;
        border-radius: 4px;
        font-size: 0;
        background: #FFF;
        box-shadow: 0 30px 70px 0 rgba(27, 63, 57, 0.4);
        transition: all 0.2s ease-out; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          .hg-box-item > a > .img-video .clmn {
            height: 88px;
            text-align: center; } }
        @media screen and (max-width: 650px) {
          .hg-box-item > a > .img-video .clmn {
            height: 88px;
            text-align: center; } }
        .hg-box-item > a > .img-video .clmn > img {
          width: 100%;
          max-width: 100%;
          border-radius: 4px;
          display: inline-block;
          vertical-align: top; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            .hg-box-item > a > .img-video .clmn > img {
              max-width: auto;
              width: auto;
              height: 100%; } }
          @media screen and (max-width: 650px) {
            .hg-box-item > a > .img-video .clmn > img {
              max-width: auto;
              width: auto;
              height: 100%; } }
    .hg-box-item > a > .title-video {
      width: 200px;
      display: block;
      position: relative;
      margin: 46px 0 0 0; }
      @media screen and (max-width: 1000px) {
        .hg-box-item > a > .title-video {
          float: left;
          margin: 0 0 0 30px; } }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        .hg-box-item > a > .title-video {
          width: 220px; } }
      @media screen and (max-width: 650px) {
        .hg-box-item > a > .title-video {
          width: 122px; } }
      .hg-box-item > a > .title-video > span {
        display: block;
        margin: 17px 0 0 0;
        opacity: 0.5;
        font-size: 12px;
        color: #127261;
        letter-spacing: 1.6px;
        transition: all 0.2s ease-out;
        text-transform: uppercase; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          .hg-box-item > a > .title-video > span {
            font-size: 11px;
            letter-spacing: 1.47px;
            margin: 3px 0 0; } }
        @media screen and (max-width: 650px) {
          .hg-box-item > a > .title-video > span {
            font-size: 11px;
            letter-spacing: 1.47px;
            margin: 3px 0 0; } }
      .hg-box-item > a > .title-video > p {
        max-height: 48px;
        margin: 18px 0 0 0;
        font-family: "droid_serifregular";
        font-size: 16px;
        color: #127261;
        line-height: 24px;
        transition: all 0.2s ease-out;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          .hg-box-item > a > .title-video > p {
            max-width: 100%;
            max-height: 62px;
            margin: 12px 0 0;
            font-size: 15px;
            line-height: 20px;
            -webkit-line-clamp: 3; } }
        @media screen and (max-width: 650px) {
          .hg-box-item > a > .title-video > p {
            max-width: 130px;
            max-height: 62px;
            margin: 12px 0 0;
            font-size: 15px;
            line-height: 20px;
            -webkit-line-clamp: 2; } }

.navigation-content {
  width: 300px;
  float: left;
  position: relative;
  z-index: 10;
  padding-bottom: 57px;
  position: absolute;
  top: 0;
  left: 0; }
  @media screen and (max-width: 1200px) {
    .navigation-content {
      width: 270px; } }
  @media screen and (max-width: 1000px) {
    .navigation-content {
      width: 100%;
      margin: 30px 0 0 0;
      position: relative;
      padding-bottom: 0; } }
  .navigation-content > span.sub-title {
    width: 100%;
    display: block;
    opacity: 0.5;
    font-size: 12px;
    color: #127261;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none; }
    @media screen and (max-width: 1000px) {
      .navigation-content > span.sub-title {
        display: none; } }
  .navigation-content > button {
    width: 100%;
    height: 50px;
    display: none;
    position: relative;
    background: #18C2A5;
    box-shadow: 0 2px 4px 0 rgba(18, 114, 97, 0.1);
    border-radius: 4px;
    text-transform: uppercase;
    text-align: left;
    font-family: "basier_squaremedium"; }
    @media screen and (max-width: 1000px) {
      .navigation-content > button {
        display: block; } }
    .navigation-content > button.active:before {
      -webkit-transform: rotate(-45deg) scaleY(1.5);
      -ms-transform: rotate(-45deg) scaleY(1.5);
      transform: rotate(-45deg) scaleY(1.5); }
    .navigation-content > button.active:after {
      -webkit-transform: rotate(45deg) scaleY(1.5);
      -ms-transform: rotate(45deg) scaleY(1.5);
      transform: rotate(45deg) scaleY(1.5); }
    .navigation-content > button:before, .navigation-content > button:after {
      width: 10px;
      height: 1px;
      content: "";
      position: absolute;
      overflow: hidden;
      top: 23px;
      right: 30px;
      background: #FFF;
      transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), transform-origin 0.4s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform-origin 0.4s cubic-bezier(0.86, 0, 0.07, 1), -ms-transform-origin 0.4s cubic-bezier(0.86, 0, 0.07, 1); }
    .navigation-content > button:before {
      right: 37px;
      -webkit-transform: rotate(45deg) scaleY(1.5);
      -ms-transform: rotate(45deg) scaleY(1.5);
      transform: rotate(45deg) scaleY(1.5); }
    .navigation-content > button:after {
      -webkit-transform: rotate(-45deg) scaleY(1.5);
      -ms-transform: rotate(-45deg) scaleY(1.5);
      transform: rotate(-45deg) scaleY(1.5); }
    .navigation-content > button > span {
      padding: 0 30px;
      font-size: 12px;
      color: #FFF;
      letter-spacing: 1.6px; }
  .navigation-content > ul.sub-nav {
    width: 100%;
    display: block;
    margin-top: 29px;
    background: #FFF;
    box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden; }
    @media screen and (max-width: 1000px) {
      .navigation-content > ul.sub-nav {
        display: none;
        margin-top: 0; } }
    .navigation-content > ul.sub-nav > li {
      display: block;
      position: relative; }
      .navigation-content > ul.sub-nav > li:not(:first-child) {
        border-top: 1px solid rgba(35, 120, 101, 0.2); }
      .navigation-content > ul.sub-nav > li:first-child a {
        padding-top: 20px; }
      .navigation-content > ul.sub-nav > li:last-child a {
        padding-bottom: 20px; }
      .navigation-content > ul.sub-nav > li.active a {
        background: rgba(239, 252, 250, 0.4); }
        .navigation-content > ul.sub-nav > li.active a > span {
          color: #127261; }
      .navigation-content > ul.sub-nav > li a {
        width: 100%;
        height: 69px;
        display: table;
        font-family: "droid_serifregular";
        font-size: 16px;
        color: #444444;
        transition: all 0.2s ease-out;
        background: #FFF; }
        @media screen and (max-height: 630px) and (max-width: 1000px) {
          .navigation-content > ul.sub-nav > li a {
            height: 59px; } }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          .navigation-content > ul.sub-nav > li a {
            height: 59px;
            font-size: 15px; } }
        @media screen and (max-width: 480px) {
          .navigation-content > ul.sub-nav > li a {
            height: 59px;
            font-size: 15px; } }
        .navigation-content > ul.sub-nav > li a > span {
          display: table-cell;
          vertical-align: middle;
          padding-left: 30px;
          transition: all 0.2s ease-out; }
        .navigation-content > ul.sub-nav > li a:hover {
          background: rgba(239, 252, 250, 0.4); }
          .navigation-content > ul.sub-nav > li a:hover > span {
            color: #127261; }

section.hp-products {
  padding: 60px 0 60px 0; }
  @media screen and (max-width: 770px) {
    section.hp-products {
      padding: 80px 0 80px; } }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.hp-products {
      padding: 40px 0 40px 0; } }
  @media screen and (max-width: 650px) {
    section.hp-products {
      padding: 66px 0 64px 0; } }
  section.hp-products .header-title {
    text-align: center; }
    section.hp-products .header-title h2 {
      font-size: 45px;
      line-height: 80px; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.hp-products .header-title h2 {
          font-size: 30px;
          line-height: 40px; } }
      @media screen and (max-width: 650px) {
        section.hp-products .header-title h2 {
          font-size: 30px;
          line-height: 40px; } }
  section.hp-products .grid {
    max-width: 1200px;
    padding: 0px; }
  section.hp-products .box-container.hp-boxes {
    margin: 42px 0 0 0; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.hp-products .box-container.hp-boxes {
        margin: 0 0 0; } }
    @media screen and (max-width: 650px) {
      section.hp-products .box-container.hp-boxes {
        margin: 15px 0 0; } }
    section.hp-products .box-container.hp-boxes > .item {
      height: auto;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 20px 15px; }
      @media screen and (max-width: 1000px) {
        section.hp-products .box-container.hp-boxes > .item {
          width: 100%;
          margin: 40px 0 0;
          display: block; } }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.hp-products .box-container.hp-boxes > .item {
          height: auto; } }
      @media screen and (max-width: 650px) {
        section.hp-products .box-container.hp-boxes > .item {
          height: auto; } }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.hp-products .box-container.hp-boxes > .item:nth-of-type(1) {
          margin: 20px 0 0; } }
      section.hp-products .box-container.hp-boxes > .item:nth-of-type(1) > a .cnt .img img {
        margin: 27px 0 0 0px; }
      section.hp-products .box-container.hp-boxes > .item:nth-of-type(2) > a:hover .cnt .img, section.hp-products .box-container.hp-boxes > .item:nth-of-type(2) > a:hover .cnt .title {
        -webkit-transform: translateY(-25px);
        -ms-transform: translateY(-25px);
        transform: translateY(-25px); }
      section.hp-products .box-container.hp-boxes > .item:nth-of-type(2) > a .cnt .img img {
        margin: 9px 0 0 18px; }
      section.hp-products .box-container.hp-boxes > .item:nth-of-type(3) > a:hover .cnt .img, section.hp-products .box-container.hp-boxes > .item:nth-of-type(3) > a:hover .cnt .title {
        -webkit-transform: translateY(-25px);
        -ms-transform: translateY(-25px);
        transform: translateY(-25px); }
      section.hp-products .box-container.hp-boxes > .item:nth-of-type(3) > a .cnt .img img {
        margin: 16px 0 0 5px; }
      section.hp-products .box-container.hp-boxes > .item > a {
        background: rgba(32, 213, 183, 0.07);
        border-radius: 4px;
        display: block;
        transition: all 0.26s ease-out; }
        section.hp-products .box-container.hp-boxes > .item > a:hover {
          background: #FFF;
          box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.1); }
          section.hp-products .box-container.hp-boxes > .item > a:hover > span {
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0);
            opacity: 1; }
            html.no-touch section.hp-products .box-container.hp-boxes > .item > a:hover > span {
              opacity: 1; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          section.hp-products .box-container.hp-boxes > .item > a {
            position: relative;
            display: block; } }
        @media screen and (max-width: 650px) {
          section.hp-products .box-container.hp-boxes > .item > a {
            position: relative;
            display: block; } }
        section.hp-products .box-container.hp-boxes > .item > a .cnt {
          position: relative;
          padding: 50px; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.hp-products .box-container.hp-boxes > .item > a .cnt {
              padding: 30px 30px 50px; } }
          @media screen and (max-width: 650px) {
            section.hp-products .box-container.hp-boxes > .item > a .cnt {
              padding: 30px 30px 50px; } }
          section.hp-products .box-container.hp-boxes > .item > a .cnt > .img {
            width: 100%;
            height: 220px;
            display: block;
            text-align: center;
            font-size: 0;
            transition: all 0.26s ease-out; }
            @media screen and (max-width: 820px) and (orientation: landscape) {
              section.hp-products .box-container.hp-boxes > .item > a .cnt > .img {
                height: 211px; } }
            @media screen and (max-width: 650px) {
              section.hp-products .box-container.hp-boxes > .item > a .cnt > .img {
                height: 211px; } }
            section.hp-products .box-container.hp-boxes > .item > a .cnt > .img img {
              max-width: 100%;
              display: inline-block;
              vertical-align: top; }
          section.hp-products .box-container.hp-boxes > .item > a .cnt > .title {
            width: 100%;
            display: block;
            margin: 9px 0 0 0;
            transition: all 0.26s ease-out; }
            @media screen and (max-width: 820px) and (orientation: landscape) {
              section.hp-products .box-container.hp-boxes > .item > a .cnt > .title {
                padding-bottom: 42px; } }
            @media screen and (max-width: 650px) {
              section.hp-products .box-container.hp-boxes > .item > a .cnt > .title {
                padding-bottom: 42px; } }
            section.hp-products .box-container.hp-boxes > .item > a .cnt > .title h3 {
              font-size: 26px; }
            section.hp-products .box-container.hp-boxes > .item > a .cnt > .title p {
              margin: 16px 0 0 0;
              font-family: "basier_squareregular";
              font-size: 15px;
              color: #80A09B;
              line-height: 25px; }
              @media screen and (max-width: 820px) and (orientation: landscape) {
                section.hp-products .box-container.hp-boxes > .item > a .cnt > .title p {
                  margin: 24px 0 0; } }
              @media screen and (max-width: 650px) {
                section.hp-products .box-container.hp-boxes > .item > a .cnt > .title p {
                  margin: 24px 0 0; } }
        section.hp-products .box-container.hp-boxes > .item > a > span {
          width: 100px;
          height: 30px;
          line-height: 30px;
          position: absolute;
          bottom: 10px;
          left: 50%;
          margin: 0 0 0 -53px;
          z-index: 3;
          font-family: "basier_squaremedium";
          font-size: 12px;
          color: #18C2A5;
          letter-spacing: 1.6px;
          transition: color 0.2s ease-out, opacity 0.25s ease-out, transform 0.25s ease-out;
          text-transform: uppercase;
          -webkit-transform: translateY(10px);
          -ms-transform: translateY(10px);
          transform: translateY(10px); }
          html.no-touch section.hp-products .box-container.hp-boxes > .item > a > span {
            opacity: 0; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.hp-products .box-container.hp-boxes > .item > a > span {
              bottom: 50px; } }
          @media screen and (max-width: 650px) {
            section.hp-products .box-container.hp-boxes > .item > a > span {
              bottom: 50px; } }
          section.hp-products .box-container.hp-boxes > .item > a > span:hover {
            color: #127261; }
  section.hp-products .key-benefits {
    padding: 0px; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.hp-products .key-benefits {
        padding: 58px 0 0 0; } }
    @media screen and (max-width: 650px) {
      section.hp-products .key-benefits {
        padding: 58px 0 0 0; } }
    section.hp-products .key-benefits .stc-con {
      padding-bottom: 93px;
      position: absolute;
      top: 50px;
      left: 15px; }
      html.mac section.hp-products .key-benefits .stc-con {
        top: 141px;
        padding-bottom: 82px; }
        @media screen and (max-width: 1200px) {
          html.mac section.hp-products .key-benefits .stc-con {
            top: 0;
            padding-bottom: 0; } }
      @media screen and (max-width: 1200px) {
        section.hp-products .key-benefits .stc-con {
          width: 100%;
          float: left;
          position: relative;
          top: 0;
          left: 0;
          padding: 0; } }
    section.hp-products .key-benefits .header-title {
      text-align: left; }
      @media screen and (max-width: 1200px) {
        section.hp-products .key-benefits .header-title {
          text-align: center; } }
      section.hp-products .key-benefits .header-title h2 {
        font-size: 70px;
        line-height: 75px; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          section.hp-products .key-benefits .header-title h2 {
            font-size: 50px;
            line-height: 60px; } }
        @media screen and (max-width: 650px) {
          section.hp-products .key-benefits .header-title h2 {
            font-size: 50px;
            line-height: 60px; } }
    section.hp-products .key-benefits > .columns {
      position: relative;
      padding: 42px 0 0 400px;
      font-size: 0;
      z-index: 6; }
      @media screen and (max-width: 1380px) {
        section.hp-products .key-benefits > .columns {
          padding: 42px 0 0 340px; } }
      @media screen and (max-width: 1200px) {
        section.hp-products .key-benefits > .columns {
          width: 100%;
          float: left;
          padding: 70px 0 0; } }
      @media screen and (max-width: 770px) {
        section.hp-products .key-benefits > .columns {
          padding: 0; } }
      section.hp-products .key-benefits > .columns > .clmn {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        position: relative; }
        @media screen and (max-width: 770px) {
          section.hp-products .key-benefits > .columns > .clmn {
            width: 100%; } }
        section.hp-products .key-benefits > .columns > .clmn .item {
          width: 100%;
          height: 210px;
          display: block;
          position: relative;
          background-repeat: no-repeat;
          background-position: 15px 0;
          background-image: url(../images/icon1.png); }
          @media screen and (max-width: 770px) {
            section.hp-products .key-benefits > .columns > .clmn .item {
              height: auto;
              margin-top: 60px;
              transform: translateX(0) scale(1) !important; } }
          @media screen and (max-width: 480px) {
            section.hp-products .key-benefits > .columns > .clmn .item {
              margin-top: 50px;
              background-position: 50% 0;
              text-align: center; } }
          html.no-svg section.hp-products .key-benefits > .columns > .clmn .item {
            background-image: url(../images/icon1.png); }
          section.hp-products .key-benefits > .columns > .clmn .item > img {
            position: absolute;
            top: 17px;
            left: 31px;
            pointer-events: none; }
            @media screen and (max-width: 480px) {
              section.hp-products .key-benefits > .columns > .clmn .item > img {
                left: 50%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%); } }
          section.hp-products .key-benefits > .columns > .clmn .item > h3 {
            padding: 18px 0 0 105px;
            font-family: "droid_serifregular";
            font-size: 22px;
            color: #444444; }
            @media screen and (max-width: 770px) {
              section.hp-products .key-benefits > .columns > .clmn .item > h3 {
                padding-top: 0; } }
            @media screen and (max-width: 480px) {
              section.hp-products .key-benefits > .columns > .clmn .item > h3 {
                padding: 90px 0 0 0; } }
          section.hp-products .key-benefits > .columns > .clmn .item > p {
            padding: 13px 20px 0 105px;
            font-size: 14px;
            color: #80A09B;
            line-height: 24px; }
            @media screen and (max-width: 820px) and (orientation: landscape) {
              section.hp-products .key-benefits > .columns > .clmn .item > p {
                font-size: 15px;
                line-height: 24px; } }
            @media screen and (max-width: 480px) {
              section.hp-products .key-benefits > .columns > .clmn .item > p {
                padding: 13px 0 0;
                font-size: 15px;
                line-height: 24px; } }

section.hp-about {
  padding: 165px 0 150px;
  background: #EFFCFA;
  overflow: hidden; }
  @media screen and (max-width: 1100px) {
    section.hp-about {
      text-align: center;
      padding: 165px 0 0; } }
  @media screen and (max-width: 770px) {
    section.hp-about {
      padding: 100px 0 0; } }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.hp-about {
      padding: 64px 0 0; } }
  @media screen and (max-width: 650px) {
    section.hp-about {
      padding: 64px 0 0; } }
  section.hp-about .header-title {
    display: inline-block;
    position: relative; }
    section.hp-about .header-title h2 {
      font-size: 35px;
      line-height: 40px; }
      @media screen and (max-width: 650px) {
        section.hp-about .header-title h2 {
          font-size: 25px;
          line-height: 35px; } }
    section.hp-about .header-title p {
      margin: 28px 0 0 0;
      font-family: "basier_squareregular";
      font-size: 16px;
      color: #80A09B;
      line-height: 28px; }
      @media screen and (max-width: 650px) {
        section.hp-about .header-title p {
          margin: 31px 0 0 0; } }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.hp-about .header-title p {
          margin: 31px 0 0 0; } }
    section.hp-about .header-title .link-cnt {
      margin: 39px 0 0 0; }
      @media screen and (max-width: 650px) {
        section.hp-about .header-title .link-cnt {
          margin: 27px 0 0 0; } }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.hp-about .header-title .link-cnt {
          margin: 27px 0 0 0; } }
      section.hp-about .header-title .link-cnt:before {
        left: 150px; }
        @media screen and (max-width: 650px) {
          section.hp-about .header-title .link-cnt:before {
            left: 137px; } }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          section.hp-about .header-title .link-cnt:before {
            left: 137px; } }
  section.hp-about img {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -50px;
    pointer-events: none; }
    @media screen and (max-width: 1200px) {
      section.hp-about img {
        margin-left: -120px; } }
    @media screen and (max-width: 1100px) {
      section.hp-about img {
        max-width: 120%;
        position: relative;
        left: -10%;
        display: inline-block;
        vertical-align: top;
        margin: 30px auto 0; } }

section.about-us {
  padding: 85px 0 114px; }
  section.about-us a {
    text-decoration: underline;
    color: #127261;
    transition: all 0.2s ease-out; }
    section.about-us a:hover {
      color: #18C2A5; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.about-us {
      padding: 40px 0 50px 0; } }
  @media screen and (max-width: 650px) {
    section.about-us {
      padding: 66px 0 60px 0; } }
  section.about-us .about-column {
    width: 50%;
    float: left;
    position: relative; }
    @media screen and (max-width: 770px) {
      section.about-us .about-column {
        width: 100%; } }
    section.about-us .about-column > article {
      position: relative; }
    section.about-us .about-column.left article {
      padding-right: 85px; }
      @media screen and (max-width: 1000px) {
        section.about-us .about-column.left article {
          padding-right: 40px; } }
      @media screen and (max-width: 770px) {
        section.about-us .about-column.left article {
          padding: 0; } }
    @media screen and (max-width: 770px) {
      section.about-us .about-column.right {
        margin-top: 50px; } }
    section.about-us .about-column.right article {
      padding-left: 85px; }
      @media screen and (max-width: 1000px) {
        section.about-us .about-column.right article {
          padding-left: 40px; } }
      @media screen and (max-width: 770px) {
        section.about-us .about-column.right article {
          padding: 0; } }
    section.about-us .about-column .header-title {
      float: none;
      display: block;
      padding-bottom: 28px;
      border-bottom: 2px solid #18C2A5; }
      section.about-us .about-column .header-title h2 {
        font-size: 45px;
        line-height: 80px; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          section.about-us .about-column .header-title h2 {
            font-size: 30px;
            line-height: 40px; } }
        @media screen and (max-width: 650px) {
          section.about-us .about-column .header-title h2 {
            font-size: 30px;
            line-height: 40px; } }
    section.about-us .about-column p {
      padding: 52px 0 0;
      font-family: "basier_squareregular";
      font-size: 16px;
      color: #80A09B;
      line-height: 28px; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.about-us .about-column p {
          padding: 32px 0 0; } }
      @media screen and (max-width: 650px) {
        section.about-us .about-column p {
          padding: 32px 0 0; } }
      section.about-us .about-column p b,
      section.about-us .about-column p strong {
        color: #127261;
        font-family: "basier_squaresemibold"; }

blockquote {
  padding: 143px 0 0;
  max-width: 900px; }
  @media screen and (max-width: 1000px) {
    blockquote {
      padding: 100px 0 0; } }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    blockquote {
      padding: 50px 0 0; } }
  @media screen and (max-width: 650px) {
    blockquote {
      padding: 60px 0 0; } }
  blockquote:before {
    display: none; }
  blockquote p {
    font-family: "ClarendonLTStd-Light";
    font-size: 45px;
    color: #127261;
    line-height: 60px;
    padding-bottom: 38px;
    border-bottom: 2px solid #18C2A5; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      blockquote p {
        font-size: 30px;
        line-height: 40px; } }
    @media screen and (max-width: 650px) {
      blockquote p {
        font-size: 30px;
        line-height: 40px;
        padding-bottom: 20px; } }
  blockquote > span {
    display: block;
    margin-top: 22px;
    opacity: 0.5;
    font-size: 12px;
    color: #127261;
    letter-spacing: 1.6px;
    line-height: 24px;
    text-transform: uppercase; }

.wrap.mid-line:before {
  width: 1px;
  height: 100%;
  content: "";
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 50%;
  opacity: 0.2;
  background: #237865; }
  @media screen and (max-width: 770px) {
    .wrap.mid-line:before {
      display: none; } }

section.about-timeline {
  padding: 131px 0 147px;
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid rgba(35, 120, 101, 0.2);
  border-bottom: 1px solid rgba(35, 120, 101, 0.2);
  z-index: 6; }
  @media screen and (max-width: 1170px) {
    section.about-timeline {
      padding: 90px 0 30px; } }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.about-timeline {
      padding: 50px 0; } }
  @media screen and (max-width: 650px) {
    section.about-timeline {
      padding: 50px 0; } }
  section.about-timeline ~ section#partners {
    padding: 100px 0 94px; }
    @media screen and (max-width: 900px) {
      section.about-timeline ~ section#partners {
        padding: 100px 0 84px; } }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.about-timeline ~ section#partners {
        padding: 65px 0 50px; } }
    @media screen and (max-width: 650px) {
      section.about-timeline ~ section#partners {
        padding: 65px 0 50px; } }
  section.about-timeline .grid {
    max-width: 1500px;
    padding: 0; }
    @media screen and (max-width: 1000px) {
      section.about-timeline .grid {
        padding: 0; } }
    @media screen and (max-width: 350px) {
      section.about-timeline .grid {
        padding: 0; } }
  section.about-timeline .wrap:before, section.about-timeline .wrap:after {
    width: 200px;
    height: 100%;
    content: "";
    position: absolute;
    overflow: hidden;
    top: 0;
    -webkit-background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    -webkit-background-image: -webkit-linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    -moz-background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    pointer-events: none;
    z-index: 50; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.about-timeline .wrap:before, section.about-timeline .wrap:after {
        width: 80px; } }
    @media screen and (max-width: 480px) {
      section.about-timeline .wrap:before, section.about-timeline .wrap:after {
        width: 80px; } }
  section.about-timeline .wrap:before {
    left: 0;
    -webkit-background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    -webkit-background-image: -webkit-linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    -moz-background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%); }
  section.about-timeline .wrap:after {
    right: 0;
    -webkit-background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    -webkit-background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    -moz-background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%); }
  section.about-timeline [data-fluffy-container] {
    width: 100%;
    display: block;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px; }
    section.about-timeline [data-fluffy-container]::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      background-color: transparent; }
    section.about-timeline [data-fluffy-container]::-webkit-scrollbar-thumb {
      display: none !important; }
  section.about-timeline [data-fluffy-container].is-ready {
    background-color: transparent; }
  section.about-timeline [data-fluffy-container].is-ready [data-fluffy-content],
  section.about-timeline [data-fluffy-container].is-moving [data-fluffy-scrollbars] {
    opacity: 1; }
  section.about-timeline [data-fluffy-scrollbars] {
    display: none; }
  section.about-timeline [data-fluffy-scrollbars] .is-horizontal,
  section.about-timeline [data-fluffy-scrollbars] .is-vertical {
    display: none; }
  section.about-timeline [data-fluffy-content] {
    height: 100%;
    position: relative;
    list-style-type: none;
    margin: 0; }
    section.about-timeline [data-fluffy-content]:before {
      width: 100%;
      height: 2px;
      content: "";
      position: absolute;
      overflow: hidden;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #18C2A5;
      pointer-events: none; }
    section.about-timeline [data-fluffy-content]::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      background-color: transparent; }
    section.about-timeline [data-fluffy-content]::-webkit-scrollbar-thumb {
      display: none !important; }
  section.about-timeline .end-item {
    width: 180px;
    display: inline-block;
    vertical-align: top; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.about-timeline .end-item {
        width: 90px; } }
    @media screen and (max-width: 480px) {
      section.about-timeline .end-item {
        width: 90px; } }
  section.about-timeline .slide-item-btn {
    width: 230px;
    height: 270px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: left;
    box-sizing: content-box; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.about-timeline .slide-item-btn {
        height: 200px; } }
    @media screen and (max-width: 480px) {
      section.about-timeline .slide-item-btn {
        height: 200px; } }
    html.no-touch section.about-timeline .slide-item-btn.hovered:after {
      background: #18C2A5; }
    section.about-timeline .slide-item-btn:before {
      width: 1px;
      height: 100px;
      content: "";
      position: absolute;
      overflow: hidden;
      right: 0;
      opacity: 0.2;
      background: #237865;
      z-index: 0;
      pointer-events: none; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.about-timeline .slide-item-btn:before {
          height: 65px; } }
      @media screen and (max-width: 480px) {
        section.about-timeline .slide-item-btn:before {
          height: 65px; } }
    section.about-timeline .slide-item-btn:after {
      width: 13px;
      height: 13px;
      content: "";
      position: absolute;
      overflow: hidden;
      right: -8px;
      background: #FFF;
      border: 2px solid #18C2A5;
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      transition: all 0.2s ease-out; }
    section.about-timeline .slide-item-btn:nth-of-type(1) {
      width: 364px; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.about-timeline .slide-item-btn:nth-of-type(1) {
          width: 230px; } }
      @media screen and (max-width: 480px) {
        section.about-timeline .slide-item-btn:nth-of-type(1) {
          width: 230px; } }
    section.about-timeline .slide-item-btn:nth-of-type(odd) button {
      top: -8px; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.about-timeline .slide-item-btn:nth-of-type(odd) button {
          top: 0; } }
      @media screen and (max-width: 480px) {
        section.about-timeline .slide-item-btn:nth-of-type(odd) button {
          top: 0; } }
      html.touch section.about-timeline .slide-item-btn:nth-of-type(odd) button {
        top: 1px; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          html.touch section.about-timeline .slide-item-btn:nth-of-type(odd) button {
            top: 8px; } }
        @media screen and (max-width: 480px) {
          html.touch section.about-timeline .slide-item-btn:nth-of-type(odd) button {
            top: 8px; } }
      html.mac section.about-timeline .slide-item-btn:nth-of-type(odd) button {
        top: 1px; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          html.mac section.about-timeline .slide-item-btn:nth-of-type(odd) button {
            top: 8px; } }
        @media screen and (max-width: 480px) {
          html.mac section.about-timeline .slide-item-btn:nth-of-type(odd) button {
            top: 8px; } }
    section.about-timeline .slide-item-btn:nth-of-type(odd):before {
      top: 34px; }
    section.about-timeline .slide-item-btn:nth-of-type(odd):after {
      top: 17px; }
    section.about-timeline .slide-item-btn:nth-of-type(even) button {
      bottom: -10px; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.about-timeline .slide-item-btn:nth-of-type(even) button {
          bottom: 0; } }
      @media screen and (max-width: 480px) {
        section.about-timeline .slide-item-btn:nth-of-type(even) button {
          bottom: 0; } }
      html.touch section.about-timeline .slide-item-btn:nth-of-type(even) button {
        bottom: -20px; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          html.touch section.about-timeline .slide-item-btn:nth-of-type(even) button {
            bottom: -8px; } }
        @media screen and (max-width: 480px) {
          html.touch section.about-timeline .slide-item-btn:nth-of-type(even) button {
            bottom: -8px; } }
      html.mac section.about-timeline .slide-item-btn:nth-of-type(even) button {
        bottom: -20px; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          html.mac section.about-timeline .slide-item-btn:nth-of-type(even) button {
            bottom: -8px; } }
        @media screen and (max-width: 480px) {
          html.mac section.about-timeline .slide-item-btn:nth-of-type(even) button {
            bottom: -8px; } }
    section.about-timeline .slide-item-btn:nth-of-type(even):before {
      bottom: 34px; }
    section.about-timeline .slide-item-btn:nth-of-type(even):after {
      bottom: 17px; }
    section.about-timeline .slide-item-btn > button {
      width: 100%;
      position: absolute;
      right: -10px;
      font-family: "ClarendonLTStd-Light";
      font-size: 70px;
      line-height: 1;
      text-align: right;
      color: #127261;
      transition: all 0.2s ease-out;
      z-index: 10; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.about-timeline .slide-item-btn > button {
          font-size: 50px; } }
      @media screen and (max-width: 480px) {
        section.about-timeline .slide-item-btn > button {
          font-size: 50px; } }
      section.about-timeline .slide-item-btn > button > span {
        position: relative;
        left: -40px; }
      section.about-timeline .slide-item-btn > button:hover:before {
        background-color: #FFF; }

section.about-team {
  padding: 101px 0 99px;
  background: #EFFCFA; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.about-team {
      padding: 50px 0 65px; } }
  @media screen and (max-width: 650px) {
    section.about-team {
      padding: 50px 0 65px; } }
  section.about-team ~ section.intr-solutions {
    border-top: 0; }
  section.about-team .grid {
    max-width: 1200px; }
    @media screen and (max-width: 1290px) {
      section.about-team .grid {
        padding: 0 45px; } }
    @media screen and (max-width: 1000px) {
      section.about-team .grid {
        padding: 0 25px; } }
  section.about-team .header-title {
    text-align: center; }
    section.about-team .header-title h2 {
      font-size: 35px; }
  section.about-team ul.team-list {
    font-size: 0;
    margin-top: 25px; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.about-team ul.team-list {
        margin-top: 0; } }
    @media screen and (max-width: 650px) {
      section.about-team ul.team-list {
        margin-top: 0; } }
    section.about-team ul.team-list > li {
      width: 22%;
      width: calc(25% - 30px);
      display: inline-block;
      vertical-align: top;
      margin: 58px 15px 0; }
      section.about-team ul.team-list > li div.team-cv {
        display: none; }
      @media screen and (max-width: 770px) {
        section.about-team ul.team-list > li {
          width: 45%;
          width: calc(50% - 30px); } }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.about-team ul.team-list > li {
          margin-top: 40px; } }
      @media screen and (max-width: 480px) {
        section.about-team ul.team-list > li {
          width: 90%;
          width: calc(100% - 30px);
          margin-top: 40px; } }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.about-team ul.team-list > li:nth-of-type(1), section.about-team ul.team-list > li:nth-of-type(2) {
          margin-top: 40px; } }
      @media screen and (max-width: 650px) {
        section.about-team ul.team-list > li:nth-of-type(1), section.about-team ul.team-list > li:nth-of-type(2) {
          margin-top: 40px; } }
      section.about-team ul.team-list > li > a, section.about-team ul.team-list > li div {
        display: block;
        position: relative;
        color: #fff;
        text-decoration: none; }
        section.about-team ul.team-list > li > a:hover .row, section.about-team ul.team-list > li div:hover .row {
          box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.1); }
        section.about-team ul.team-list > li > a:hover .title:before, section.about-team ul.team-list > li > a:hover .title:after, section.about-team ul.team-list > li div:hover .title:before, section.about-team ul.team-list > li div:hover .title:after {
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
          opacity: 1; }
        section.about-team ul.team-list > li > a .row, section.about-team ul.team-list > li div .row {
          width: 100%;
          display: inline-block;
          position: relative;
          vertical-align: top;
          background-repeat: no-repeat;
          background-position: 50% 0;
          background-size: cover;
          overflow: hidden;
          border-radius: 4px;
          transition: all 0.2s ease-out;
          z-index: 1; }
          section.about-team ul.team-list > li > a .row:before, section.about-team ul.team-list > li div .row:before {
            content: "";
            display: block;
            padding-top: 100%; }
        section.about-team ul.team-list > li > a .title, section.about-team ul.team-list > li div .title {
          width: 100%;
          display: block;
          position: relative;
          padding: 39px 0 0;
          z-index: 3; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.about-team ul.team-list > li > a .title, section.about-team ul.team-list > li div .title {
              padding-top: 20px; } }
          @media screen and (max-width: 480px) {
            section.about-team ul.team-list > li > a .title, section.about-team ul.team-list > li div .title {
              padding-top: 20px; } }
          section.about-team ul.team-list > li > a .title h3, section.about-team ul.team-list > li div .title h3 {
            font-family: DroidSerif;
            font-size: 16px;
            color: #444444; }
          section.about-team ul.team-list > li > a .title > span, section.about-team ul.team-list > li div .title > span {
            display: block;
            margin-top: 12px;
            opacity: 0.5;
            font-size: 12px;
            color: #127261;
            letter-spacing: 1.6px;
            text-transform: uppercase; }
      section.about-team ul.team-list > li > a .title:before, section.about-team ul.team-list > li > a .title:after {
        content: "";
        position: absolute;
        overflow: hidden;
        background: #18C2A5;
        -webkit-transform: translateY(5px);
        -ms-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 0;
        transition: all 0.26s ease-out; }
      section.about-team ul.team-list > li > a .title:before {
        width: 14px;
        height: 2px;
        top: 46px;
        right: 10px; }
      section.about-team ul.team-list > li > a .title:after {
        width: 2px;
        height: 14px;
        top: 40px;
        right: 16px; }

.about-lightbox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 101;
  display: table;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  -webkit-animation: zoom 0.3s ease-out forwards;
  animation: zoom 0.3s ease-out forwards; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    .about-lightbox {
      display: block; } }
  @media screen and (max-width: 650px) {
    .about-lightbox {
      display: block; } }

@-webkit-keyframes zoom {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes zoom {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }
  .about-lightbox.fadeout {
    animation: zooms 0.3s ease-out forwards; }

@keyframes zooms {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }
  .about-lightbox > .grid {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    position: relative;
    text-align: left; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      .about-lightbox > .grid {
        height: 100%;
        display: block;
        float: left;
        background: #FFF;
        padding: 0; } }
    @media screen and (max-width: 650px) {
      .about-lightbox > .grid {
        height: 100%;
        display: block;
        float: left;
        background: #FFF;
        padding: 0; } }
    .about-lightbox > .grid .about-box-cnt {
      max-width: 700px;
      margin: 0 auto;
      position: relative;
      background: #FFF;
      border-radius: 4px; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        .about-lightbox > .grid .about-box-cnt {
          width: 100%;
          height: 100%;
          -webkit-overflow-scrolling: touch;
          z-index: 11;
          position: relative;
          overflow: hidden;
          overflow-y: scroll;
          border-radius: 0; }
          .about-lightbox > .grid .about-box-cnt::-webkit-scrollbar {
            display: none; } }
      @media screen and (max-width: 650px) {
        .about-lightbox > .grid .about-box-cnt {
          width: 100%;
          height: 100%;
          -webkit-overflow-scrolling: touch;
          z-index: 11;
          position: relative;
          overflow: hidden;
          overflow-y: scroll;
          border-radius: 0; }
          .about-lightbox > .grid .about-box-cnt::-webkit-scrollbar {
            display: none; } }
      .about-lightbox > .grid .about-box-cnt > button.close-about-modal {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 25px;
        right: 25px;
        z-index: 12;
        transition: all 0.24s ease-out; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          .about-lightbox > .grid .about-box-cnt > button.close-about-modal {
            position: fixed;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden; } }
        @media screen and (max-width: 650px) {
          .about-lightbox > .grid .about-box-cnt > button.close-about-modal {
            position: fixed;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden; } }
        .about-lightbox > .grid .about-box-cnt > button.close-about-modal:hover:before, .about-lightbox > .grid .about-box-cnt > button.close-about-modal:hover:after {
          background: #127261; }
        .about-lightbox > .grid .about-box-cnt > button.close-about-modal:before, .about-lightbox > .grid .about-box-cnt > button.close-about-modal:after {
          width: 26px;
          height: 2px;
          content: "";
          position: absolute;
          overflow: hidden;
          top: 50%;
          left: 50%;
          transition: all 0.24s ease-out;
          background: #18C2A5; }
        .about-lightbox > .grid .about-box-cnt > button.close-about-modal:before {
          -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          -ms-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg); }
        .about-lightbox > .grid .about-box-cnt > button.close-about-modal:after {
          -webkit-transform: translate(-50%, -50%) rotate(45deg);
          -ms-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg); }
      .about-lightbox > .grid .about-box-cnt .about-modal-header {
        width: 100%;
        display: block;
        position: relative;
        padding-bottom: 60px; }
        .about-lightbox > .grid .about-box-cnt .about-modal-header > h5 {
          font-size: 35px;
          padding: 62px 60px 0; }
        .about-lightbox > .grid .about-box-cnt .about-modal-header > span {
          padding: 0 60px;
          opacity: 0.5;
          font-size: 12px;
          color: #127261;
          letter-spacing: 1.6px;
          text-transform: uppercase; }
        .about-lightbox > .grid .about-box-cnt .about-modal-header > p {
          margin-top: 44px;
          padding: 41px 60px 0;
          font-family: "basier_squareregular";
          font-size: 16px;
          color: #80A09B;
          line-height: 28px;
          border-top: 1px solid rgba(35, 120, 101, 0.2); }
        .about-lightbox > .grid .about-box-cnt .about-modal-header > a {
          height: 24px;
          display: table;
          padding: 0 0 0 34px;
          margin: 35px 0 0 60px;
          font-size: 12px;
          color: #18C2A5;
          letter-spacing: 1.6px;
          line-height: 24px;
          text-transform: uppercase;
          background-repeat: no-repeat;
          background-position: 0 50%;
          background-image: url(../images/icon-linkedin.svg);
          transition: all 0.2s ease-out; }
          .about-lightbox > .grid .about-box-cnt .about-modal-header > a:hover {
            color: #127261;
            background-image: url(../images/icon-linkedin_hover.svg); }
          html.no-svg .about-lightbox > .grid .about-box-cnt .about-modal-header > a {
            background-image: url(../images/icon-linkedin.png); }

section.product-columns {
  padding: 80px 0 0; }
  section.product-columns a {
    text-decoration: underline; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.product-columns {
      padding: 40px 0 50px 0; } }
  @media screen and (max-width: 650px) {
    section.product-columns {
      padding: 66px 0 60px 0; } }
  @media screen and (max-width: 1000px) {
    section.product-columns .header-title {
      text-align: center; } }
  section.product-columns .header-title h2 {
    font-size: 45px; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.product-columns .header-title h2 {
        font-size: 30px;
        line-height: 40px; } }
    @media screen and (max-width: 650px) {
      section.product-columns .header-title h2 {
        font-size: 30px;
        line-height: 40px; } }
  section.product-columns .wrap {
    counter-reset: my-badass-counter; }
  section.product-columns .columns {
    font-size: 0; }
    @media screen and (max-width: 1000px) {
      section.product-columns .columns {
        margin-top: 0; } }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.product-columns .columns {
        margin-top: 0; } }
    @media screen and (max-width: 1000px) {
      section.product-columns .columns:nth-of-type(2) {
        margin-top: 0; } }
    section.product-columns .columns > .clmn {
      width: 50%;
      display: inline-block;
      vertical-align: top; }
      @media screen and (max-width: 1000px) {
        section.product-columns .columns > .clmn {
          width: 100%;
          text-align: center; } }
      section.product-columns .columns > .clmn.left {
        padding-top: 10px; }
        @media screen and (max-width: 1000px) {
          section.product-columns .columns > .clmn.left {
            padding-top: 0; } }
        section.product-columns .columns > .clmn.left img {
          max-width: 100%;
          display: inline-block;
          vertical-align: top;
          position: relative; }
      section.product-columns .columns > .clmn.right {
        padding-top: 57px; }
        @media screen and (max-width: 1000px) {
          section.product-columns .columns > .clmn.right {
            padding-top: 0; } }
        section.product-columns .columns > .clmn.right > article {
          position: relative;
          padding: 11px 0 0 15px; }
          @media screen and (max-width: 1000px) {
            section.product-columns .columns > .clmn.right > article {
              padding: 11px 0 0 0; } }
          section.product-columns .columns > .clmn.right > article h3 {
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: 35px;
            line-height: 70px;
            border-bottom: 2px solid #18C2A5; }
            @media screen and (max-width: 820px) and (orientation: landscape) {
              section.product-columns .columns > .clmn.right > article h3 {
                font-size: 26px;
                line-height: 31px; } }
            @media screen and (max-width: 650px) {
              section.product-columns .columns > .clmn.right > article h3 {
                font-size: 26px;
                line-height: 31px; } }
            section.product-columns .columns > .clmn.right > article h3:before {
              padding: 0 15px 0 0;
              content: counter(my-badass-counter) "";
              counter-increment: my-badass-counter;
              font-family: "ClarendonLTStd-Light";
              font-size: 70px;
              color: #127261; }
              @media screen and (max-width: 1000px) {
                section.product-columns .columns > .clmn.right > article h3:before {
                  padding: 0; } }
          section.product-columns .columns > .clmn.right > article p {
            padding-top: 28px;
            font-family: "basier_squareregular";
            font-size: 16px;
            color: #80A09B;
            line-height: 28px; }
            section.product-columns .columns > .clmn.right > article p b,
            section.product-columns .columns > .clmn.right > article p strong {
              color: #127261;
              font-family: "basier_squaresemibold"; }
            section.product-columns .columns > .clmn.right > article p a {
              color: #127261;
              transition: all 0.2s ease-out; }
              section.product-columns .columns > .clmn.right > article p a:hover {
                color: #18C2A5; }

section.product-know {
  padding: 99px 0 100px;
  background: #EFFCFA; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.product-know {
      margin-top: 0;
      padding: 40px 0 60px 0; } }
  @media screen and (max-width: 650px) {
    section.product-know {
      margin-top: 0;
      padding: 66px 0 70px 0; } }
  section.product-know ~ section.intr-solutions {
    border-top: 0; }
  section.product-know .header-title {
    text-align: center; }
    section.product-know .header-title h2 {
      font-size: 45px; }
  section.product-know ul.know-list {
    font-size: 0;
    margin-top: 52px; }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.product-know ul.know-list {
        margin-top: 25px; } }
    @media screen and (max-width: 650px) {
      section.product-know ul.know-list {
        margin-top: 25px; } }
    section.product-know ul.know-list > li {
      width: 50%;
      width: calc(50% - 15px);
      min-height: 240px;
      margin-top: 30px;
      padding-bottom: 50px;
      display: inline-block;
      vertical-align: top;
      position: relative;
      border-radius: 4px; }
      @media screen and (max-width: 1000px) {
        section.product-know ul.know-list > li {
          width: 100%;
          height: auto;
          min-height: auto;
          padding-bottom: 58px; } }
      @media screen and (max-width: 650px) {
        section.product-know ul.know-list > li {
          padding-bottom: 30px; } }
      section.product-know ul.know-list > li:nth-of-type(even) {
        margin-left: 30px; }
        @media screen and (max-width: 1000px) {
          section.product-know ul.know-list > li:nth-of-type(even) {
            margin-left: 0; } }
      section.product-know ul.know-list > li:before, section.product-know ul.know-list > li:after {
        content: "";
        position: absolute;
        overflow: hidden;
        pointer-events: none;
        background-repeat: no-repeat;
        background-position: 50% 0; }
        @media screen and (max-width: 480px) {
          section.product-know ul.know-list > li:before, section.product-know ul.know-list > li:after {
            background-size: 65% auto; } }
      section.product-know ul.know-list > li:before {
        z-index: 1; }
        @media screen and (max-width: 480px) {
          section.product-know ul.know-list > li:before {
            -webkit-transform: translate(56px, 10px);
            -ms-transform: translate(56px, 10px);
            transform: translate(56px, 10px); } }
      section.product-know ul.know-list > li:after {
        z-index: 0; }
        @media screen and (max-width: 480px) {
          section.product-know ul.know-list > li:after {
            -webkit-transform: translateX(50px);
            -ms-transform: translateX(50px);
            transform: translateX(50px); } }
      section.product-know ul.know-list > li h3 {
        max-width: 310px;
        padding: 65px 70px 0 70px;
        font-family: "droid_serifregular";
        font-size: 26px; }
        @media screen and (max-width: 650px) {
          section.product-know ul.know-list > li h3 {
            padding: 35px 40px 0 40px; } }
        @media screen and (max-width: 480px) {
          section.product-know ul.know-list > li h3 {
            max-width: 160px; } }
      section.product-know ul.know-list > li p {
        padding: 21px 70px 0;
        font-family: "basier_squareregular";
        font-size: 15px;
        line-height: 25px;
        color: #80A09B; }
        @media screen and (max-width: 650px) {
          section.product-know ul.know-list > li p {
            padding: 32px 40px 0; } }
      section.product-know ul.know-list > li > a {
        display: table;
        clear: both;
        padding: 10px 0;
        margin: 11px 0 0 70px;
        font-size: 12px;
        color: #127261;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        transition: all 0.2s ease-out; }
        section.product-know ul.know-list > li > a:hover {
          color: #18C2A5; }
        @media screen and (max-width: 650px) {
          section.product-know ul.know-list > li > a {
            margin: 11px 0 0 40px; } }
      section.product-know ul.know-list > li > ul {
        padding: 21px 70px 0; }
        @media screen and (max-width: 650px) {
          section.product-know ul.know-list > li > ul {
            padding: 32px 40px 0; } }
        section.product-know ul.know-list > li > ul > li {
          position: relative;
          padding: 0 0 4px 40px;
          font-family: "basier_squareregular";
          font-size: 15px;
          line-height: 25px;
          color: #80A09B; }
          section.product-know ul.know-list > li > ul > li:before {
            width: 20px;
            height: 2px;
            content: "";
            position: absolute;
            overflow: hidden;
            top: 12px;
            left: 0;
            background: #18C2A5; }

section.product-api {
  padding: 98px 0 100px; }
  section.product-api a {
    text-decoration: underline;
    color: #127261;
    transition: all 0.2s ease-out; }
    section.product-api a:hover {
      color: #18C2A5; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.product-api {
      padding: 40px 0 60px 0; } }
  @media screen and (max-width: 650px) {
    section.product-api {
      padding: 66px 0 70px 0; } }
  section.product-api .clmn {
    width: 50%;
    float: left; }
    @media screen and (max-width: 650px) {
      section.product-api .clmn {
        width: 100%; } }
    section.product-api .clmn article {
      position: relative; }
      section.product-api .clmn article .header-title {
        float: none; }
        section.product-api .clmn article .header-title h2 {
          font-size: 45px; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.product-api .clmn article .header-title h2 {
              font-size: 30px; } }
          @media screen and (max-width: 650px) {
            section.product-api .clmn article .header-title h2 {
              font-size: 30px; } }
      section.product-api .clmn article > ul {
        display: block; }
        section.product-api .clmn article > ul > li {
          display: block;
          padding-bottom: 52px; }
          section.product-api .clmn article > ul > li:nth-of-type(1) {
            margin-top: 41px; }
          section.product-api .clmn article > ul > li h3 {
            padding-top: 59px;
            font-size: 35px;
            line-height: 40px;
            border-top: 2px solid #18C2A5; }
            @media screen and (max-width: 820px) and (orientation: landscape) {
              section.product-api .clmn article > ul > li h3 {
                font-size: 26px;
                line-height: 31px; } }
            @media screen and (max-width: 650px) {
              section.product-api .clmn article > ul > li h3 {
                font-size: 26px;
                line-height: 31px; } }
          section.product-api .clmn article > ul > li p {
            padding-top: 29px;
            font-family: "basier_squareregular";
            font-size: 16px;
            color: #80A09B;
            line-height: 28px; }
            section.product-api .clmn article > ul > li p b,
            section.product-api .clmn article > ul > li p strong {
              color: #127261;
              font-family: "basier_squaresemibold"; }
            section.product-api .clmn article > ul > li p a {
              color: #127261;
              transition: all 0.2s ease-out; }
              section.product-api .clmn article > ul > li p a:hover {
                color: #18C2A5; }
          section.product-api .clmn article > ul > li > ul {
            padding: 32px 0 0; }
            @media screen and (max-width: 650px) {
              section.product-api .clmn article > ul > li > ul {
                padding: 32px 0 0; } }
            section.product-api .clmn article > ul > li > ul > li {
              position: relative;
              padding: 0 0 15px 40px;
              font-family: "basier_squareregular";
              font-size: 15px;
              line-height: 28px;
              color: #80A09B; }
              section.product-api .clmn article > ul > li > ul > li:last-child {
                padding-bottom: 0; }
              section.product-api .clmn article > ul > li > ul > li:before {
                width: 20px;
                height: 2px;
                content: "";
                position: absolute;
                overflow: hidden;
                top: 12px;
                left: 0;
                background: #18C2A5; }
      section.product-api .clmn article .lnk {
        display: block;
        margin-top: 43px;
        padding-top: 60px;
        border-top: 1px solid rgba(35, 120, 101, 0.2); }
        @media screen and (max-width: 1000px) {
          section.product-api .clmn article .lnk {
            margin-top: 0; } }
        section.product-api .clmn article .lnk > a {
          width: 100%;
          display: block;
          height: 70px;
          position: relative;
          background: #18C2A5;
          box-shadow: 0 2px 4px 0 rgba(18, 114, 97, 0.1);
          border-radius: 4px;
          font-size: 12px;
          letter-spacing: 1.6px;
          color: #FFF;
          text-transform: uppercase;
          transition: all 0.2s ease-out; }
          section.product-api .clmn article .lnk > a:before {
            width: 40px;
            height: 100%;
            content: "";
            position: absolute;
            overflow: hidden;
            top: 0;
            right: 35px;
            opacity: .7;
            transition: all 0.2s ease-out;
            background: url(../images/arrow.svg) no-repeat 0 50%; }
            html.no-svg section.product-api .clmn article .lnk > a:before {
              background: url(../images/arrow.png) no-repeat 0 50%; }
          section.product-api .clmn article .lnk > a:hover {
            background: #127261; }
            section.product-api .clmn article .lnk > a:hover:before {
              opacity: 1;
              -webkit-transform: translateX(5px);
              -ms-transform: translateX(5px);
              transform: translateX(5px); }
          section.product-api .clmn article .lnk > a > span {
            position: absolute;
            top: 50%;
            left: 35px;
            right: 35px;
            display: block;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
            section.product-api .clmn article .lnk > a > span > span {
              padding-right: 50px; }
    section.product-api .clmn.left article {
      padding-right: 85px; }
      @media screen and (max-width: 1000px) {
        section.product-api .clmn.left article {
          padding-right: 40px; } }
      @media screen and (max-width: 650px) {
        section.product-api .clmn.left article {
          padding: 0; } }
    section.product-api .clmn.right article {
      padding-left: 85px; }
      @media screen and (max-width: 1000px) {
        section.product-api .clmn.right article {
          padding-left: 40px; } }
      @media screen and (max-width: 650px) {
        section.product-api .clmn.right article {
          padding: 70px 0 0 0; } }

section.product-sub {
  padding: 57px 0 40px; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    section.product-sub {
      padding: 57px 0 30px; } }
  @media screen and (max-width: 650px) {
    section.product-sub {
      padding: 57px 0 30px; } }
  section.product-sub .wrap > h2 {
    font-size: 45px;
    display: none; }
    @media screen and (max-width: 1000px) {
      section.product-sub .wrap > h2 {
        display: block; } }
    @media screen and (max-width: 820px) and (orientation: landscape) {
      section.product-sub .wrap > h2 {
        font-size: 30px; } }
    @media screen and (max-width: 650px) {
      section.product-sub .wrap > h2 {
        font-size: 30px; } }
  section.product-sub .content-items {
    padding: 0 0 0 400px;
    position: relative; }
    @media screen and (max-width: 1200px) {
      section.product-sub .content-items {
        padding: 0 0 0 320px; } }
    @media screen and (max-width: 1000px) {
      section.product-sub .content-items {
        width: 100%;
        display: block;
        padding: 0; } }
    section.product-sub .content-items .content-text {
      width: 100%;
      float: right;
      position: relative;
      padding: 41px 0 0; }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        section.product-sub .content-items .content-text {
          padding-top: 10px; } }
      @media screen and (max-width: 650px) {
        section.product-sub .content-items .content-text {
          padding-top: 10px; } }
      section.product-sub .content-items .content-text .header-title {
        float: none; }
        @media screen and (max-width: 1000px) {
          section.product-sub .content-items .content-text .header-title {
            display: none; } }
        section.product-sub .content-items .content-text .header-title h2 {
          font-size: 45px; }
      section.product-sub .content-items .content-text > article {
        width: 100%;
        display: block;
        border-top: 2px solid #18C2A5;
        margin: 41px 0 45px;
        padding-top: 59px; }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          section.product-sub .content-items .content-text > article {
            margin: 35px 0 45px;
            padding-top: 39px; } }
        @media screen and (max-width: 650px) {
          section.product-sub .content-items .content-text > article {
            margin: 35px 0 45px;
            padding-top: 39px; } }
        section.product-sub .content-items .content-text > article .img-box {
          width: 300px;
          height: 250px;
          float: right;
          margin: 0 0 40px 45px;
          overflow: hidden;
          border-radius: 4px;
          background-color: #E0F1ED;
          background-repeat: no-repeat;
          background-position: 50% 0;
          background-cize: cover; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.product-sub .content-items .content-text > article .img-box {
              display: none; } }
          @media screen and (max-width: 650px) {
            section.product-sub .content-items .content-text > article .img-box {
              display: none; } }
          section.product-sub .content-items .content-text > article .img-box:nth-of-type(2) {
            display: none; }
            @media screen and (max-width: 820px) and (orientation: landscape) {
              section.product-sub .content-items .content-text > article .img-box:nth-of-type(2) {
                width: 100%;
                display: block;
                float: none;
                margin: 35px 0 0; } }
            @media screen and (max-width: 650px) {
              section.product-sub .content-items .content-text > article .img-box:nth-of-type(2) {
                width: 100%;
                display: block;
                float: none;
                margin: 35px 0 0; } }
        section.product-sub .content-items .content-text > article > h2, section.product-sub .content-items .content-text > article h3, section.product-sub .content-items .content-text > article h4, section.product-sub .content-items .content-text > article h5 {
          font-size: 35px;
          color: #127261; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.product-sub .content-items .content-text > article > h2, section.product-sub .content-items .content-text > article h3, section.product-sub .content-items .content-text > article h4, section.product-sub .content-items .content-text > article h5 {
              font-size: 25px; } }
          @media screen and (max-width: 650px) {
            section.product-sub .content-items .content-text > article > h2, section.product-sub .content-items .content-text > article h3, section.product-sub .content-items .content-text > article h4, section.product-sub .content-items .content-text > article h5 {
              font-size: 25px; } }
        section.product-sub .content-items .content-text > article p {
          padding-top: 28px;
          font-family: "basier_squareregular";
          font-size: 16px;
          color: #80A09B;
          line-height: 28px; }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            section.product-sub .content-items .content-text > article p {
              padding-top: 24px; } }
          @media screen and (max-width: 650px) {
            section.product-sub .content-items .content-text > article p {
              padding-top: 24px; } }
          section.product-sub .content-items .content-text > article p b,
          section.product-sub .content-items .content-text > article p strong {
            color: #127261;
            font-family: "basier_squaresemibold"; }
          section.product-sub .content-items .content-text > article p a {
            color: #127261;
            transition: all 0.2s ease-out; }
            section.product-sub .content-items .content-text > article p a:hover {
              color: #18C2A5; }
        section.product-sub .content-items .content-text > article a.lnk {
          margin-top: 19px;
          display: table;
          padding: 10px 0;
          font-size: 12px;
          color: #18C2A5;
          letter-spacing: 1.6px;
          transition: all 0.2s ease-out;
          text-transform: uppercase; }
          section.product-sub .content-items .content-text > article a.lnk:hover {
            color: #127261; }

input.hs-input.error,
.hs-form div.field.error input,
.hs-form div.field.error textarea,
.hs-form div.field.error .chzn-choices,
textarea.hs-input.error {
  border-color: #c87872;
  -webkit-box-shadow: 0 0 3px rgba(171, 41, 32, 0.25);
  -moz-box-shadow: 0 0 3px rgba(171, 41, 32, 0.25);
  box-shadow: 0 0 3px rgba(171, 41, 32, 0.25); }

input.hs-input.error:focus,
#hsformContainer div.field.error input:focus,
#hsformContainer div.field.error textarea:focus,
#hsformContainer div.field.error .chzn-choices:focus,
textarea.hs-input.error:focus {
  border-color: #b9554d;
  -webkit-box-shadow: 0 0 6px rgba(171, 41, 32, 0.5);
  -moz-box-shadow: 0 0 6px rgba(171, 41, 32, 0.5);
  box-shadow: 0 0 6px rgba(171, 41, 32, 0.5); }

#hsformContainer input[type="text"].hs-input, #hsformContainer input[type="number"].hs-input, #hsformContainer input[type="email"].hs-input, #hsformContainer textarea {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-top-color: #ced4da;
  border-right-color: #ced4da;
  border-bottom-color: #ced4da;
  border-left-color: #ced4da;
  border-radius: 0; }

#hsformContainer textarea {
  height: auto; }

div#hsformContainer {
  font-family: "basier_squareregular";
  color: #80A09B; }
  div#hsformContainer fieldset {
    margin-top: 0.5rem; }
  div#hsformContainer .hs-richtext {
    margin-bottom: 1rem;
    margin-top: 1rem; }
  div#hsformContainer .hs-error-msgs label {
    color: #cc0000; }
  div#hsformContainer p {
    padding: 10px 0; }
  div#hsformContainer .actions input.btn.btn-primary {
    margin-top: 2rem;
    width: 100%;
    display: block;
    height: 70px;
    position: relative;
    background: #18C2A5;
    box-shadow: 0 2px 4px 0 rgba(18, 114, 97, 0.1);
    border-radius: 4px;
    font-size: 12px;
    letter-spacing: 1.6px;
    color: #FFF;
    text-transform: uppercase;
    transition: all 0.2s ease-out; }

.text-content p, .text-content h4, .text-content h5, .text-content h6 {
  margin: 10px 0;
  font-family: "basier_squareregular";
  font-size: 16px;
  color: #80A09B;
  line-height: 28px; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    .text-content p, .text-content h4, .text-content h5, .text-content h6 {
      padding: 32px 0 0; } }
  @media screen and (max-width: 650px) {
    .text-content p, .text-content h4, .text-content h5, .text-content h6 {
      padding: 32px 0 0; } }
  .text-content p b,
  .text-content p strong, .text-content h4 b,
  .text-content h4 strong, .text-content h5 b,
  .text-content h5 strong, .text-content h6 b,
  .text-content h6 strong {
    color: #127261;
    font-family: "basier_squaresemibold"; }

.text-content ul p {
  padding: 0;
  margin: 0; }

.text-content h4, .text-content h5, .text-content h6 {
  font-weight: bold; }

.text-content h2 {
  font-size: 45px;
  line-height: 80px; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    .text-content h2 {
      font-size: 30px;
      line-height: 40px; } }
  @media screen and (max-width: 650px) {
    .text-content h2 {
      font-size: 30px;
      line-height: 40px; } }

.text-content h3 {
  font-size: 30px;
  line-height: 60px; }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    .text-content h3 {
      font-size: 20px;
      line-height: 30px; } }
  @media screen and (max-width: 650px) {
    .text-content h3 {
      font-size: 20px;
      line-height: 30px; } }

@media screen and (max-width: 1024px) {
  .grid .wrap {
    padding-left: 20px;
    padding-right: 20px; } }

.saphetor-com-nav-compare {
  color: #ffffff;
  text-transform: uppercase;
  padding: 20px; }

ul.sc-links {
  position: relative;
  font-size: 0;
  margin-top: 10px;
  padding: 0 0 0 5px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  @media screen and (max-width: 1000px) {
    ul.sc-links {
      padding: 0;
      text-align: left; } }
  @media screen and (max-width: 385px) {
    ul.sc-links {
      display: block; } }
  ul.sc-links:nth-of-type(1) {
    margin-top: 24px; }
    @media screen and (max-width: 385px) {
      ul.sc-links:nth-of-type(1) {
        margin-top: 30px; } }
  ul.sc-links li {
    width: 50%;
    width: calc(50% - 10px);
    margin-left: 10px;
    position: relative;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    border-radius: 4px;
    border: 1px solid rgba(35, 120, 101, 0.2);
    transition: all 0.2s ease-out;
    box-sizing: border-box;
    overflow: hidden; }
    @media screen and (max-width: 1000px) {
      ul.sc-links li {
        width: calc(50% - 5px);
        margin: 0; }
        ul.sc-links li:nth-of-type(1) {
          margin-right: 5px; }
        ul.sc-links li:nth-of-type(2) {
          margin-left: 5px; } }
    @media screen and (max-width: 385px) {
      ul.sc-links li {
        width: 100%;
        margin: 0;
        display: block; }
        ul.sc-links li:nth-of-type(1) {
          margin-right: 0; }
        ul.sc-links li:nth-of-type(2) {
          margin-top: 10px;
          margin-left: 0; } }
    ul.sc-links li:before, ul.sc-links li:after {
      width: 20px;
      height: 20px;
      content: "";
      position: absolute;
      overflow: hidden;
      top: 50%;
      left: 22px;
      margin-top: -10px;
      background-repeat: no-repeat;
      background-position: 50%;
      transition: all 0.3s ease-out;
      pointer-events: none; }
    ul.sc-links li:before {
      z-index: 1;
      background-image: url(../images/icon-btn-static.svg); }
    ul.sc-links li:after {
      z-index: 2;
      opacity: 0;
      background-image: url(../images/icon-btn-hover.svg); }
    ul.sc-links li:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); }
      ul.sc-links li:hover:before {
        opacity: 0; }
      ul.sc-links li:hover:after {
        opacity: 1; }
    ul.sc-links li a {
      width: 100%;
      height: 100%;
      display: table;
      vertical-align: top;
      position: relative;
      text-transform: uppercase;
      font-size: 12px;
      color: #127261;
      font-family: "basier_squaremedium";
      font-size: 12px;
      text-decoration: none;
      letter-spacing: 0.4px;
      line-height: 16px;
      transition: all 0.3s ease-out; }
      ul.sc-links li a > span {
        display: table-cell;
        vertical-align: middle;
        position: relative;
        padding: 21px 20px 21px 55px;
        z-index: 3; }
