section.hp-products {
	padding: 60px 0 60px 0;

	@media #{$tablet} {
		padding: 80px 0 80px;
	}

	@media #{$mobilland} {
		padding: 40px 0 40px 0;
	}

	@media #{$mobil2} {
		padding: 66px 0 64px 0;
	}

	.header-title {
		text-align: center;

		h2 {
			font-size: 45px;
			line-height: 80px;

			@media #{$mobilland} {
				font-size: 30px;
				line-height: 40px;
			}

			@media #{$mobil2} {
				font-size: 30px;
				line-height: 40px;
	        }
		}
	}

	.grid {
		max-width: 1200px;
		padding: 0px;
	}


	.box-container.hp-boxes {
		margin: 42px 0 0 0;

		@media #{$mobilland} {
			margin: 0 0 0;
		}

		@media #{$mobil2} {
			margin: 15px 0 0;
		}

	    >.item {
	    	height: auto;
	        display: inline-block;
	        vertical-align: top;
	        position: relative;
	        margin: 20px 15px;

	        @media #{$tablet2} {
	        	width: 100%;
	        	margin: 40px 0 0;
	        	display: block;
	        }

	        @media #{$mobilland} {
				height: auto;
			}

			@media #{$mobil2} {
				height: auto;
			}

	        &:nth-of-type(1) {

	        	@media #{$mobilland} {
					margin: 20px 0 0;
				}

	        	>a {

	        		.cnt {

	        			.img {

	        				img {
	        					margin: 27px 0 0 0px;
	        				}
	        			}
	        		}
	        	}
	        }

	        &:nth-of-type(2) {

	        	>a {

	        		@include hover {
	        			& {
	        				.cnt {

			        			.img, .title {
			        				@include transform(translateY(-25px));
			        			}
			        		}
	        			}
	        		}

	        		.cnt {

	        			.img {

	        				img {
	        					margin: 9px 0 0 18px;
	        				}
	        			}
	        		}
	        	}
	        }

	        &:nth-of-type(3) {

	        	>a {

	        		@include hover {
	        			& {
	        				.cnt {

			        			.img, .title {
			        				@include transform(translateY(-25px));
			        			}
			        		}
	        			}
	        		}

	        		.cnt {

	        			.img {

	        				img {
	        					margin: 16px 0 0 5px;
	        				}
	        			}
	        		}
	        	}
	        }

	        >a {
				background: rgba(32,213,183,0.07);
				border-radius: 4px;
				display: block;
				transition: all 0.26s ease-out;

				@include hover {
					background: $white;
					box-shadow: 0 40px 70px 0 rgba(0,0,0,0.10);

					>span {
						@include transform(translateY(0));
						opacity: 1;

						html.no-touch & {
							opacity: 1;
						}
					}
				}

				@media #{$mobilland} {
					position: relative;
					display: block;
				}

				@media #{$mobil2} {
					position: relative;
					display: block;
				}

				.cnt {
					position: relative;
					padding: 50px;

					@media #{$mobilland} {
						padding: 30px 30px 50px;
					}

					@media #{$mobil2} {
						padding: 30px 30px 50px;
					}

					>.img {
						width: 100%;
						height: 220px;
						display: block;
						text-align: center;
						font-size: 0;
						transition: all 0.26s ease-out;

						@media #{$mobilland} {
							height: 211px;
						}

						@media #{$mobil2} {
							height: 211px;
						}

						img {
							max-width: 100%;
							display: inline-block;
							vertical-align: top;
						}
					}

					>.title {
						width: 100%;
						display: block;
						margin: 9px 0 0 0;
						transition: all 0.26s ease-out;

						@media #{$mobilland} {
							padding-bottom: 42px;
						}

						@media #{$mobil2} {
							padding-bottom: 42px;
						}

						h3 {
							font-size: 26px;
						}

						p {
							margin: 16px 0 0 0;
							font-family: $basereg;
							font-size: 15px;
							color: $grey;
							line-height: 25px;

                            @media #{$mobilland} {
								margin: 24px 0 0;
							}

							@media #{$mobil2} {
								margin: 24px 0 0;
							}
						}
					}
				}

				>span {
					width: 100px;
					height: 30px;
					line-height: 30px;
					position: absolute;
					bottom: 10px;
			        left: 50%;
			        margin: 0 0 0 -53px;
			        z-index: 3;
			        font-family: $base;
			        font-size: 12px;
			        color: $green;
			        letter-spacing: 1.6px;
			        transition: color 0.2s ease-out, opacity 0.25s ease-out, transform 0.25s ease-out;
			        text-transform: uppercase;
			        @include transform(translateY(10px));

			        html.no-touch & {
			        	opacity: 0;
			        }

			        @media #{$mobilland} {
						bottom: 50px;
					}

					@media #{$mobil2} {
						bottom: 50px;
					}

			        @include hover {
			        	color: $darkgreen;
					}
				}
	        }
	    }
	}

	.key-benefits {
		@extend .section;
		padding: 0px;

		@media #{$mobilland} {
			padding: 58px 0 0 0;
		}

		@media #{$mobil2} {
			padding: 58px 0 0 0;
		}

		.stc-con {
			padding-bottom: 93px;
			position: absolute;
			top: 50px;
			left: 15px;

			html.mac & {
				top: 141px;
				padding-bottom: 82px;

				@media #{$desktop3} {
					top: 0;
					padding-bottom: 0;
				}
			}

			@media #{$desktop3} {
				width: 100%;
				float: left;
				position: relative;
				top: 0;
				left: 0;
				padding: 0;
			}
		}

		.header-title {
			text-align: left;

			@media #{$desktop3} {
				text-align: center;
			}

			h2 {
				font-size: 70px;
				line-height: 75px;

				@media #{$mobilland} {
					font-size: 50px;
					line-height: 60px;
				}

				@media #{$mobil2} {
					font-size: 50px;
					line-height: 60px;
		        }
			}
		}

		>.columns {
			position: relative;
			padding: 42px 0 0 400px;
			font-size: 0;
			z-index: 6;

			@media #{$desktop6} {
				padding: 42px 0 0 340px;
			}

			@media #{$desktop3} {
				width: 100%;
				float: left;
				padding: 70px 0 0;
			}

			@media #{$tablet} {
				padding: 0;
			}

			@media #{$mobilland} {
			}

			>.clmn {
				width: 50%;
				display: inline-block;
		        vertical-align: top;
		        position: relative;

		        @media #{$tablet} {
		        	width: 100%;
		        }

		        .item {
		        	width: 100%;
		        	height: 210px;
		        	display: block;
		        	position: relative;
		        	background-repeat: no-repeat;
			        background-position: 15px 0;
			        background-image: url(../images/icon1.png);

			        @media #{$tablet} {
			        	height: auto;
			        	margin-top: 60px;
			        	transform: translateX(0) scale(1)!important;
			        }

			        @media #{$mobilland} {

			        }

			        @media #{$mobil} {
			        	margin-top: 50px;
						background-position: 50% 0;
						text-align: center;
					}

			        &:not(:nth-of-type(1)) {
			        }

			        html.no-svg & {
			        	background-image: url(../images/icon1.png);
			        }

			        >img {
			        	position: absolute;
			        	top: 17px;
					    left: 31px;
			        	pointer-events: none;

			        	@media #{$mobil} {
			        		left: 50%;
			        		@include transform(translateX(-50%));
			        	}
			        }

		        	>h3 {
		        		padding: 18px 0 0 105px;
						font-family: $small;
						font-size: 22px;
						color: $brown;

						@media #{$tablet} {
							padding-top: 0;
						}

						@media #{$mobil} {
							padding: 90px 0 0 0;
						}
		        	}

		        	>p {
		        		padding: 13px 20px 0 105px;
						font-size: 14px;
						color: $grey;
						line-height: 24px;

						@media #{$mobilland} {
							font-size: 15px;
							line-height: 24px;
						}

						@media #{$mobil} {
							padding: 13px 0 0;
							font-size: 15px;
							line-height: 24px;
						}
		        	}
		        }
			}
		}
	}
}