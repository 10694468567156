section.product-api {
    padding: 98px 0 100px;
	a {
		text-decoration: underline;
		color: $darkgreen;
		transition: all 0.2s ease-out;

		@include hover {
			color: $green;
		}
	}
    @media #{$mobilland} {
		padding: 40px 0 60px 0;
	}

	@media #{$mobil2} {
		padding: 66px 0 70px 0;
	}

	.clmn {
		width: 50%;
		float: left;

		@media #{$mobil2} {
			width: 100%;
		}

		article {
			position: relative;

			.header-title {
				float: none;

				h2 {
					font-size: 45px;

					@media #{$mobilland} {
						font-size: 30px;
					}

					@media #{$mobil2} {
						font-size: 30px;
			        }
				}
			}

			>ul {
				display: block;

				>li {
					display: block;
					padding-bottom: 52px;

					&:nth-of-type(1) {
						margin-top: 41px;
					}

					h3 {
					    padding-top: 59px;
						font-size: 35px;
						line-height: 40px;
						border-top: 2px solid #18C2A5;

						@media #{$mobilland} {
							font-size: 26px;
							line-height: 31px;
						}

						@media #{$mobil2} {
							font-size: 26px;
							line-height: 31px;
				        }
					}

					p {
					    padding-top: 29px;
						font-family: $basereg;
						font-size: 16px;
						color: $grey;
						line-height: 28px;

						b,
						strong {
							color: $darkgreen;
							font-family: $semi;
						}

						a {
							color: $darkgreen;
							transition: all 0.2s ease-out;

							@include hover {
								color: $green;
							}
						}
					}

					>ul {
					    padding: 32px 0 0;

					    @media #{$mobil2} {
					    	padding: 32px 0 0;
					    }

						>li {
							position: relative;
							padding: 0 0 15px 40px;
							font-family: $basereg;
							font-size: 15px;
							line-height: 28px;
							color: $grey;

							&:last-child {
								padding-bottom: 0;
							}

							&:before {
								width: 20px;
								height: 2px;
								@include pseudo;
								top: 12px;
								left: 0;
								background: $green;
							}
						}
					}
				}
			}

			.lnk {
				display: block;
				margin-top: 43px;
				padding-top: 60px;
				border-top: 1px solid rgba(#237865, 0.2);

				@media #{$tablet2} {
					margin-top: 0;
				}

				>a {
					width: 100%;
				    display: block;
				    height: 70px;
				    position: relative;
					background: $green;
					box-shadow: 0 2px 4px 0 rgba(18,114,97,0.10);
					border-radius: 4px;
					font-size: 12px;
					letter-spacing: 1.6px;
					color: $white;
					text-transform: uppercase;
					transition: all 0.2s ease-out;

					&:before {
						width: 40px;
						height: 100%;
						@include pseudo;
						top: 0;
						right: 35px;
						opacity: .7;
						transition: all 0.2s ease-out;
						background: url(../images/arrow.svg) no-repeat 0 50%;

						html.no-svg & {
							background: url(../images/arrow.png) no-repeat 0 50%;
						}
					}

					@include hover {
						background: $darkgreen;

						&:before {
							opacity: 1;
							@include transform(translateX(5px));
						}
					}

					>span {
						position: absolute;
					    top: 50%;
					    left: 35px;
					    right: 35px;
					    display: block;
					    @include transform(translateY(-50%));

						>span {
							padding-right: 50px;
						}
					}
				}
			}
		}

		&.left {

			article {
				padding-right: 85px;

				@media #{$tablet2} {
					padding-right: 40px;
				}

				@media #{$mobil2} {
					padding: 0;
				}
			}
		}

		&.right {

			article {
				padding-left: 85px;

				@media #{$tablet2} {
					padding-left: 40px;
				}

				@media #{$mobil2} {
					padding: 70px 0 0 0;
				}
			}
		}
	}
}