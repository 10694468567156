section.product-columns {
	padding: 80px 0 0;
	a {
		text-decoration: underline;
	}
	@media #{$mobilland} {
		padding: 40px 0 50px 0;
	}

	@media #{$mobil2} {
		padding: 66px 0 60px 0;
	}

	.header-title {

		@media #{$tablet2} {
			text-align: center;
		}

		h2 {
			font-size: 45px;

			@media #{$mobilland} {
				font-size: 30px;
				line-height: 40px;
			}

			@media #{$mobil2} {
				font-size: 30px;
				line-height: 40px;
	        }
		}
	}

	.wrap {
		counter-reset: my-badass-counter;
	}

	.columns {
		@extend .section;
		font-size: 0;

		@media #{$tablet2} {
			margin-top: 0;
		}

		@media #{$mobilland} {
			margin-top: 0;
		}

		&:nth-of-type(2) {

			@media #{$tablet2} {
				margin-top: 0;
			}
		}

		>.clmn {
			width: 50%;
			display: inline-block;
			vertical-align: top;

			@media #{$tablet2} {
				width: 100%;
				text-align: center;
			}

			&.left {
				padding-top: 10px;

				@media #{$tablet2} {
					padding-top: 0;
				}

				img {
					max-width: 100%;
					display: inline-block;
					vertical-align: top;
					position: relative;
				}
			}

			&.right {
				padding-top: 57px;

				@media #{$tablet2} {
					padding-top: 0;
				}

                >article {
					position: relative;
					padding: 11px 0 0 15px;

					@media #{$tablet2} {
						padding: 11px 0 0 0;
					}

					h3 {
					    padding-top: 15px;
						padding-bottom: 15px;
						font-size: 35px;
						line-height: 70px;
						border-bottom: 2px solid #18C2A5;

						@media #{$mobilland} {
							font-size: 26px;
							line-height: 31px;
						}

						@media #{$mobil2} {
							font-size: 26px;
							line-height: 31px;
				        }

						&:before {
							padding: 0 15px 0 0;
							content: counter(my-badass-counter) "";
							counter-increment: my-badass-counter;
							font-family: $title;
							font-size: 70px;
							color: $darkgreen;

							@media #{$tablet2} {
								padding: 0;
							}
						}
					}

					p {
					    padding-top: 28px;
						font-family: $basereg;
						font-size: 16px;
						color: $grey;
						line-height: 28px;

						b,
						strong {
							color: $darkgreen;
							font-family: $semi;
						}

						a {
							color: $darkgreen;
							transition: all 0.2s ease-out;

							@include hover {
								color: $green;
							}
						}
					}
				}
			}
		}
	}
}