section.product-know {
	padding: 99px 0 100px;
	background: #EFFCFA;

	@media #{$mobilland} {
		margin-top: 0;
		padding: 40px 0 60px 0;
	}

	@media #{$mobil2} {
		margin-top: 0;
		padding: 66px 0 70px 0;
	}

	& ~ section.intr-solutions {
		border-top: 0;
	}

	.header-title {
		text-align: center;

		h2 {
			font-size: 45px;
		}
	}

	ul.know-list {
		@extend .section;
		font-size: 0;
		margin-top: 52px;

		@media #{$mobilland} {
			margin-top: 25px;
		}

		@media #{$mobil2} {
			margin-top: 25px;
		}

		>li {
			width: 50%;
			width: calc(50% - 15px);
			min-height: 240px;
			margin-top: 30px;
			padding-bottom: 50px;
			display: inline-block;
			vertical-align: top;
			position: relative;
			border-radius: 4px;

			@media #{$tablet2} {
				width: 100%;
				height: auto;
				min-height: auto;
				padding-bottom: 58px;
			}

			@media #{$mobil2} {
				padding-bottom: 30px;
			}

			&:nth-of-type(even) {
				margin-left: 30px;

				@media #{$tablet2} {
					margin-left: 0;
				}
			}


			&:before,
			&:after {
				@include pseudo;
				pointer-events: none;
				background-repeat: no-repeat;
				background-position: 50% 0;

				@media #{$mobil} {
					background-size: 65% auto;
				}
			}

			&:before {
				z-index: 1;

				@media #{$mobil} {
					@include transform(translate(56px, 10px));
				}
			}

			&:after {
				z-index: 0;

				@media #{$mobil} {
					@include transform(translateX(50px));
				}
			}

			h3 {
				max-width: 310px;
				padding: 65px 70px 0 70px;
				font-family: $small;
				font-size: 26px;

				@media #{$mobil2} {
					padding: 35px 40px 0 40px;
				}

				@media #{$mobil} {
					max-width: 160px;
				}
			}

			p {
			    padding: 21px 70px 0;
				font-family: $basereg;
				font-size: 15px;
				line-height: 25px;
				color: $grey;

				@media #{$mobil2} {
					padding: 32px 40px 0;
				}
			}

			>a {
				display: table;
				clear: both;
				padding: 10px 0;
				margin: 11px 0 0 70px;
				font-size: 12px;
                color: $darkgreen;
                letter-spacing: 1.6px;
                text-transform: uppercase;
                transition: all 0.2s ease-out;

                @include hover {
                	color: $green;
                }

                @media #{$mobil2} {
					margin: 11px 0 0 40px;
				}
			}

			>ul {
			    padding: 21px 70px 0;

			    @media #{$mobil2} {
			    	padding: 32px 40px 0;
			    }

				>li {
					position: relative;
					padding: 0 0 4px 40px;
					font-family: $basereg;
					font-size: 15px;
					line-height: 25px;
					color: $grey;

					&:before {
						width: 20px;
						height: 2px;
						@include pseudo;
						top: 12px;
						left: 0;
						background: $green;
					}
				}
			}
		}
	}
}