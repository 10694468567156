.about-lightbox {
	@include absolute;
	@include fixed;
	z-index: 101;
	display: table;
	background: rgba($black, 0.2);
	opacity: 0;
	visibility: hidden;
	-webkit-animation: zoom 0.3s ease-out forwards;
	animation: zoom 0.3s ease-out forwards;

	@media #{$mobilland} {
		display: block;
	}

	@media #{$mobil2} {
		display: block;
	}

	@-webkit-keyframes zoom {
		0% {
			opacity: 0;
			visibility: hidden;
		}

		100% {
			opacity: 1;
			visibility: visible;
		}
	}

	@keyframes zoom {
		0% {
			opacity: 0;
			visibility: hidden;
		}

		100% {
			opacity: 1;
			visibility: visible;
		}
	}

	&.fadeout {
		animation: zooms 0.3s ease-out forwards;

		@keyframes zooms {
		

		0% {
			opacity: 1;
			visibility: visible;
		}

		100% {
			opacity: 0;
			visibility: hidden;
		}
	}

	}

	>.grid {
		width: 100%;
		display: table-cell;
		vertical-align: middle;
		position: relative;
		text-align: left;

		@media #{$mobilland} {
			height: 100%;
			display: block;
			float: left;
			background: $white;
			padding: 0;
		}

		@media #{$mobil2} {
			height: 100%;
			display: block;
			float: left;
			background: $white;
			padding: 0;
		}

		.about-box-cnt {
			max-width: 700px;
			margin: 0 auto;
			position: relative;
			background: $white;
			border-radius: 4px;

			@media #{$mobilland} {
				width: 100%;
				height: 100%;
				-webkit-overflow-scrolling: touch;
	            z-index: 11;
	            position: relative;
	            overflow: hidden;
	            overflow-y: scroll;
	            border-radius: 0;
	            &::-webkit-scrollbar { display: none; }
			}

			@media #{$mobil2} {
				width: 100%;
				height: 100%;
				-webkit-overflow-scrolling: touch;
	            z-index: 11;
	            position: relative;
	            overflow: hidden;
	            overflow-y: scroll;
	            border-radius: 0;
	            &::-webkit-scrollbar { display: none; }
			}

			>button.close-about-modal {
				width: 50px;
				height: 50px;
				position: absolute;
				top: 25px;
				right: 25px;
				z-index: 12;
				transition: all 0.24s ease-out;

				@media #{$mobilland} {
					@include fixed;
				}

				@media #{$mobil2} {
					@include fixed;
				}

				@include hover() {

					&:before,
			        &:after {
			        	background: $darkgreen;
					}
		        }

		        &:before,
		        &:after {
		        	width: 26px;
		        	height: 2px;
		        	@include pseudo;
		        	top: 50%;
		        	left: 50%;
		        	transition: all 0.24s ease-out;
		        	background: $green;
				}

				&:before {
					@include transform(translate(-50%, -50%) rotate(-45deg));
				}

				&:after {
					@include transform(translate(-50%, -50%) rotate(45deg));
				}
			}

			.about-modal-header {
				width: 100%;
				display: block;
			    position: relative;
		        padding-bottom: 60px;

			    >h5 {
					font-size: 35px;
					padding: 62px 60px 0;
			    }

			    >span {
			    	padding: 0 60px;
					opacity: 0.5;
					font-size: 12px;
					color: $darkgreen;
					letter-spacing: 1.6px;
					text-transform: uppercase;
			    }

			    >p {
		    	    margin-top: 44px;
	    	        padding: 41px 60px 0;
					font-family: $basereg;
					font-size: 16px;
					color: $grey;
					line-height: 28px;
					border-top: 1px solid rgba(#237865, 0.2);
			    }

			    >a {
			    	height: 24px;
			    	display: table;
			    	padding: 0 0 0 34px;
			    	margin: 35px 0 0 60px;
					font-size: 12px;
					color: $green;
					letter-spacing: 1.6px;
					line-height: 24px;
			    	text-transform: uppercase;
			    	background-repeat: no-repeat;
			    	background-position: 0 50%;
			    	background-image: url(../images/icon-linkedin.svg);
			    	transition: all 0.2s ease-out;

			    	@include hover() {
			    		color: $darkgreen;
			    		background-image: url(../images/icon-linkedin_hover.svg);
			    	}

			    	html.no-svg & {
			    		background-image: url(../images/icon-linkedin.png);
			    	}
			    }
			}
		}
	}
}