aside#header-navigation {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transition: all .2s ease-in;
    background: rgba(255,255,255,0.00);
    border-bottom: 1px solid rgba(35,120,101,0.20);
    &::-webkit-scrollbar { display: none; }

    &.fixed-nav {
        background: $white;
        border-bottom: 1px solid rgba(35,120,101,0);
        box-shadow: 0 40px 70px 0 rgba(0,0,0,0.06);
        
        >.site-container {
            padding: 0;

            @media #{$desktop3} {
                padding: 0 0 15px;
            }

            @media #{$tablet2} {
                padding: 15px 0 15px;
            }

            #logo {
                width: 129px;
                height: 30px;
                top: 0;
                margin: 25px 0 0 30px;

                @media #{$tablet2} {
                    width: 160px;
                    height: 50px;
                    margin: 0 0 0 40px;
                }

                a {
                    background-image: url(../images/logo-saphetor-small.svg);

                    @media #{$tablet2} {
                        background-image: url(../images/logo-saphetor.svg);
                    }

                    html.no-svg & {
                        background-image: url(../images/logo-saphetor-small.png);

                        @media #{$tablet2} {
                            background-image: url(../images/logo-saphetor.png);
                        }
                    }
                }
            }

            .slogan {
                height: 80px;

                >span {
                }
            }

            nav {
                float: right;

                .nav-container {

                    .nav-content {
                    }
                }

                ul.general-nav {
                    margin: 33px 57px 0 0;

                    @media #{$tablet2} {
                        margin: 148px 0 0 40px;
                    }

                    @media #{$mobilland} {
                        margin: 90px 0 0 40px;
                    }

                    >li {

                        &:not(:nth-last-of-type(1)) {
                            margin: 0 60px 0 0;

                            @media #{$desktop6} {
                                margin: 0 45px 0 0;
                            }

                            @media #{$tablet2} {
                                margin: 0;
                            }
                        }

                        &:not(:nth-of-type(1)) {

                            @media #{$tablet2} {
                                margin-top: 28px;
                            }
                        }

                        &:nth-of-type(1) {

                            >a {

                                span {

                                    &:before {
                                        top: 60px;
                                    }
                                }
                            }
                        }

                        >ul.sub-nav {
                            top: 60px;

                            @media #{$tablet2} {
                                top: 0;
                            }

                            >li {
                                margin-top: 42px;

                                @media #{$tablet2} {
                                    margin-top: 0;
                                }
                            }
                        }

                        >a {
                            font-size: 14px;

                            @media #{$tablet2} {
                                font-size: 22px;
                            }
                        }
                    }
                }

                .client-login {
                    margin: 15px 15px 0 0;

                    @media #{$tablet2} {
                        margin: 51px 0 0 0;
                    }
                }
            }
        }

        button#btn-open-nav {

            @media #{$tablet2} {
                top: 16px;
            }
        }
    }

    &.navigation-opened {

        nav {
            background: rgba(0, 0, 0, 0.2);
            pointer-events: inherit;
            opacity: 1;
            visibility: visible;

            .nav-container {
                opacity: 1;
                @include transform(translateX(0));

                .nav-contact-socials {
                    opacity: 1;
                }
            }
        }
    }

    button#btn-open-nav {
        width: 50px;
        height: 50px;
        @include fixed;
        top: 35px;
        right: 33px;
        z-index: 100;
        display: none;
        transition: all .2s ease-in;

        @media #{$tablet2} {
            display: block;
        }

        @media #{$mobilland} {
            display: block;
            top: 25px;
        }

        @media #{$mobil2} {
            display: block;
            top: 25px;
        }

        &.active {

            &:before {
                margin-top: 0;
                @include transform(translate(-50%, -50%) rotate(45deg));
            }

            &:after {
                margin-top: 0;
                @include transform(translate(-50%, -50%) rotate(-45deg));
            }
        }

        &:before,
        &:after {
            width: 35px;
            height: 2px;
            @include pseudo;
            top: 50%;
            left: 50%;
            background: $green;
            @include transform(translate(-50%, -50%) rotate(0));
            transition: all .2s ease-in;
        }

        &:before {
            margin-top: -7px;
        }

        &:after {
            margin-top: 7px;
        }
    }

    >.site-container {
        @extend .section;
        padding: 35px 0 34px;
        transition: all .2s ease-in;

        @media #{$mobilland} {
            padding: 25px 0 24px;
        }

        @media #{$mobil2} {
            padding: 25px 0 24px;
        }

        #logo {
            width: 160px;
            height: 50px;
            float: left;
            position: relative;
            overflow: hidden;
            top: -1px;
            margin: 0 0 0 50px;
            transition: all 0.2s ease-out;

            @media #{$desktop} {
                margin: 0 0 0 50px;
            }

            @media #{$tablet2} {
                margin: 0 0 0 40px;
            }

            >a {
                @include absolute;
                background-repeat: no-repeat;
                background-position: 0 50%;
                background-image: url(../images/logo-saphetor.svg);
                transition: all 0.2s ease-out;

                html.no-svg & {
                    background-image: url(../images/logo-saphetor.png);
                }

                >span,
                img {
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: -999px;
                    left: -999px;
                    opacity: 0;
                    font-size: 0;
                }
            }
        }

        .slogan {
            height: 50px;
            float: left;
            margin: 0 0 0 40px;
            padding-left: 40px;
            display: table;
            position: relative;
            border-left: 1px solid rgba(#237865, 0.2);
            transition: all 0.2s ease-out;

            @media #{$desktop3} {
                display: none;
            }

            >span {
                display: table-cell;
                vertical-align: middle;
                opacity: 0.7;
                font-size: 10px;
                color: #127261;
                letter-spacing: 1.33px;
                line-height: 16px;
                text-transform: uppercase;
                transition: all 0.2s ease-out;
                @include noselect;
            }
        }
    }

    nav {
        float: right;

        @media #{$tablet2} {
            width: 100%;
            height: 100%;
            @include fixed;
            top: 0;
            left: 0;
            bottom: 0;
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
            transition: all .2s ease-in;
            z-index: 10;
        }

        .nav-container {
            width: 100%;
            z-index: 10;
            pointer-events: inherit;
            &::-webkit-scrollbar { display: none; }
            transition: opacity 0.14s ease-out, transform 0.3s ease-out;

            @media #{$tablet2} {
                max-width: 280px;
                height: 100%;
                background: $white;
                float: right;
                opacity: 0;
                @include transform(translateX(100%));
            }

            .nav-content {
                width: 100%;
                height: 100%;
                margin: 0 0 0 auto;
                -webkit-overflow-scrolling: touch;
                z-index: 20;           
                &::-webkit-scrollbar { display: none; }

                @media #{$tablet2} {
                    position: relative;
                    overflow: hidden;
                    overflow-y: scroll;
                }
            }
        }

        ul.general-nav {
            float: left;
            font-size: 0;
            margin: 16px 80px 0 0;
            transition: all 0.2s ease-out;

            @media #{$desktop6} {
                margin: 16px 50px 0 0;
            }

            @media #{$tablet2} {
                margin: 148px 0 0 40px;
            }

            @media #{$mobilland} {
                margin: 90px 0 0 40px;
            }

            >li {
                display: inline-block;
                vertical-align: top;

                @media #{$tablet2} {
                    width: 100%;
                }

                &:not(:nth-last-of-type(1)) {
                    margin: 0 75px 0 0;
                    transition: all 0.2s ease-out;

                    @media #{$desktop6} {
                        margin: 0 45px 0 0;
                    }

                    @media #{$tablet2} {
                        margin: 0;
                    }
                }

                &:not(:nth-of-type(1)) {

                    @media #{$tablet2} {
                        margin-top: 28px;
                    }
                }

                &.has-submenu {

                    >a {

                        span {

                            &.hovered:before {
                                border-bottom: 15px solid #f9fefd;
                            }

                            &:before {
                                width: 0; 
                                height: 0; 
                                @include pseudo;
                                top: 80px;
                                left: 50%;
                                display: none;
                                @include transform(translateX(-50%));
                                border-left: 15px solid transparent;
                                border-right: 15px solid transparent;
                                border-bottom: 15px solid $white;
                                pointer-events: none;
                                z-index: -1;
                                transition: all 0.22s ease-out;
                                opacity: 0;
                            }
                        }
                    }
                }

                &.active {

                    >a {
                        color: $darkgreen;
                    }
                }

                @include hover {


                    &.has-submenu {

                        >a {

                            span {

                                &:before {
                                    -webkit-animation: opacitynav .22s linear forwards;
                                    animation: opacitynav .22s linear forwards;
                                    display: block;
                                }
                            }
                        }
                    }

                    > a {
                        color: $darkgreen;
                    }

                    > ul.sub-nav {
                        display: block;
                        -webkit-animation: opacitynav .22s linear forwards;
                        animation: opacitynav .22s linear forwards;

                    }
                }

                >a {
                    position: relative;
                    padding: 10px 0;
                    font-size: 24px;
                    font-family: $small;
                    font-size: 16px;
                    color: $brown;
                    transition: all 0.2s ease-out;

                    @media #{$tablet2} {
                        font-size: 22px;
                    }

                    @media #{$mobil2}{
                    }

                    >span {
                        position: relative;
                        display: inline-block;
                        vertical-align: top;
                    }
                }

                >ul.sub-nav {
                    width: 1170px;
                    display: none;
                    position: absolute;
                    top: 79px;
                    left: 50%;
                    @include transform(translateX(-50%));
                    z-index: 10;
                    opacity: 0;
                    transition: all 0.2s ease-out;

                    @media #{$tablet2} {
                        display: block;
                        opacity: 1;
                        position: relative;
                        top: 0;
                        left: 0;
                        @include transform(translateX(0));
                    }

                    >li {
                        width: 100%;
                        display: block;
                        font-size: 0;
                        margin-top: 62px;
                        position: relative;
                        background: #FFFFFF;
                        box-shadow: 0 40px 70px 0 rgba(0,0,0,0.10);
                        border-radius: 4px;
                        transition: all 0.2s ease-out;

                        @media #{$tablet2} {
                            box-shadow: none;
                            margin-top: 0;
                            padding: 21px 0 0;
                        }

                        >a {
                            width: 33.3333%;
                            width: calc(100% / 3);
                            display: inline-block;
                            vertical-align: top;
                            position: relative;
                            box-sizing: border-box;
                            transition: all 0.2s ease-out;

                            @media #{$tablet2} {
                                width: 100%;
                                padding: 8px 0;
                            }

                            &:nth-of-type(2) {
                                border-left: 1px solid rgba(#237865, 0.2);
                                border-right: 1px solid rgba(#237865, 0.2);

                                @media #{$tablet2} {
                                    border: 0;
                                }
                            }

                            @include hover {
                                background: rgba(#EFFCFA, 0.4);

                                >.text {

                                    p {

                                        >span {
                                            color: $darkgreen;
                                        }
                                    }
                                }

                                >.btn-more{

                                    &:before {
                                        height: 2px;
                                        background: $green;
                                    }

                                    >span {
                                        color: $darkgreen;
                                    }
                                }
                            }

                            >.text {
                                width: 100%;
                                display: block;
                                position: relative;

                                @media #{$tablet2} {
                                    height: auto;
                                }

                                >span {
                                    display: block;
                                    margin-bottom: 20px;
                                    padding: 45px 50px 0 50px;
                                    font-family: $small;
                                    font-size: 16px;
                                    color: $brown;
                                    transition: all 0.2s ease-out;

                                    @media #{$tablet2} {
                                        margin: 0;
                                        padding: 0;
                                    }
                                }

                                >p {
                                    min-height: 180px;
                                    padding: 0 50px 20px 50px;
                                    font-size: 14px;
                                    color: $grey;
                                    font-family: $basereg;
                                    line-height: 22px;

                                    @media #{$tablet2} {
                                        display: none;
                                    }
                                }
                            }

                            >.btn-more {
                                width: 100%;
                                height: 80px;
                                display: table;
                                position: relative;

                                @media #{$tablet2} {
                                    display: none;
                                }

                                &:before {
                                    width: 100%; 
                                    height: 1px; 
                                    @include pseudo;
                                    top: 0;
                                    left: 0;
                                    background: rgba(#237865, 0.2);
                                    transition: all 0.2s ease-out;
                                }

                                >span {
                                    display: table-cell;
                                    vertical-align: middle;
                                    padding: 0 0 0 50px;
                                    font-size: 12px;
                                    color: $green;
                                    letter-spacing: 1.6px;
                                    transition: all 0.2s ease-out;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }

                @-webkit-keyframes opacitynav {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }

                @keyframes opacitynav {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
            }
        }

        .client-login {
            float: left;
            margin: 0 40px 0 0;
            transition: all 0.2s ease-out;

            @media #{$tablet2} {
                width: 100%;
                margin: 51px 0 0 0;
                padding: 24px 0 80px;
                border-top: 1px solid rgba(35,120,101,0.20);
            }

            >a {
                width: 140px;
                height: 50px;
                display: block;
                box-sizing: border-box;
                border: 1px solid rgba(#237865, 0.2);
                border-radius: 4px;
                text-align: center;
                line-height: 48px;
                font-size: 12px;
                color: $white;
                opacity: 1;
                background: $green;
                border: 1px solid $green;
                letter-spacing: 1.6px;
                text-transform: uppercase;
                transition: all 0.2s ease-out;

                @include hover {
                    color: $white;
                    background: $darkgreen;
                    border: 1px solid $green;
                }

                @media #{$tablet2} {
                    margin-left: 40px;
                }
            }
        }
    }
}
   