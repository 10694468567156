#root {
    width: 100%;
    overflow: hidden;
    float: left;
    position: relative;
}

// aside.screen {
//     @include absolute;
//     top: 0;
//     left: 0;
//     pointer-events: none;
//     background-repeat: no-repeat;
//     background-position: 50% 0;
//     background-image: url(../work/screens/varsome.jpg);
//     z-index: 1000;
//     opacity: 0.323;
//     display: none;

//     &.x {

//         display: block;
//     }
// }

button#screen {
    width: 40px;
    height: 40px;
    @include fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}

.section {
	width: 100%;
	position: relative;
	float: left;
    z-index: 5;
}

.grid {
	max-width: 1170px;
	position: relative;
	margin: 0 auto;

	.wrap {
        width: 100%;
        float: left;
		position: relative;
	}
}

.header-title {
    @extend .section;

    >span {
        display: block;
    }

    h1,h2,h3, h4 {
        display: inline-block;
        vertical-align: top;
        position: relative;
        font-family: $title;
        color: $darkgreen;
    }
}

.box-container {
    @extend .section;
    text-align: center;
    font-size: 0;

    >.item {
        display: inline-block;
        vertical-align: top;
        position: relative;
    }
}

.link-cnt {
    @extend .section;
    font-size: 0;
    overflow: hidden;

    &:before {
        width: 100%;
        height: 2px;
        @include pseudo;
        top: 50%;
        left: 120px;
        margin-top: -1px;
        background: $green;
        pointer-events: none;
        z-index: -1;
    }

    >a {
        width: auto;
        display: inline-block;
        vertical-align: top;
        position: relative;
        padding: 10px;
        left: -10px;
        font-family: $base;
        font-size: 12px;
        color: $darkgreen;
        letter-spacing: 1.6px;
        transition: all 0.2s ease-out;
        text-transform: uppercase;

        >span {
            color: $darkgreen;
            display: inline-block;
            vertical-align: top;
            transition: all 0.3s ease-out;
        }

        @include hover {

            >span {
                color: $green;
                @include transform(translateX(10px));
            }
        }
    }
}
