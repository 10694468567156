footer {
	@extend .section;
	padding: 97px 0 97px;
	background-color: $darkgreen;

	@media #{$tablet} {
		padding: 80px 0 80px;
	}

	@media #{$mobilland} {
        padding: 40px 0 40px;
    }

	@media #{$mobil2} {
		padding: 40px 0 40px;
	}

	a.ft-logo {
		top: 0;
		left: 0;

	}

	address {
		margin-top: 34px;
		opacity: 0.5;
		font-family: $basereg;
		font-style: normal;
		font-size: 15px;
		line-height: 24px;
		color: $white;

	}

	.socials {
		// width: 100%;
		font-size: 0;
		text-align: left;
		left: 0;
		z-index: 10;


		>a {
			width: 40px;
			height: 40px;
			display: inline-block;
			vertical-align: top;
			position: relative;
			left: -8px;
			border-radius: 50%;
			background-repeat: no-repeat;
			background-position: 50%;

			@media #{$tablet1} {
				left: 0;
				margin: 0 2px;
			}

			&:not(:nth-of-type(1)) {
				margin-left: 4px;

				@media #{$tablet1} {
					margin-left: 2px;
				}
			}









			>img {
				width: 24px;
				position: absolute;
				top: 50%;
				left: 50%;
				@include transform(translate(-50%, -50%));
			}

			@include hover {

				svg path {
					fill: $white;
				}
			}
		}
	}

	.ft-container {
		position: relative;
		z-index: 2;

		.items {
			@extend .section;
			font-size: 0;

			@media #{$desktop1} {
				padding-bottom: 0;
			}

			@media #{$tablet1} {
				text-align: center;
			}

			>ul {
				display: inline-block;
				vertical-align: top;
				margin-top: 5px;
				text-align: left;

				@media #{$tablet1} {
					display: none;
				}


				>li {
					display: block;
					margin: 16px 0;
					font-size: 14px;

					&:nth-of-type(1) {
						margin: 0;
						margin-bottom: 33px;

			            >span {
			            	text-transform: uppercase;
							font-size: 12px;
				            color: $white;
				            letter-spacing: 1.6px;
							opacity: 0.5;
			            }
					}

					a {
						padding: 10px 0;
						font-family: $basereg;
						font-size: 15px;
						color: $lightgreen;
						transition: all 0.24s ease-out;

						@include hover {
							color: $white;
						}
					}
				}
			}

			.form-newsletter {
				display: inline-block;
				vertical-align: top;
				text-align: left;

				@media #{$tablet1} {
					width: 100%;
					text-align: center;
				}

				>span {
					width: 100%;
					display: block;
					@include noselect;

		            &.title {
		            	text-transform: uppercase;
						font-size: 12px;
			            color: $green;
			            letter-spacing: 1.6px;
			            margin-top: 7px;
		            }

		            &.spam {
		            	margin-top: 26px;
		            	text-align: right;
						opacity: 0.5;
						font-family: $basereg;
						font-size: 14px;
						color: $white;

						@media #{$tablet1} {
							text-align: center;
						}
		            }
				}

				form {
					margin-top: 37px;
					text-align: left;

					@media #{$tablet1} {
						display: inline-block;
						vertical-align: top;
					}

					@media #{$mobil} {
						width: 100%;
					}

					label.item {
						width: 210px;
						height: 50px;
						display: inline-block;
						vertical-align: top;
						position: relative;
						cursor: text;
						box-sizing: border-box;
						border: 1px solid rgba(190,220,215,0.30);
						transition: all 0.24s ease-out;
						border-right: none;
						overflow: hidden;
						@include border-radius(4px 0 0 4px);

						@include hover {
							border: 1px solid rgba(190,220,215,0.50);
							border-right: none;
							
							>span {
								color: $white;
							}
						}

						@media #{$mobil} {
							width: 100%;
							width: calc(100% - 107px);
						}

						&.active,
						&.valid {
							border: 1px solid rgba(190,220,215,0.50);
							border-right: none;
							
							>span {
								color: rgba($white, 0);
							}
						}

						&.invalid {
							border: 1px solid $red;
							border-right: none;

							>span {
								color: $red;
							}
						}

						>span {
							position: absolute;
							top: 50%;
							left: 20px;
							pointer-events: none;
							font-family: $basereg;
							font-size: 15px;
							color: rgba($white, 0.5);
							margin-top: -1px;
							transition: all 0.24s ease-out;
							@include transform(translateY(-50%));
						}

						>input {
							width: 70%;
							width: calc(100% - 40px);
							height: 49px;
							position: absolute;
						    top: 0;
						    left: 20px;
						    margin-top: -1px;
						    border: 0;
						    font-size: 15px;
						    font-family: $basereg;
							color: $white;
							background: transparent;
							overflow: hidden;
						}
					}

					.send-form {
						width: 107px;
						height: 50px;
						float: right;
						position: relative;
						
						>button {
							@include absolute;
							background: $green;
							color: $white;
							font-family: $base;
							font-size: 11px;
							letter-spacing: 2.51px;
							transition: all 0.24s ease-out;
							text-transform: uppercase;
							@include border-radius(0 4px 4px 0);

							@include hover {
								color: $darkgreen;
								background-color: $white;
							}
						}
					}
				}
			}
		}
	}

	.project {
		@extend .section;
		padding: 57px 0 0;
		margin: 52px 0 0;
		font-family: $basereg;
		font-size: 15px;
		border-top: 1px solid rgba(255,255,255,0.15);

		@media #{$tablet1} {
			text-align: center;
			margin: 34px 0 0;
			padding: 37px 0 0;
		}

		span.copyright {
			color: $white;

			@media #{$tablet1} {
				display: block;
			}
		}

		a.author {
			float: right;
			position: relative;
			top: -10px;
			padding: 10px 0;
			color: $green;
			font-size: 15px;
			color: $lightgreen;
			transition: all 0.24s ease-out;

			@media #{$tablet1} {
				float: none;
				top: 0;
				display: inline-block;
				vertical-align: top;
				margin-top: 12px;
			}

			@media #{$mobil} {
				max-width: 182px;
				margin-top: 10px;
				line-height: 25px;
			}

			@include hover {
				color: $white;
			}
		}

		button#scroll-top {
			width: 60px;
			height: 40px;
			display: block;
			margin: 15px auto 0;
			clear: both;
			background-repeat: no-repeat;
			background-position: 50%;
			background-image: url(../images/icon-top.svg);

			html.no-svg & {
				background-image: url(../images/icon-top.png);
			}
		}
	}
}