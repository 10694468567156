/******************************************************************
    reset css
*/

html,
body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    font-size-adjust: none;
    -moz-font-size-adjust: none;
    -webkit-font-size-adjust: none;

    @media #{$mobilland} {
        height: auto;
    }

    @media #{$mobil7} {
        height: auto;
    }
}
html {
    font-family: $helvetica, sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
body {
    font-family: $base, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: $black;
    margin: 0;
    padding: 0;
    cursor: default;
    overflow-x: hidden;
    background-color: $white;
}

* {
    margin: 0;
    padding: 0;
}

table,
p {
    padding: 0;
    margin: 0;
}

p {

    a {

    }
}

strong, b {
    font-weight: normal;
}

embed {
    padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // font-family: $regular, $helvetica, sans-serif;
    font-weight: normal;
    font-family: $title;
    @include noselect;
    color: $darkgreen;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
    font-size-adjust: none;
    -moz-font-size-adjust: none;
    -webkit-font-size-adjust: none;
}

a,
a:hover,
button,
button:hover {
    cursor: pointer;
    outline: none;
    text-decoration: none;
    @include noselect;
}

a img {
    border: none;
}

button {
    width: auto;
    vertical-align: top;
    overflow: visible;
    outline: no-repeat;
    cursor: pointer;
    @include border-radius(0);
    border: 0;
    outline: 0;
    background: transparent;
}

a:link,
section.articleWrapper aside:link {
    -webkit-tap-highlight-color: rgba(1, 1, 1, 0.563);
}

label,
img {
    display: block;
    border: none;
    outline: none;
    @include noselect;
}

textarea {
    overflow: auto;
    resize: none;
    outline: no-repeat
}



ol,
ul,
li {
    list-style: none;
    outline: none;
}

form,
dl,
dd,
dt,
ul,
ol,
li {
    padding: 0;
    margin: 0;
}

canvas {
    overflow: hidden;
}

acronym {
    border: none;
    cursor: help;
}

iframe {
    overflow: hidden;
}

::selection {
    background: $green;
    color: $white;
}

::-moz-selection {
    background: $green;
    color: $white;
}

::-webkit-selection {
    background: $green;
    color: $white;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
    display: block;
}

html input[type="button"],
input[type="reset"] {
    -webkit-appearance: button;
    cursor: pointer;
    border-radius: 0;
    width: auto;
    border: 0;
    outline: 0;
    background: transparent;
    border-radius: 0;
    cursor: pointer;
    @include noselect;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
@-ms-viewport {
    width: device-width;
}