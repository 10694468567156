@font-face {
    font-family: 'ClarendonLTStd-Light';
    src: url('../fonts/clarendonltstd-light.woff2') format('woff2'),
         url('../fonts/clarendonltstd-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'droid_serifregular';
    src: url('../fonts/droidserif-webfont.woff2') format('woff2'),
         url('../fonts/droidserif-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'basier_squaremedium';
    src: url('../fonts/basiersquare-medium-webfont.woff2') format('woff2'),
         url('../fonts/basiersquare-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'basier_squareregular';
    src: url('../fonts/basiersquare-regular-webfont.woff2') format('woff2'),
         url('../fonts/basiersquare-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'basier_squaresemibold';
    src: url('../fonts/basiersquare-semibold-webfont.woff2') format('woff2'),
         url('../fonts/basiersquare-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
