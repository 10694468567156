/* colors */
$grey: #80A09B;
$green: #18C2A5;
$lightgreen: #20D5B7;
$darkgreen: #127261;
$lightblue: #E0F1ED;
$brown: #444444;
$white: #FFF;
$black: #000;
$red: #ff4646;

/* fonts */
$helvetica: 'Helvetica';
$base: 'basier_squaremedium';
$basereg: 'basier_squareregular';
$title: 'ClarendonLTStd-Light';
$small: 'droid_serifregular';
$semi: 'basier_squaresemibold';

/* responsive width */
$full1: "screen and (max-width: 2000px)";
$full: "screen and (max-width: 1800px)";
$desktop5: "screen and (max-width: 1600px)";
$desktop6: "screen and (max-width: 1380px)";
$desktop4: "screen and (max-width: 1300px)";
$desktop7: "screen and (max-width: 1290px)";
$desktop3: "screen and (max-width: 1200px)";
$desktop2: "screen and (max-width: 1170px)";
$desktop1: "screen and (max-width: 1100px)";
$desktop: "screen and (max-width: 1200px)";
$tablet5: "screen and (max-width: 1030px)";
$tablet4: "screen and (max-width: 1024px)";
$tablet3: "screen and (max-width: 1080px)";
$tablet2: "screen and (max-width: 1000px)";
$tablet1: "screen and (max-width: 900px)";
$tablet: "screen and (max-width: 770px)";
$mobilland: "screen and (max-width: 820px) and (orientation:landscape)";
$mobil2land: "screen and (max-width: 750px) and (orientation:landscape)";
$mobil3: "screen and (max-width: 800px)";
$mobil2: "screen and (max-width: 650px)";
$mobil1: "screen and (max-width: 600px)";
$mobil: "screen and (max-width: 480px)";
$mobil6: "screen and (max-width: 385px)";
$mobil5: "screen and (max-width: 370px)";
$mobil7: "screen and (max-width: 350px)";

//only width
$width1: "screen and (orientation:landscape) and (max-height: 680px)";
/* min */
$min1: "screen and (min-width: 1000px)";
$min5: "screen and (min-width: 10px)";
$min2: "screen and (min-width: 1024px)";
$header1: "screen and (min-width: 600px)";
// landscape
$landscape: "screen and (orientation:landscape)";
$portrait: "screen and (orientation:portrait)";
//height
$height: "screen and (max-height: 630px) and (max-width: 1000px)";

$hg1: "screen and (orientation:portrait) and (max-height: 710px)";
//ipad pro
$ipad: "only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)";
$ipadpor: "only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5)";
$ipadlan: "only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)";

$retina: "only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx)";

$retina-min: "only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1000px),
only screen and (   min--moz-device-pixel-ratio: 2) and (min-width: 1000px),
only screen and (     -o-min-device-pixel-ratio: 2/1) and (min-width: 1000px),
only screen and (        min-device-pixel-ratio: 2) and (min-width: 1000px),
only screen and (                min-resolution: 192dpi) and (min-width: 1000px),
only screen and (                min-resolution: 2dppx) and (min-width: 1000px)";


/* padding */
$desktopPadding: 60px;
$tabletPadding: 40px;
$mobilPadding: 0;


/* hover */



@mixin blur($px: 0) {
    -webkit-filter: blur(#{$px}px);
    filter: blur(#{$px}px);
}


/* fixed */
@mixin fixed {
    position: fixed;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
/* absolute */
@mixin absolute {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
}



/* pseudo */
@mixin pseudo {
    content: "";
    position: absolute;
    overflow: hidden;
}
/* backface */
@mixin backface {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

@mixin bugs {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    perspective: 1000;
}

/* radius */

@mixin border-radius($radius) {
    border-radius: $radius;
}
// @include border-radius(top left, top right, bottom right, bottom left);

/* select */
@mixin noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@mixin noselectyes {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    user-select: auto;
}

/* transform */

@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}

/* gradient */
@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
}

@mixin linear-gradient($args...) {
    background-image: -o-linear-gradient($args); 
    background-image: -moz-linear-gradient($args); 
    background-image: -webkit-linear-gradient($args); 
    background-image: linear-gradient($args); 
}

@mixin horizontalGradient($left, $right){
    background: $left; /* Old browsers */
    background: -moz-linear-gradient(right,  $left 0%, $right 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left right, left right, color-stop(0%,$left), color-stop(100%,$right)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(right,  $left 0%,$right 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(right,  $left 0%,$right 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(right,  $left 0%,$right 100%); /* IE10+ */
    background: linear-gradient(right left,  $left 0%,$right 100%); /* W3C */
}

