.hg-box-item {
	position: absolute;
    top: 72px;
    right: 70px;
    z-index: 10;

    @media #{$desktop3} {
    	right: 0;
    }

	@media #{$tablet2} {
		float: left;
		position: relative;
		top: 0;
		right: 0;
		margin-top: 42px;
	}

	@media #{$mobil2} {
		padding-bottom: 20px;
	}

	>a {
		width: 100%;
		display: block;
		float: left;

		@include hover {

			>.img-video {

				&:before {
					background-color: $green;

					background-image: url(../images/arrow-white.svg);

					html.no-svg & {
						background-image: url(../images/arrow-white.png);
					}
				}

				.clmn {
					box-shadow: 0 10px 30px 0 rgba(27,63,57,0.20);
				}
			}

			>.title-video {

				p {
					color: $brown;
				}
			}
		}

		>.img-video {
			width: 170px;
			height: 110px;
			display: block;
			position: relative;

			@media #{$tablet2} {
				float: left;
			}

			@media #{$mobilland} {
				width: 136px;
				height: 88px;
		    }

		    @media #{$mobil2} {
		    	width: 136px;
		    	height: 88px;
		    }

			&:before {
				width: 50px;
				height: 50px;
				@include pseudo;
				right: 20px;
				bottom: -25px;
				z-index: 2;
				border-radius: 50%;
				transition: all 0.2s ease-out;
				background-color: $white;
				background-repeat: no-repeat;
				background-position: 53% center;
				background-image: url(../images/arrow-green.svg);

				@media #{$mobilland} {
					width: 40px;
					height: 40px;
					right: 15px;
					bottom: -20px;
					background-size: 8px auto;
			    }

			    @media #{$mobil2} {
			    	width: 40px;
			    	height: 40px;
			    	right: 15px;
					bottom: -20px;
					background-size: 8px auto;
			    }

				html.no-svg & {
					background-image: url(../images/arrow-green.png);
				}
			}

			.clmn {
				width: 100%;
				height: 110px;
				display: block;
				position: relative;
				overflow: hidden;
				border-radius: 4px;
				font-size: 0;
				background: $white;
				box-shadow: 0 30px 70px 0 rgba(27,63,57,0.40);
				transition: all 0.2s ease-out;

				@media #{$mobilland} {
					height: 88px;
					text-align: center;
			    }

			    @media #{$mobil2} {
			    	height: 88px;
			    	text-align: center;
			    }

				>img {
					width: 100%;
					max-width: 100%;
					border-radius: 4px;
					display: inline-block;
					vertical-align: top;

					@media #{$mobilland} {
						max-width: auto;
						width: auto;
						height: 100%;
				    }

				    @media #{$mobil2} {
				    	max-width: auto;
				    	width: auto;
				    	height: 100%;
				    }
				}
			}
		}

		>.title-video {
			width: 200px;
			display: block;
			position: relative;
			margin: 46px 0 0 0;

			@media #{$tablet2} {
				float: left;
				margin: 0 0 0 30px;
			}

			@media #{$mobilland} {
				width: 220px;
		    }

		    @media #{$mobil2} {
		    	width: 122px;
		    }

			>span {
				display: block;
				margin: 17px 0 0 0;
				opacity: 0.5;
				font-size: 12px;
				color: $darkgreen;
				letter-spacing: 1.6px;
				transition: all 0.2s ease-out;
				text-transform: uppercase;

				@media #{$mobilland} {
					font-size: 11px;
					letter-spacing: 1.47px;
					margin: 3px 0 0;
			    }

			    @media #{$mobil2} {
			    	font-size: 11px;
					letter-spacing: 1.47px;
					margin: 3px 0 0;
			    }
			}

			>p {
				max-height: 48px;
				margin: 18px 0 0 0;
				font-family: $small;
				font-size: 16px;
				color: $darkgreen;
				line-height: 24px;
				transition: all 0.2s ease-out;
				overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                @media #{$mobilland} {
                	max-width: 100%;
                	max-height: 62px;
                	margin: 12px 0 0;
					font-size: 15px;
					line-height: 20px;
					-webkit-line-clamp: 3;
			    }

			    @media #{$mobil2} {
			    	max-width: 130px;
			    	max-height: 62px;
			    	margin: 12px 0 0;
			    	font-size: 15px;
					line-height: 20px;
					-webkit-line-clamp: 2;
			    }
			}
		}
	}
}