section.product-sub {
	padding: 57px 0 40px;

	@media #{$mobilland} {
		padding: 57px 0 30px;
	}

	@media #{$mobil2} {
		padding: 57px 0 30px;
	}

	.wrap {

		>h2 {
			font-size: 45px;
			display: none;

			@media #{$tablet2}{
				display: block;
			}

			@media #{$mobilland} {
				font-size: 30px;
			}

			@media #{$mobil2} {
				font-size: 30px;
	        }
		}
	}

	.content-items {
		padding: 0 0 0 400px;
	    position: relative;

	    @media #{$desktop3} {
	    	padding: 0 0 0 320px;
	    }

	    @media #{$tablet2} {
	    	width: 100%;
	    	display: block;
		    padding: 0;
	    }

	    .content-text {
			width: 100%;
			float: right;
			position: relative;
			padding: 41px 0 0;

			@media #{$mobilland} {
				padding-top: 10px;
			}

			@media #{$mobil2} {
				padding-top: 10px;
			}

			.header-title {
				float: none;

				@media #{$tablet2}{
					display: none;
				}

				h2 {
					font-size: 45px;
				}
			}

			>article {
				width: 100%;
				display: block;
				border-top: 2px solid $green;
				margin: 41px 0 45px;
			    padding-top: 59px;

			    @media #{$mobilland} {
			    	margin: 35px 0 45px;
				    padding-top: 39px;
			    }

			    @media #{$mobil2} {
			    	margin: 35px 0 45px;
				    padding-top: 39px;
			    }

			    .img-box {
			    	width: 300px;
			    	height: 250px;
			    	float: right;
			    	margin: 0 0 40px 45px;
			    	overflow: hidden;
			    	border-radius: 4px;
			    	background-color: $lightblue;
			    	background-repeat: no-repeat;
			    	background-position: 50% 0;
			    	background-cize: cover;

			    	@media #{$mobilland} {
			    		display: none;
			    	}

			    	@media #{$mobil2} {
			    		display: none;
			    	}

			    	&:nth-of-type(2) {
				    	display: none;

				    	@media #{$mobilland} {
				    		width: 100%;
				    		display: block;
				    		float: none;
				    		margin: 35px 0 0;
				    	}

				    	@media #{$mobil2} {
				    		width: 100%;
				    		display: block;
				    		float: none;
				    		margin: 35px 0 0;
				    	}
				    }
			    }

				>h2,h3,h4,h5 {
					font-size: 35px;
					color: #127261;

					@media #{$mobilland} {
						font-size: 25px;
					}

					@media #{$mobil2} {
						font-size: 25px;
					}
				}

				p {
				    padding-top: 28px;
					font-family: $basereg;
					font-size: 16px;
					color: $grey;
					line-height: 28px;

					@media #{$mobilland} {
						padding-top: 24px;
					}

					@media #{$mobil2} {
						padding-top: 24px;
					}

					b,
					strong {
						color: $darkgreen;
						font-family: $semi;
					}

					a {
						color: $darkgreen;
						transition: all 0.2s ease-out;

						@include hover {
							color: $green;
						}
					}
				}

				a.lnk {
					margin-top: 19px;
					display: table;
					padding: 10px 0;
					font-size: 12px;
					color: $green;
					letter-spacing: 1.6px;
					transition: all 0.2s ease-out;
					text-transform: uppercase;

					@include hover {
						color: $darkgreen;
					}
				}
			}
	    }
	}
}



  

        