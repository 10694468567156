section.about-timeline {
  padding: 131px 0 147px;
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid rgba(#237865, 0.2);
  border-bottom: 1px solid rgba(#237865, 0.2);
  z-index: 6;

  @media #{$desktop2} {
    padding: 90px 0 30px;
  }

  @media #{$mobilland} {
    padding: 50px 0;
  }

  @media #{$mobil2} {
    padding: 50px 0;
  }

  & ~ section#partners {
    padding: 100px 0 94px;

    @media #{$tablet1} {
      padding: 100px 0 84px;
    }

    @media #{$mobilland} {
      padding: 65px 0 50px;
    }

    @media #{$mobil2} {
      padding: 65px 0 50px;
    }
  }

  .grid {
    max-width: 1500px;
    padding: 0;

    @media #{$tablet2} {
      padding: 0;
    }

    @media #{$mobil7} {
      padding: 0;
    }
  }

  .wrap {

    &:before,
    &:after {
      width: 200px;
      height: 100%;
      @include pseudo;
      top: 0;
      -webkit-background-image: linear-gradient(-90deg, rgba($white, 0) 0%, $white 100%);
      -webkit-background-image: -webkit-linear-gradient(-90deg, rgba($white, 0) 0%, $white 100%);
      -moz-background-image: linear-gradient(-90deg, rgba($white, 0) 0%, $white 100%);
      background-image: linear-gradient(-90deg, rgba($white, 0) 0%, $white 100%);
      pointer-events: none;
      z-index: 50;

      @media #{$mobilland} {
        width: 80px;
      }

      @media #{$mobil} {
        width: 80px;
      }
    }

    &:before {
      left: 0;
      -webkit-background-image: linear-gradient(-90deg, rgba($white, 0) 0%, $white 100%);
      -webkit-background-image: -webkit-linear-gradient(-90deg, rgba($white, 0) 0%, $white 100%);
      -moz-background-image: linear-gradient(-90deg, rgba($white, 0) 0%, $white 100%);
      background-image: linear-gradient(-90deg, rgba($white, 0) 0%, $white 100%);
    }

    &:after {
      right: 0;
      -webkit-background-image: linear-gradient(90deg, rgba($white, 0) 0%, $white 100%);
      -webkit-background-image: -webkit-linear-gradient(90deg, rgba($white, 0) 0%, $white 100%);
      -moz-background-image: linear-gradient(90deg, rgba($white, 0) 0%, $white 100%);
      background-image: linear-gradient(90deg, rgba($white, 0) 0%, $white 100%);
    }
  }

  [data-fluffy-container] {
    width: 100%;
    display: block;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    &::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      display: none !important;
    }
  }

  [data-fluffy-container].is-ready {
    background-color: transparent;
  }


  [data-fluffy-container].is-ready [data-fluffy-content],
  [data-fluffy-container].is-moving [data-fluffy-scrollbars] {
    opacity: 1
  }

  [data-fluffy-scrollbars] {
    display: none;
  }

  [data-fluffy-scrollbars] .is-horizontal,
  [data-fluffy-scrollbars] .is-vertical {
    display: none;
  }

  [data-fluffy-content] {
    height: 100%;
    position: relative;
    list-style-type: none;
    margin: 0;

    &:before {
      width: 100%;
      height: 2px;
      @include pseudo;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: $green;
      pointer-events: none;
    }

    &::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      display: none !important;
    }
  }

  .end-item {
    width: 180px;
    display: inline-block;
    vertical-align: top;

    @media #{$mobilland} {
      width: 90px;
    }

    @media #{$mobil} {
      width: 90px;
    }
  }

  .slide-item-btn {
    width: 230px;
    height: 270px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: left;
    box-sizing: content-box;

    @media #{$mobilland} {
      height: 200px;
    }

    @media #{$mobil} {
      height: 200px;
    }

    html.no-touch &.hovered {

      &:after {
        background: $green;
      }
    }

    &:before {
      width: 1px;
      height: 100px;
      @include pseudo;
      right: 0;
      opacity: 0.2;
      background: #237865;
      z-index: 0;
      pointer-events: none;

      @media #{$mobilland} {
        height: 65px;
      }

      @media #{$mobil} {
        height: 65px;
      }
    }

    &:after {
      width: 13px;
      height: 13px;
      @include pseudo;
      right: -8px;
      background: $white;
      border: 2px solid #18C2A5;
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.20);
      transition: all 0.2s ease-out;
    }

    &:nth-of-type(1) {
      width: 364px;

      @media #{$mobilland} {
        width: 230px;
      }

      @media #{$mobil} {
        width: 230px;
      }
    }

    &:nth-of-type(odd) {

      button {
        top: -8px;

        @media #{$mobilland} {
          top: 0;
        }

        @media #{$mobil} {
          top: 0;
        }

        html.touch & {
          top: 1px;

          @media #{$mobilland} {
            top: 8px;
          }

          @media #{$mobil} {
            top: 8px;
          }
        }

        html.mac & {
          top: 1px;

          @media #{$mobilland} {
            top: 8px;
          }

          @media #{$mobil} {
            top: 8px;
          }
        }
      }

      &:before {
        top: 34px;
      }

      &:after {
        top: 17px;
      }
    }

    &:nth-of-type(even) {

      button {
        bottom: -10px;

        @media #{$mobilland} {
          bottom: 0;
        }

        @media #{$mobil} {
          bottom: 0;
        }

        html.touch & {
          bottom: -20px;

          @media #{$mobilland} {
            bottom: -8px;
          }

          @media #{$mobil} {
            bottom: -8px;
          }
        }

        html.mac & {
          bottom: -20px;

          @media #{$mobilland} {
            bottom: -8px;
          }

          @media #{$mobil} {
            bottom: -8px;
          }
        }
      }

      &:before {
        bottom: 34px;
      }

      &:after {
        bottom: 17px;
      }
    }

    > button {
      width: 100%;
      position: absolute;
      right: -10px;
      font-family: $title;
      font-size: 70px;
      line-height: 1;
      text-align: right;
      color: $darkgreen;
      transition: all 0.2s ease-out;
      z-index: 10;

      @media #{$mobilland} {
        font-size: 50px;
      }

      @media #{$mobil} {
        font-size: 50px;
      }

      > span {
        position: relative;
        left: -40px;
      }

      @include hover() {

        &:before {
          background-color: $white;
        }
      }
    }
  }
}