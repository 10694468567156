section.hp-about {
	padding: 165px 0 150px;
	background: #EFFCFA;
	overflow: hidden;

	@media #{$desktop1} {
		text-align: center;
		padding: 165px 0 0;
	}

	@media #{$tablet} {
		padding: 100px 0 0;
	}

	@media #{$mobilland} {
		padding: 64px 0 0;
	}

	@media #{$mobil2} {
		padding: 64px 0 0;
	}

	.header-title {
		display: inline-block;
		position: relative;

		h2 {
			font-size: 35px;
			line-height: 40px;

			@media #{$mobil2} {
				font-size: 25px;
				line-height: 35px;
	        }
		}

		p {
			margin: 28px 0 0 0;
			font-family: $basereg;
			font-size: 16px;
			color: $grey;
			line-height: 28px;

			@media #{$mobil2} {
				margin: 31px 0 0 0;
			}

			@media #{$mobilland} {
				margin: 31px 0 0 0;
			}
		}

		.link-cnt {
			margin: 39px 0 0 0;

			@media #{$mobil2} {
				margin: 27px 0 0 0;
			}

			@media #{$mobilland} {
				margin: 27px 0 0 0;
			}

			&:before {
				left: 150px;

				@media #{$mobil2} {
					left: 137px;
				}

				@media #{$mobilland} {
					left: 137px;
				}
			}
		}
	}

	img {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -50px;
		pointer-events: none;

		@media #{$desktop3} {
			margin-left: -120px;
		}

		@media #{$desktop1} {
			max-width: 120%;
			position: relative;
			left: -10%;
			display: inline-block;
			vertical-align: top;
			margin: 30px auto 0;
		}
	}
}