.navigation-content {
	width: 300px;
    float: left;
    position: relative;
    z-index: 10;
    padding-bottom: 57px;
    position: absolute;
    top: 0;
    left: 0;

    @media #{$desktop3} {
    	width: 270px;
    }

    @media #{$tablet2} {
    	width: 100%;
    	margin: 30px 0 0 0;
    	position: relative;
    	padding-bottom: 0;
    }

    >span.sub-title {
    	width: 100%;
    	display: block;
		opacity: 0.5;
		font-size: 12px;
		color: $darkgreen;
		letter-spacing: 1.6px;
		text-transform: uppercase;
		@include noselect;

		@media #{$tablet2} {
			display: none;
		}
    }

    >button {
    	width: 100%;
    	height: 50px;
    	display: none;
    	position: relative;
		background: $green;
		box-shadow: 0 2px 4px 0 rgba(18,114,97,0.10);
		border-radius: 4px;
		text-transform: uppercase;
		text-align: left;
		font-family: $base;

    	@media #{$tablet2} {
    		display: block;
    	}

    	&.active {

    		&:before {
	    		@include transform(rotate(-45deg) scaleY(1.5));
	    	}

	    	&:after {
	    		@include transform(rotate(45deg) scaleY(1.5));
	    	}
    	}

    	&:before,
    	&:after {
		    width: 10px;
		    height: 1px;
		    @include pseudo;
		    top: 23px;
		    right: 30px;
		    background: $white;
    		transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1),transform-origin 0.4s cubic-bezier(0.86, 0, 0.07, 1),-webkit-transform 0.4s cubic-bezier(0.86, 0, 0.07, 1),-webkit-transform-origin 0.4s cubic-bezier(0.86, 0, 0.07, 1),-ms-transform-origin 0.4s cubic-bezier(0.86, 0, 0.07, 1);
    	}

    	&:before {
    		right: 37px;
    		@include transform(rotate(45deg) scaleY(1.5));
    	}

    	&:after {
    		@include transform(rotate(-45deg) scaleY(1.5));
    	}

    	>span {
    		padding: 0 30px;
			font-size: 12px;
			color: $white;
			letter-spacing: 1.6px;
    	}
    }

    >ul.sub-nav {
    	width: 100%;
    	display: block;
    	margin-top: 29px;  	    
		background: $white;
		box-shadow: 0 40px 70px 0 rgba(0,0,0,0.10);
		border-radius: 4px;
		overflow: hidden;

		@media #{$tablet2} {
			display: none;
			margin-top: 0;
		}

		>li {
			display: block;
			position: relative;

			&:not(:first-child) {
				border-top: 1px solid rgba(#237865, 0.2);
			}

			&:first-child {
				

				a {
					padding-top: 20px;
				}
			}

			&:last-child {
				

				a {
					padding-bottom: 20px;
				}
			}

			&.active {

				a {
					background: rgba(#EFFCFA, 0.4);

					>span {
						color: $darkgreen;
					}
				}
			}

			a {
				width: 100%;
				height: 69px;
		    	display: table;
				font-family: $small;
				font-size: 16px;
				color: $brown;
				transition: all 0.2s ease-out;
				background: $white;

				@media #{$height} {
					height: 59px;
				}

				@media #{$mobilland} {
					height: 59px;
					font-size: 15px;
				}

				@media #{$mobil} {
					height: 59px;
					font-size: 15px;
				}

				>span {
					display: table-cell;
					vertical-align: middle;
					padding-left: 30px;
					transition: all 0.2s ease-out;
				}

				@include hover {
					background: rgba(#EFFCFA, 0.4);

					>span {
						color: $darkgreen;
					}
				}
			}
		}
    }
}