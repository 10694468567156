.video-lightbox {
	@include absolute;
	@include fixed;
	z-index: 101;
	display: table;
	background: rgba($black, 0.2);
	opacity: 0;
	visibility: hidden;
	-webkit-animation: zoom 0.3s ease-out forwards;
	animation: zoom 0.3s ease-out forwards;

	@-webkit-keyframes zoom {
		0% {
			opacity: 0;
			visibility: hidden;
		}

		100% {
			opacity: 1;
			visibility: visible;
		}
	}

	@keyframes zoom {
		0% {
			opacity: 0;
			visibility: hidden;
		}

		100% {
			opacity: 1;
			visibility: visible;
		}
	}

	&.fadeout {
		animation: zooms 0.3s ease-out forwards;

		@keyframes zooms {
		

		0% {
			opacity: 1;
			visibility: visible;
		}

		100% {
			opacity: 0;
			visibility: hidden;
		}
	}

	}

	>.grid {
		width: 100%;
		display: table-cell;
		vertical-align: middle;
		position: relative;
		text-align: center;

		.video-box-cnt {
			max-width: 870px;
			margin: 0 auto;
			position: relative;
			background: $white;
			padding: 10px;
			border-radius: 4px;

			@media #{$mobilland} {
				max-width: 400px;
			}

			>button {
				width: 50px;
				height: 50px;
				position: absolute;
				top: -25px;
				right: -25px;
				z-index: 12;
				transition: all 0.24s ease-out;
				border-radius: 50%;
				background: $white;
				box-shadow: 0 10px 20px 0 rgba(0,0,0,0.15);

				@include hover() {
					background: $green;

					&:before,
			        &:after {
			        	background: $white;
					}
		        }

		        &:before,
		        &:after {
		        	width: 20px;
		        	height: 2px;
		        	@include pseudo;
		        	top: 50%;
		        	left: 50%;
		        	transition: all 0.24s ease-out;
		        	background: $green;
				}

				&:before {
					@include transform(translate(-50%, -50%) rotate(-45deg));
				}

				&:after {
					@include transform(translate(-50%, -50%) rotate(45deg));
				}
			}
		}
	}

	.wrapper {
		width: 100%;
		height: 0;
	    position: relative;
	    padding-bottom: 56.25%;
	    padding-top: 25px;
	    border-radius: 4px;

	    iframe {
	    	width: 100%;
		    height: 100%;
		    position: absolute;
		    top: 0;
		    left: 0;
		    z-index: 10;
		    border-radius: 4px;
		}
	}
}