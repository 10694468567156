section.intr-solutions {
    border-top: 1px solid #DFECE9;
    padding: 99px 0;

    @media #{$mobilland} {
        padding: 0;
        border-top: 0;
        background: $green;
    }

    @media #{$mobil2} {
        border-top: 0;
        padding: 0;
        background: $green;
    }

    .grid {

        @media #{$mobilland} {
            padding: 0;
        }

        @media #{$mobil2} {
            padding: 0;
        }
    }


    .wrap {
        padding: 30px 0;
        background: $green;
        border-radius: 4px;

        @media #{$mobilland} {
            border-radius: 0;
            padding: 67px 0 70px;
        }

        @media #{$mobil2} {
            border-radius: 0;
            padding: 67px 0 70px;
        }


        .flex-container {
            height: 50px;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: stretch;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;

            @media #{$mobilland} {
                display: block;
                height: auto;
            }

            @media #{$mobil2} {
                display: block;
                height: auto;
            }

            .flex-item-link {
                padding: 0;
                position: absolute;
                top: 30px;
                right: 35px;

                @media #{$mobilland} {
                    position: relative;
                    top: 0;
                    right: 0;
                    left: 40px;
                }

                @media #{$mobil2} {
                    position: relative;
                    top: 0;
                    right: 0;
                    left: 40px;
                }

                a {
                    float: right;
                    height: 50px;
                    padding: 0 24px;
                    display: block;
                    z-index: 5;
                    box-sizing: border-box;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 50px;
                    font-size: 12px;
                    color: $green;
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    transition: all 0.2s ease-out;
                    background: $white;
                    box-shadow: 0 2px 4px 0 rgba(18,114,97,0.10);

                    @media #{$mobilland} {
                        float: left;
                        margin-top: 30px;
                        left: 40px;
                    }

                    @media #{$mobil2} {
                        float: left;
                        margin-top: 30px;
                        left: 40px;
                    }

                    @include hover {
                        background: $darkgreen;
                    }
                }
            }

            .flex-item {
                position: relative;
                padding: 0 40px;
                -webkit-order: 0;
                -ms-flex-order: 0;
                order: 0;
                -webkit-flex: 0 1 auto;
                -ms-flex: 0 1 auto;
                flex: 0 1 auto;
                -webkit-align-self: auto;
                -ms-flex-item-align: auto;
                align-self: auto;

                @media #{$mobilland} {
                    display: block;
                }

                @media #{$mobil2} {
                    display: block;
                }

                &:nth-of-type(1) {

                    &:before {
                        width: 1px;
                        height: 50px;
                        @include pseudo;
                        top: 50%;
                        right: 0;
                        @include transform(translateY(-50%));
                        background: rgba(#237865, 0.3);

                        @media #{$mobilland} {
                            display: none;
                        }

                        @media #{$mobil2} {
                            display: none;
                        }
                    }
                }

                &:nth-of-type(2) {
                    padding: 0 240px 0 40px;

                    @media #{$mobilland} {
                        padding: 22px 40px 0;
                    }

                    @media #{$mobil2} {
                        padding: 22px 40px 0;
                    }
                } 

                h4 {
                    font-family: $small;
                    font-size: 22px;
                    color: $white;
                }

                p {
                    font-family: $basereg;
                    font-size: 15px;
                    color: #FFFFFF;
                    line-height: 25px;
                    @include noselect;

                    @media #{$mobilland} {
                        line-height: 20px;
                    }

                    @media #{$mobil2} {
                        line-height: 20px;
                    }
                }
            }
        }  
    }
}